import { KeywordSearch } from "interfaces/searchResults.interfaces";
import { KeywordSearchServices } from "services/keyword-search.services";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IKeywordSearchState {
  isLoading: boolean;
  error: null | string;
  keywords: KeywordSearch[];
  searchedByUser: KeywordSearch[];
}

/**
 * A function that fetch a list with keywords search
 * based on word pased for paramether
 * @param {String} keyword
 */
export const fetchKeywordSearch = createAsyncThunk(
  "keywords-search/fetchKeywordSearch",
  async (keyword: string) => {
    return await KeywordSearchServices.getSimilarKeywords(keyword);
  }
);

/**
 * A function that create a new keyword search
 * based on word based for parameter
 * @param {String} keyword
 */
export const createKeywordSearch = createAsyncThunk(
  "keywords-search/createKeywordSearch",
  async (keyword: string) => {
    return await KeywordSearchServices.createNewKeyword(keyword);
  }
);

/**
 * A function that fetch a list with keywords search searched by the current user
 * based on word based for parameter
 * @param {String} keyword
 */
export const fetchKeywordSearchByUser = createAsyncThunk(
  "keywords-search/fetchKeywordSearchByUser",
  async (keyword: string) => {
    return await KeywordSearchServices.getSimilarKeywordsByUser(keyword);
  }
);

const initialState: IKeywordSearchState = {
  error: null,
  keywords: [],
  searchedByUser: [],
  isLoading: false,
};

const keywordSearchSlice = createSlice({
  name: "keywords-search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchKeywordSearch.fulfilled,
        (state, action: PayloadAction<KeywordSearch[]>) => {
          state.isLoading = false;
          state.keywords = action.payload;
        }
      )
      .addCase(fetchKeywordSearch.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchKeywordSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(
        fetchKeywordSearchByUser.fulfilled,
        (state, action: PayloadAction<KeywordSearch[]>) => {
          state.isLoading = false;
          state.searchedByUser = action.payload;
        }
      )
      .addCase(fetchKeywordSearchByUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchKeywordSearchByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || genericErrorMessage;
      });
  },
});

const genericErrorMessage = "a generic error occurred on the request";

export default keywordSearchSlice.reducer;
