import { FC } from "react";
import theme from "styles/muiTheme";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

const EmptyProductList: FC = () => {
  const navigate = useNavigate();
  const goTohome = () => navigate("/");

  return (
    <Box>
      <Typography variant="body1" color="text.secondary">
        En este momento no tenemos productos disponibles para recomendarte.
      </Typography>

      <Typography
        variant="body1"
        onClick={goTohome}
        color={theme.palette.secondary.main}
        sx={{ my: 2, textDecoration: "underline", cursor: "pointer" }}
      >
        Buscar productos
      </Typography>
    </Box>
  );
};

export default EmptyProductList;
