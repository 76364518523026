import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";

import { Box } from "@mui/system";
import DateAdapter from "@mui/lab/AdapterLuxon";
import { TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import theme from "styles/muiTheme";
import { DateTime } from "luxon";

interface IProps {
  rules: any;
  name: string;
  label: string;
  maxDate?: DateTime;
  error?: { message: string };
  control: Control<FieldValues, object>;
}

/**
 * Component date picker for get day of birthday
 * @param {IProps} props
 * @example return (
 * <DatePicker
      control={control}
      name="dateOfBird"
      error={errors.dateOfBird}
      label="Fecha de nacimiento *"
      rules={{ required: "Campo obligatorio" }}
    />)
 */

const CustomDatePicker: FC<IProps> = (props) => {
  const {
    field: { onChange, onBlur, name, value },
  } = useController({
    name: props.name,
    defaultValue: "",
    rules: props.rules,
    control: props.control,
  });

  return (
    <Box sx={{ my: 0.5 }}>
      <Typography lineHeight="24px" variant="body2" sx={{ mb: 0.25 }}>
        {props.label}
      </Typography>

      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          value={value}
          disableFuture
          onChange={onChange}
          PopperProps={{ popperOptions: { placement: "bottom-end" } }}
          inputFormat="dd/MM/yyyy"
          maxDate={props.maxDate ? props.maxDate : null}
          allowSameDateSelection={true}
          defaultCalendarMonth={props.maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ backgroundColor: theme.palette.secondary.light }}
              fullWidth
              name={name}
              size="small"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="dd/MM/yyyy"
              error={!!props.error?.message}
              helperText={props.error?.message}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDatePicker;
