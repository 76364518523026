import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";

import puntoMallLogo from "assets/logos/puntoMall.svg";

const useStyles = makeStyles({
  root: { maxWidth: 120, objectFit: "contain", cursor: "pointer" },
});

interface IProps {
  width?: number | string;
  height?: number | string;
}

/**
 * Component for showing custom logo (puntoMall) in different sizes.
 * @param {Number} width (optional)
 * @param {Number} height (optional)
 *
 * @example
 * return (
 *   <CustomLogo width={45} />
 * )
 */

const CustomLogo: FC<IProps> = (props) => {
  const s = useStyles();
  const navigate = useNavigate();

  const goHome = () => navigate("/");

  return (
    <img
      onClick={goHome}
      className={s.root}
      src={puntoMallLogo}
      alt="logo-punto-mall"
      width={props.width || undefined}
      height={props.height || undefined}
    />
  );
};

export default CustomLogo;
