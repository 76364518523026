import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { KeywordSearch } from "interfaces/searchResults.interfaces";

export const KeywordSearchServices = {
  /**
   * A function that makes axios request to puntoMall API (/keywords-search) and
   * get an array of similar keywords based on keyword pased for query
   * @param {String} keyword
   * @returns {KeywordSearch[]} array of product
   */
  getSimilarKeywords: async (keyword: string): Promise<KeywordSearch[]> => {
    try {
      const keywords: AxiosResponse<KeywordSearch[]> = await puntoMallAPI.get(
        `/keywords-search?word=${keyword}`
      );

      return keywords.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios POST request to puntoMall API (/keywords-search) and
   * create a new keyword search
   * @param {string} keyword
   */
  createNewKeyword: async (keyword: string): Promise<void> => {
    try {
      const newKeyword: AxiosResponse<void> = await puntoMallAPI.post(
        `/keywords-search`,
        { name: keyword }
      );

      return newKeyword.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios request to puntoMall API (/keywords-search) and
   * get an array of similar keywords based on keyword pased for query and a the current user
   * @param {String} keyword
   * @returns {KeywordSearch[]} array of product
   */
  getSimilarKeywordsByUser: async (
    keyword: string
  ): Promise<KeywordSearch[]> => {
    try {
      const keywords: AxiosResponse<KeywordSearch[]> = await puntoMallAPI.get(
        `/keywords-search/by-user?word=${keyword}`
      );

      return keywords.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
