import { FC } from "react";

import { Box } from "@mui/system";
import { Modal, Paper } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material/";

interface ICustomModalProps {
  open: boolean;
  handleClose: any;
  customSize?: number;
  size: "small" | "big";
  showCloseButton?: boolean;
}

const CustomModal: FC<ICustomModalProps> = ({
  children,
  open = false,
  handleClose,
  size = "small",
  customSize = 846,
  showCloseButton = false,
}) => (
  <Modal open={open} onClose={handleClose}>
    <Paper
      sx={{
        top: "50%",
        left: "50%",
        maxWidth: "95vw",
        maxHeight: "95vh",
        overflowY: "auto",
        padding: 4,
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: size === "small" ? 607 : customSize,
      }}
    >
      {showCloseButton && (
        <Box
          sx={{
            m: 2,
            top: 0,
            right: 0,
            cursor: "pointer",
            position: "absolute",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Box>
      )}

      {children}
    </Paper>
  </Modal>
);

export default CustomModal;
