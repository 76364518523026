import { FC } from "react";
import { Box } from "@mui/material";

// icons
import mpIcon from "assets/images/checkout/payment_icon_1.svg";
import visaIcon from "assets/images/checkout/payment_icon_2.svg";
import moreMethodsIcon from "assets/images/checkout/payment_icon_3.svg";

const PaymentCheckoutIcons: FC = () => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", mb: 2, width: "100%" }}
    >
      <Box component="img" src={mpIcon} width={64} mr={1} />
      <Box component="img" src={visaIcon} width={42} mr={1} />
      <Box component="img" src={moreMethodsIcon} width={140} />
    </Box>
  );
};

export default PaymentCheckoutIcons;
