import { FC } from "react";

import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Theme, Typography } from "@mui/material";

import { ISubCategoryState } from "interfaces/categories.interfaces";
import { useNavigate } from "react-router-dom";

interface ISubcategoryItem {
  handleClose: () => void;
  category: ISubCategoryState;
}

const SubcategoryItem: FC<ISubcategoryItem> = (props) => {
  const { category, handleClose } = props;
  const { categoryName, list, show } = category;

  const s = styles();
  const navigate = useNavigate();

  const goToSpecifiedCategory = (sword: string) => {
    handleClose();
    navigate(`/search?amz=${sword}&page=1`);
  };

  return (
    <Box width="100%">
      {show && (
        <Box className={s.title}>
          {categoryName}
          <Divider sx={{ marginTop: 1 }} />
        </Box>
      )}

      <Grid
        container
        spacing={2}
        className={`${s.sub} ${!show ? s.hide : null}`}
      >
        {list.map(({ id, name, subCategories, englishKeyword }) => (
          <Grid item sm={3} key={id}>
            <Typography
              fontSize={14}
              fontWeight="bold"
              className={s.text}
              color="text.primary"
              onClick={() => goToSpecifiedCategory(englishKeyword)}
            >
              {name}
            </Typography>

            {subCategories &&
              subCategories.map(({ id, name, englishKeyword }) => (
                <Typography
                  key={id}
                  my={0.5}
                  fontSize={13}
                  className={s.text}
                  color="text.secondary"
                  onClick={() => goToSpecifiedCategory(englishKeyword)}
                >
                  {name}
                </Typography>
              ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const styles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },

  sub: {
    width: "100%",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
  },

  text: {
    cursor: "pointer",
    "&:hover": { color: theme.palette.secondary.main },
  },

  hide: { display: "none" },
}));

export default SubcategoryItem;
