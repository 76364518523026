import { Box } from "@mui/system";
import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { FieldValues, useForm } from "react-hook-form";

import { getProfile } from "store/user.slice";
import { getCheckoutHelpers } from "store/checkout/checkout.selectors";

import {
  setCheckoutStep,
  setUserInformation,
} from "store/checkout/checkout.slice";

import {
  CheckoutSteps,
  UserInformation,
  SortStepPosition,
} from "store/checkout/checkout.interfaces";

import CustomButton from "components/atoms/custom-button";
import AcordionBox from "components/molecules/acordion-box";
import FrancheseCheckoutForm from "components/molecules/franchese-checkout-form";
import PersonalInformationform from "components/organisms/forms/PersonalInformationForm";

import * as Yup from "yup";
import { DateTime } from "luxon";
import { yupResolver } from "@hookform/resolvers/yup";
import { GenderDto } from "interfaces/user.interfaces";

const PersonalInformationView: FC = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userInformation);
  const { currenStep, sortStepPosition } = useAppSelector(getCheckoutHelpers);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .typeError("Por favor ingrese solo palabras")
      .required("Campo obligatorio"),
    lastName: Yup.string()
      .typeError("Por favor ingrese solo palabras")
      .required("Campo obligatorio"),
    document: Yup.number()
      .typeError("Por favor ingrese solo números")
      .required("Campo obligatorio"),
    middleName: Yup.string().typeError("Por favor ingrese solo palabras"),
    secondLastName: Yup.string().typeError("Por favor ingrese solo palabras"),
    birthday: Yup.date()
      .typeError("Por favor ingrese solo fechas")
      .max(
        DateTime.now().minus({ years: 18 }).toJSDate(),
        "Tienes que ser mayor de edad"
      )
      .required("Campo obligatorio"),
  });

  const formProps = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      document: user?.document,
      middleName: user?.middleName,
      secondLastName: user?.secondLastName,
      gender: user?.gender || GenderDto.Not_Especified,
      birthday: "",
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    formProps.setValue(
      "birthday",
      user?.birthday
        ? (DateTime.fromISO(user?.birthday.toString()).toJSDate() as any)
        : ""
    );
  }, [formProps, user]);

  const onClick = (fieldValues: FieldValues) => {
    dispatch(
      setUserInformation({
        ...fieldValues,
        birthday: DateTime.fromJSDate(
          new Date(fieldValues.birthday.toString())
        ).toJSDate(),
      } as UserInformation)
    );

    dispatch(
      setCheckoutStep({
        currentStep: CheckoutSteps.DELIVERY_METHOD,
        sortStepPosition: SortStepPosition.DELIVERY_METHOD,
      })
    );
  };

  return (
    <Box px={{ md: 2 }} py={1}>
      <AcordionBox
        title="Información personal"
        expanded={currenStep === CheckoutSteps.USER_INFORMATION}
        showGoBack={sortStepPosition > SortStepPosition.USER_INFORMATION}
        goBack={() =>
          dispatch(
            setCheckoutStep({
              currentStep: CheckoutSteps.USER_INFORMATION,
              sortStepPosition: SortStepPosition.USER_INFORMATION,
            })
          )
        }
      >
        <Box p={{ md: 2 }}>
          <PersonalInformationform formProps={formProps as any} />

          <FrancheseCheckoutForm />

          <Box display="flex" sx={{ my: 2, justifyContent: "center" }}>
            <CustomButton
              text="Continuar"
              variant="primary"
              sx={{ width: { md: "240px", xs: "100%" } }}
              onClick={formProps.handleSubmit(onClick)}
            />
          </Box>
        </Box>
      </AcordionBox>
    </Box>
  );
};

export default PersonalInformationView;
