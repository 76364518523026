import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/system";
import { Divider, Grid } from "@mui/material";

import { ICartItem } from "interfaces/shopping-cart.interfaces";
import { getUserFavorites } from "store/favorites/favorites.actions";
import { getFavoritesList } from "store/favorites/favorites.selectors";

import CartItem from "./CartItem";

interface IProps {
  cartId: string;
  items: ICartItem[];
  fetchFavorites?: boolean;
}

const CartItemsList: FC<IProps> = (props) => {
  const { items, cartId, fetchFavorites } = props;

  const dispatch = useAppDispatch();
  const favorites = useAppSelector(getFavoritesList);

  useEffect(() => {
    if (fetchFavorites && !favorites.length) dispatch(getUserFavorites());
  }, [dispatch, fetchFavorites, favorites.length]);

  return (
    <Grid container spacing={{ md: 1 }} mt={{ md: 0.2 }}>
      <Box
        width="100%"
        p={{ xs: 2, md: 0 }}
        sx={{ pl: { md: 1 } }}
        bgcolor={{ xs: "white", md: "transparent" }}
      >
        {items &&
          items.map((item, index) => (
            <Box key={item.id} my={{ md: 1 }}>
              <Box display={{ xs: "none", md: "block" }}>
                <CartItem
                  {...item}
                  key={item.id}
                  cartId={cartId}
                  withPaper={true}
                />
              </Box>

              <Box display={{ xs: "block", md: "none" }}>
                <CartItem
                  {...item}
                  key={item.id}
                  cartId={cartId}
                  withPaper={false}
                />

                {index + 1 !== items.length ? (
                  <Divider sx={{ marginY: 3 }} />
                ) : null}
              </Box>
            </Box>
          ))}
      </Box>
    </Grid>
  );
};

export default CartItemsList;
