import { RootState } from "store";
import { IProduct } from "interfaces/products.interfaces";

export const getStateProductsLoaders = (state: RootState) =>
  state.products.loaders;

export const getStateProductsHelpers = (state: RootState) =>
  state.products.helpers;

export const getStateProducts = (state: RootState) => state.products.products;

export const getStateSingleProduct = (state: RootState) =>
  state.products.product as IProduct;

export const getStateProductsErrors = (state: RootState) =>
  state.products.errors;

export const getSimilarProductState = (state: RootState) =>
  state.products.similarProducts;
