import { DateTime } from "luxon";
import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box, Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import CustomButton from "components/atoms/custom-button";
import AcordionBox from "components/molecules/acordion-box";
import StepOptionButton from "components/atoms/step-option-button";
import ProductsCardList from "components/molecules/products-card-list";

import { getFees } from "store/fees/fees.actions";
import { getImageFromProduct } from "utils/commons";
import { getCartInformation } from "store/cart/cart.selectors";
import { getApartmentLabel } from "utils/forms/aparments.options";
import { ApartmentType } from "interfaces/shipping-addresses.interfaces";

import {
  ICartItem,
  DeliveryMethodsFees,
  FrancheseType,
} from "interfaces/shopping-cart.interfaces";

import {
  CheckoutSteps,
  SortStepPosition,
  DeliveryMethods,
} from "store/checkout/checkout.interfaces";

import {
  setCheckoutStep,
  toogleConfirmPurchaseView,
} from "store/checkout/checkout.slice";

import {
  getCheckoutHelpers,
  getCheckoutUserInfo,
  getCheckoutAddressInfo,
  getCheckout,
} from "store/checkout/checkout.selectors";
import { getDeliveryMethod } from "utils/getDeliveryMethod";

const ConfirmPurchase: FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { useFranchise } = useAppSelector(getCheckout);
  const { currenStep } = useAppSelector(getCheckoutHelpers);
  const userInformation = useAppSelector(getCheckoutUserInfo);
  const { user } = useAppSelector((state) => state.userInformation);
  const { total, items: products } = useAppSelector(getCartInformation);
  const { address, deliveryMethod } = useAppSelector(getCheckoutAddressInfo);

  useEffect(() => {
    if (deliveryMethod) {
      const method =
        deliveryMethod === DeliveryMethods.PICKUP
          ? DeliveryMethodsFees.PICKUP
          : getDeliveryMethod(address);

      dispatch(
        getFees({
          userId: user?.id,
          deliveryMethod: method,
          useFranchise:
            total < 200
              ? (useFranchise as FrancheseType)
              : FrancheseType.NOT_APPLICABLE,
        })
      );
    }
  }, [deliveryMethod, dispatch, total, useFranchise, user?.id, address]);

  const parseProductsToCard = (products: ICartItem[]) =>
    products.map((p) => ({
      id: p.productId,
      price: "" + p.price,
      quantity: p.quantity,
      title: p.product.productTitle,
      img: getImageFromProduct(p.product),
      productDetails: p.product.productDetails,
    }));

  const goToUserStep = () =>
    dispatch(
      setCheckoutStep({
        currentStep: CheckoutSteps.USER_INFORMATION,
        sortStepPosition: SortStepPosition.USER_INFORMATION,
      })
    );

  const goToAddressStep = () =>
    dispatch(
      setCheckoutStep({
        currentStep: CheckoutSteps.DELIVERY_METHOD,
        sortStepPosition: SortStepPosition.DELIVERY_METHOD,
      })
    );

  const handleConfirmPurchase = () => dispatch(toogleConfirmPurchaseView(true));

  return (
    <Box px={{ md: 2 }} py={1}>
      <AcordionBox
        title="Revisar y confirmar compra"
        expanded={currenStep === CheckoutSteps.PAYMENT_METHODS}
      >
        <ProductsCardList products={parseProductsToCard(products)} />

        <Box p={{ md: 2 }}>
          <Grid container spacing={{ md: 2, xs: 1 }}>
            <Grid item sm={6} xs={12} md={12} lg={6}>
              <Typography
                mb={2}
                mt={{ xs: 2 }}
                variant="body2"
                color="text.secondary"
                sx={{ textTransform: "uppercase" }}
              >
                Información personal
              </Typography>

              <Box className={styles.card}>
                <Box>
                  <Typography fontSize={{ xs: 14, my: 0.5 }}>
                    {userInformation?.firstName} {userInformation?.lastName}
                  </Typography>

                  <Typography fontSize={{ xs: 14, my: 0.5 }}>
                    C:I. {userInformation?.document}
                  </Typography>

                  <Typography fontSize={{ xs: 14, my: 0.5 }}>
                    {userInformation
                      ? DateTime.fromJSDate(
                          new Date(userInformation?.birthday)
                        ).toFormat("dd/LL/yyyy")
                      : ""}
                  </Typography>
                </Box>

                <StepOptionButton onClick={goToUserStep} />
              </Box>
            </Grid>

            <Grid item sm={6} xs={12} md={12} lg={6}>
              <Typography
                mb={2}
                mt={{ xs: 2 }}
                variant="body2"
                color="text.secondary"
                sx={{ textTransform: "uppercase" }}
              >
                Datos de envío
              </Typography>

              <Box className={styles.card}>
                <Box width={{ xs: "70%" }}>
                  <Typography
                    fontSize={{ xs: 14, my: 0.5, textTransform: "capitalize" }}
                  >
                    {address?.address}, {address?.doorNumber}
                  </Typography>

                  <Typography
                    fontSize={{ xs: 14, my: 0.5, textTransform: "capitalize" }}
                  >
                    Teléfono: {address?.phoneNumber}
                  </Typography>

                  <Typography
                    fontSize={{ xs: 14, my: 0.5, textTransform: "capitalize" }}
                  >
                    {address?.neighborhood} ({address?.zipCode})
                    {getApartmentLabel(address?.apartment as ApartmentType)},
                    Uruguay
                  </Typography>
                </Box>

                <StepOptionButton onClick={goToAddressStep} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="center" my={2}>
          <CustomButton
            variant="primary"
            text="Continuar con el pago"
            onClick={handleConfirmPurchase}
            sx={{ width: { sx: "100%", md: 240 } }}
          />
        </Box>
      </AcordionBox>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    borderRadius: 8,
    alignItems: "center",
    padding: theme.spacing(2),
    border: "2px solid #EEEEEE",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: { padding: theme.spacing(1.5) },
  },
}));

export default ConfirmPurchase;
