import { Box, Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import { FC, MouseEvent } from "react";

interface IProps {
  sx?: any;
  text: string;
  disabled?: boolean;
  variant: "facebook" | "google";
  onClick: (event: MouseEvent) => void;
}

/**
 * Custom button based on MUI with some parameters like variant, click function and text
 * @param {string} text
 * @param {string} variant
 * @param {Function} onClick
 * @param {boolean} disabled (optional)
 *
 * @example
 * return (
 *   <CustomButton text="Buy" variant="primary" onClick={() => "hello world"} />
 * )
 */

const SocialMediaButton: FC<IProps> = (props) => {
  const s = useStyles();
  const { variant, text, onClick, disabled, sx } = props;

  const renderLogo = () => {
    switch (variant) {
      case "google":
        return <Box component="img" src={google} alt="Google" />;
      case "facebook":
        return <Box component="img" src={facebook} alt="Facebook" />;
      default:
        return null;
    }
  };

  return (
    <Button
      sx={{ my: 0.5, height: 40, ...sx }}
      disableElevation
      variant="contained"
      onClick={onClick}
      disabled={disabled || false}
      className={`${s.root} ${s[variant as "facebook" | "google"]}`}
    >
      <Box
        component="span"
        marginRight={1}
        display="flex"
        justifyItems="center"
      >
        {renderLogo()}
      </Box>
      {text}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#ffffffff",
    textTransform: "none",
  },

  facebook: {
    background: "#1877F2",
    color: "white",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#1877F2",
    },
  },

  google: {
    background: "white",
    color: "#757575",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "white",
    },
  },
}));

export default SocialMediaButton;
