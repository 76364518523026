import { FC } from "react";
import { genRandomId } from "utils/commons";
import { ProductList } from "interfaces/products.interfaces";

import { Box, useTheme } from "@mui/system";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";

import ProductCard from "../product-card";
import CustomMobileProductList from "../custom-mobile-product-list";
import { useNavigate } from "react-router-dom";

interface IProps {
  urlTo: ILink;
  title: string;
  emptyMsj?: string;
  products: ProductList[];
}

interface ILink {
  url: string;
  title: string;
}

const CustomProductList: FC<IProps> = (props) => {
  const { products, title, urlTo, emptyMsj } = props;

  const navigate = useNavigate();
  const { palette, breakpoints } = useTheme();
  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));

  if (downToMiddleScreenSize) return <CustomMobileProductList {...props} />;

  const goTpUrl = (url: string) => navigate(url);

  return (
    <Container sx={{ mt: { md: 6, xs: 2 } }} maxWidth="xl">
      <Box my={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" color="text.secondary" mx={1}>
            {title}
          </Typography>

          <Typography
            variant="body2"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            color={palette.secondary.main}
            onClick={() => goTpUrl(urlTo.url)}
          >
            {urlTo.title}
          </Typography>
        </Box>

        {products.length ? (
          <Grid container>
            {products.map((product) => (
              <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xs={12}
                xl={2.4}
                key={genRandomId()}
              >
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" ml={{ md: 1 }}>
            {emptyMsj}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default CustomProductList;
