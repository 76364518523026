import { AxiosResponse } from "axios";
import { IExternalTrackingEvent } from "interfaces/tracking.interfaces";
import { puntoMallAPI } from "./config/axios.config";

export const trackingServices = {
  /**
   * A function that makes axios request to puntoMall API (/tracking) and get an array of the tracking info
   * @param {String} trackingNumber
   * @returns {IExternalTrackingEvent[]} An array with tracking info
   */
  getTrackingInfo: async (
    trackingNumber: string
  ): Promise<IExternalTrackingEvent[]> => {
    const trackingInfo: AxiosResponse<IExternalTrackingEvent[]> =
      await puntoMallAPI.get(`/tracking/${trackingNumber}`);

    return trackingInfo.data;
  },
};
