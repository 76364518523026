import { IProduct } from "./products.interfaces";

export interface JwtPayload {
  id: string;
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUser {
  id: string;
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  secondLastName?: string;
  document?: string;
  phone?: string;
  birthday?: Date;
  gender?: GenderDto;
}

export class IUserUpdate {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  secondLastName?: string;
  document?: string;
  birthday?: Date;
  phone?: string;
  gender?: GenderDto;
  email?: string;
}

export interface IHistory {
  id: string;
  userId: string;
  createDate: Date;
  product: IProduct;
  productId: string;
}

export interface SignupDto {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface IUserResponse {
  id: string;
  birthdate?: Date;
  cardInfo: CardInfoDto;
  email: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  password: string;
  phoneNumber: string;
  type: string;
  receiveEmails?: boolean;
  businessName?: string;
  RUT?: string;
  shippingAddress: AddressDto;
  boxInfo?: BoxInfoDto;
  defaultShipping?: string;
}

export interface BoxInfoDto {
  boxId: number;
  customerKey: string;
}

export interface CardInfoDto {
  type: string;
  lastFourDigits: string;
  goodThruMonth: number;
  goodThruYear: number;
}

export interface AddressDto {
  state: string;
  city: string;
  neighborhood: string;
  address: string;
  doorNumber: string;
  apartment?: string;
  zipCode: string;
  observations?: string;
}

export enum GenderDto {
  Male = "M",
  Female = "F",
  Not_Especified = "N",
}

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
}

export interface IUpdatePasswordWithCode {
  newPassword: string;
  code: string;
}

export interface ICreateHistory {
  title: string;
  productId: string;
}
