import { useAppSelector } from "hooks";
import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface IProps {
  children: ReactElement<any, any> | null;
}

const CheckIfDontHaveUser: FC<IProps> = (props) => {
  const location = useLocation();
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  return !isLoggedIn ? (
    props.children
  ) : (
    <Navigate
      replace
      state={location.pathname}
      to={(location.state as string) || "/"}
    />
  );
};

export default CheckIfDontHaveUser;
