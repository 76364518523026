import * as Yup from "yup";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";

import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { formatDate } from "utils/commons";
import { getProfile, updateUser } from "store/user.slice";

import ProfileItem from "./ProfileItem";
import DatePicker from "components/atoms/date-picker";

const UpdateBirthday: FC = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { user, isLoading } = useAppSelector((state) => state.userInformation);

  const validationScheme = Yup.object().shape({
    birthday: Yup.date()
      .typeError("Por favor ingrese solo fechas")
      .max(
        DateTime.now().minus({ years: 18 }).toJSDate(),
        "Tienes que ser mayor de edad"
      )
      .required("Campo obligatorio"),
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationScheme),
  });

  useEffect(() => {
    setValue(
      "birthday",
      user?.birthday ? DateTime.fromISO(user?.birthday.toString()) : ""
    );
  });

  const onSubmit = (data: FieldValues) => {
    const compare = formatDate(data.birthday) === (user?.birthday as any);
    if (compare) return setShowModal(false);

    dispatch(updateUser({ birthday: data.birthday })).then(() => {
      dispatch(getProfile()).then(() => {
        setShowModal(false);
      });
    });
  };

  return (
    <ProfileItem
      open={showModal}
      loading={isLoading}
      setOpen={setShowModal}
      name="Fecha de nacimiento"
      title="Modificar fecha de nacimiento"
      handleSubmit={handleSubmit(onSubmit)}
      handleCloseFunction={() => reset()}
      value={
        user?.birthday
          ? DateTime.fromISO(user?.birthday.toString()).toFormat("dd/LL/yyyy")
          : ""
      }
    >
      <Grid item xs={12}>
        <DatePicker
          control={control as any}
          name="birthday"
          error={errors.birthday as any}
          label="Fecha de nacimiento *"
          maxDate={DateTime.now().minus({ years: 18 })}
          rules={{
            required: "Campo obligatorio",
          }}
        />
      </Grid>
    </ProfileItem>
  );
};

export default UpdateBirthday;
