import { FC } from "react";
import { Box, Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import { getWeight } from "utils/commons";
import { ProductDetail } from "interfaces/products.interfaces";

interface IProps {
  products: IProduct[];
}

interface IProduct {
  id: string;
  img: string;
  title: string;
  price: string;
  quantity: number;
  productDetails: ProductDetail[];
}

const ProductsCardList: FC<IProps> = (props) => {
  const { products } = props;

  return (
    <Box p={{ md: 2 }}>
      <Typography
        mb={2}
        variant="body2"
        color="text.secondary"
        sx={{ textTransform: "uppercase" }}
      >
        PRODUCTOS
      </Typography>

      <Grid container spacing={{ md: 2, xs: 1 }}>
        {products.map((a) => (
          <ProductCard {...a} key={a.id} />
        ))}
      </Grid>
    </Box>
  );
};

const ProductCard: FC<IProduct> = (props) => {
  const { id, title, price, img, quantity, productDetails } = props;

  const styles = useStyles();
  const pTitle = title.length < 36 ? title : title.substring(0, 36) + "...";

  return (
    <Grid item sm={6} xs={12} md={12} lg={6} key={id}>
      <Box className={`${styles.card}`}>
        <Grid container>
          <Grid item xs={4} md={3}>
            <Box
              src={img}
              component="img"
              className={styles.img}
              width={{ md: 96, xs: 84 }}
              height={{ md: 96, xs: 84 }}
              sx={{ objectFit: "contain" }}
            />
          </Grid>

          <Grid item xs={8} md={9}>
            <Typography fontSize={{ xs: 14, my: 0.5 }}>{pTitle}</Typography>
            <Typography fontSize={{ xs: 14, my: 0.5 }} color="text.secondary">
              Peso: {getWeight(productDetails)}
            </Typography>

            <Typography
              variant="body2"
              fontWeight="bold"
              fontSize={{ xs: 14, my: 0.5 }}
            >
              Precio: {price}
            </Typography>

            <Typography fontSize={{ xs: 14, my: 0.5 }}>
              Cantidad: {quantity}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ProductsCardList;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 8,
    padding: theme.spacing(1),
    border: "2px solid #EEEEEE",
    [theme.breakpoints.down("md")]: {},
  },
  img: {
    padding: 6,
    borderRadius: 6,
    objectFit: "contain",
    border: "1px solid #EDEEF1",
  },
}));
