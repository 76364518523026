import { FC } from "react";
import { Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import CompleteFooter from "./components/CompleteFooter";

const bgMsj = "#061B5E";
const colorText = "#ffffff82";

/**
 * Component that displays a footer with some extra general information
 * @example
 *  return <Footer />
 */
const Footer: FC = () => (
  <Box sx={{ mt: 12 }}>
    <CompleteFooter />
    <SimpleMessage />
  </Box>
);

const SimpleMessage: FC = () => {
  const s = useStyles();

  return (
    <Box className={s.simplemsj}>
      <Typography
        fontSize={12}
        align="center"
        color={colorText}
        maxWidth="lg"
        sx={{ background: "transparent" }}
      >
        Con tu registro GRATIS en PuntoMall obtienes tu casillero o dirección
        postal para compras en Estados Unidos. Compra tus marcas preferidas en
        tiendas de Estados Unidos como Carter's, GAP, Macys, Nike, Amazon,
        Zappos y Victoria's Secret, entre muchas más. PuntoMall™ las lleva a tu
        casa.
      </Typography>
    </Box>
  );
};

export default Footer;

const useStyles = makeStyles((theme: Theme) => ({
  simplemsj: {
    background: bgMsj,
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
}));
