import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";

import { parseAxiosErrorObject } from "utils/errors";
import { CurrencyEntity } from "interfaces/globals.interface";

export const GlobalServices = {
  /**
   * Get daily current UYU cotization and his rates in another currencies
   * @returns {CurrencyEntity} cotization
   */
  getCurrentCotization: async (): Promise<CurrencyEntity> => {
    try {
      const cotization: AxiosResponse<CurrencyEntity> = await puntoMallAPI.get(
        "/currency"
      );

      return cotization.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
