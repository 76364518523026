import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";

import { getAddressStore } from "store/addresses/addresses.selectors";
import { updateDefaultShippingAddress } from "store/addresses/addresses.actions";

import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";
import { Box } from "@mui/system";

interface IProps {
  open: boolean;
  addressId: string;
  setOpen: (value: boolean) => void;
}

const DefaultAddressFormComponent: FC<IProps> = (props) => {
  const { open, setOpen, addressId } = props;

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(getAddressStore);

  const onSubmit = () => {
    dispatch(updateDefaultShippingAddress(addressId)).then(() =>
      setOpen(false)
    );
  };

  return (
    <CustomModal handleClose={setOpen} open={open} size="small">
      <Typography sx={{ mb: 2 }}>
        Establecer dirección predeterminada
      </Typography>

      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={14} color="text.secondary">
            ¿Está seguro que desea establecer esta dirección como su dirección
            predeterminada?
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <CustomButton
              text="Continuar"
              variant="primary"
              onClick={onSubmit}
              disabled={isLoading}
              sx={{ width: { sx: "100%", md: 184 }, mr: { md: 2 } }}
            />
            <CustomButton
              text="Cancelar"
              disabled={isLoading}
              variant="secondary"
              onClick={() => setOpen(false)}
              sx={{ width: { sx: "100%", md: 184 } }}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default DefaultAddressFormComponent;
