import { ChangeEvent, FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { makeStyles } from "@mui/styles";

import {
  Box,
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import PickupInformation from "./PickupInformation";
import CustomButton from "components/atoms/custom-button";
import AcordionBox from "components/molecules/acordion-box";

import { getCheckoutHelpers } from "store/checkout/checkout.selectors";
import { ApartmentType } from "interfaces/shipping-addresses.interfaces";

import {
  setCheckoutStep,
  toggleStepAddresView,
  setShippingInformation,
} from "store/checkout/checkout.slice";

import {
  CheckoutSteps,
  DeliveryMethods,
  ShippingAddress,
  SortStepPosition,
} from "store/checkout/checkout.interfaces";
import { Theme } from "@mui/system";

const DeliveryMethodsView: FC = () => {
  const s = useStyles();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string>("");
  const { user } = useAppSelector((state) => state.userInformation);
  const { currenStep, sortStepPosition } = useAppSelector(getCheckoutHelpers);

  const handleChange = (e: ChangeEvent<any>) => setValue(e.target.value);

  const onClick = () => {
    if (value === DeliveryMethods.PICKUP) {
      // TODO: Refactorizar esto
      const puntoMallAddress: ShippingAddress = {
        doorNumber: "",
        zipCode: "11300",
        userId: user?.id,
        phoneNumber: "827115353",
        neighborhood: "Punta Carretas",
        apartment: ApartmentType.MONTEVIDEO,
        observations: "Sucursal de Puntomall",
        address: "Dr. José María Montero 2881",
      };

      dispatch(
        setShippingInformation({
          addToUserAddresses: false,
          address: puntoMallAddress,
          deliveryMethod: DeliveryMethods.PICKUP,
        })
      );

      dispatch(
        setCheckoutStep({
          currentStep: CheckoutSteps.PAYMENT_METHODS,
          sortStepPosition: SortStepPosition.PAYMENT_METHODS,
        })
      );
    } else
      dispatch(
        setCheckoutStep({
          currentStep: CheckoutSteps.ADDRESS,
          sortStepPosition: SortStepPosition.ADDRESS,
        })
      );

    dispatch(toggleStepAddresView(value === DeliveryMethods.PICKUP));
  };

  return (
    <Box px={{ md: 2 }} py={1}>
      <AcordionBox
        title="Método de envío"
        expanded={currenStep === CheckoutSteps.DELIVERY_METHOD}
        showGoBack={sortStepPosition > SortStepPosition.USER_INFORMATION}
        goBack={() =>
          dispatch(
            setCheckoutStep({
              currentStep: CheckoutSteps.DELIVERY_METHOD,
              sortStepPosition: SortStepPosition.DELIVERY_METHOD,
            })
          )
        }
      >
        <Box padding={2}>
          <Typography color="#777577" variant="body1">
            Selecciona el método de envío
          </Typography>

          <RadioGroup
            sx={{
              my: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onChange={handleChange}
          >
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  className={`${s.button} ${
                    value === DeliveryMethods.PICKUP && s.selectedButton
                  }`}
                  label="Pickup"
                  value={DeliveryMethods.PICKUP}
                  control={
                    <Radio classes={{ root: s.radio, checked: s.checked }} />
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  className={`${s.button} ${
                    value === DeliveryMethods.DELIVERY && s.selectedButton
                  }`}
                  label="Entrega a domicilio"
                  value={DeliveryMethods.DELIVERY}
                  control={
                    <Radio classes={{ root: s.radio, checked: s.checked }} />
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>

          {value === DeliveryMethods.PICKUP ? <PickupInformation /> : null}

          <Box
            display={{ xs: "none", md: "flex" }}
            sx={{ width: "100%", justifyContent: "center" }}
          >
            <CustomButton
              text="Continuar"
              variant="primary"
              disabled={!value}
              onClick={onClick}
              sx={{ width: "240px" }}
            />
          </Box>

          <Box
            display={{ xs: "flex", md: "none" }}
            sx={{ width: "100%", justifyContent: "center" }}
          >
            <CustomButton
              text="Continuar"
              variant="primary"
              disabled={!value}
              sx={{ width: "100%" }}
              onClick={onClick}
            />
          </Box>
        </Box>
      </AcordionBox>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 6,
    width: "100%",
    borderRadius: 8,
    display: "flex",
    padding: "6px 12px",
    justifyContent: "center",
    border: `1px solid lightgray`,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },

  selectedButton: {
    borderColor: "#FB8200",
    background: "rgba(251, 130, 0, 0.05)",
  },

  radio: { "&$checked": { color: "#FB8200" } },

  checked: {},
}));

export default DeliveryMethodsView;
