import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { FieldValues, useForm } from "react-hook-form";

import { Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material/";

import CustomButton from "components/atoms/custom-button";
import AcordionBox from "components/molecules/acordion-box";
import SnackbarMessage from "components/atoms/custom-snackbar";
import AddressesCardList from "components/molecules/addresses-card-list";
import ShippingAddressForm from "components/organisms/forms/ShippingAddressForm";

import {
  CheckoutSteps,
  DeliveryMethods,
  ShippingAddress,
  SortStepPosition,
} from "store/checkout/checkout.interfaces";

import {
  ApartmentType,
  IShippingAddress,
} from "interfaces/shipping-addresses.interfaces";

import {
  setCheckoutStep,
  setShippingInformation,
} from "store/checkout/checkout.slice";

import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { getCheckoutHelpers } from "store/checkout/checkout.selectors";

interface IProps {
  addresses: IShippingAddress[];
}

const ShippingAddressView: FC<IProps> = (props) => {
  const { addresses } = props;

  const styles = useStyles();
  const newAddressForm = useForm();
  const dispatch = useAppDispatch();

  const { currenStep, sortStepPosition } = useAppSelector(getCheckoutHelpers);

  const [apartmentError, setApartmentError] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [apartment, setApartment] = useState<ApartmentType | null>(null);
  const [newAddress, setNewAddress] = useState<boolean>(!addresses.length);

  useEffect(() => {
    addresses.forEach((a) => {
      if (a.defaultAddress) setSelectedAddress(a.id);
    });
  }, [addresses]);

  const onClickForSetAddress = async (formFields: FieldValues) => {
    if (newAddress && !apartment) return setApartmentError(true);

    const createNewAddress = newAddress && addresses.length < 3;

    const address: ShippingAddress = newAddress
      ? {
          ...(formFields as ShippingAddress),
          apartment: apartment as ApartmentType,
        }
      : (addresses.find(
          (address) => address.id === selectedAddress
        ) as ShippingAddress);

    dispatch(
      setShippingInformation({
        address,
        addToUserAddresses: createNewAddress,
        deliveryMethod: DeliveryMethods.DELIVERY,
      })
    );

    dispatch(
      setCheckoutStep({
        currentStep: CheckoutSteps.PAYMENT_METHODS,
        sortStepPosition: SortStepPosition.PAYMENT_METHODS,
      })
    );
  };

  const toggleSelectNewAddress = (val: boolean) => {
    if (!newAddress) setSelectedAddress("");
    setNewAddress(val);
    setApartment(null);
    newAddressForm.reset();
  };

  const goToBackStep = () =>
    dispatch(
      setCheckoutStep({
        currentStep: CheckoutSteps.ADDRESS,
        sortStepPosition: SortStepPosition.ADDRESS,
      })
    );

  const setSelectedAddressFunction = (address: string) => {
    setNewAddress(false);
    setSelectedAddress(address);
  };

  return (
    <Box px={{ md: 2 }} py={1}>
      <AcordionBox
        title="Datos de envío"
        goBack={goToBackStep}
        expanded={currenStep === CheckoutSteps.ADDRESS}
        showGoBack={sortStepPosition > SortStepPosition.ADDRESS}
      >
        <Box sx={{ p: { md: 2 }, display: "flex", flexDirection: "column" }}>
          {addresses.length ? (
            <>
              <Typography variant="body2" sx={{ mb: 2, fontWeight: 400 }}>
                Seleciona una dirección existente o agrega una nueva *
              </Typography>

              <AddressesCardList
                addresses={addresses}
                selected={selectedAddress}
                setSelected={setSelectedAddressFunction}
              />
            </>
          ) : null}

          {!newAddress && addresses && addresses.length < 3 ? (
            <Typography
              my={2}
              variant="body2"
              className={styles.title}
              onClick={() => toggleSelectNewAddress(true)}
            >
              <AddIcon sx={{ mr: 1 }} /> Agregar dirección
            </Typography>
          ) : null}

          {newAddress && (
            <ShippingAddressForm
              formProps={newAddressForm}
              customLabel="Dirección de envío"
              apartmentObject={{ setValue: setApartment, value: apartment }}
            />
          )}

          <Box sx={{ my: 1 }} className={styles.btnBox}>
            {newAddress && (
              <CustomButton
                text="Cancelar"
                variant="secondary"
                sx={{ width: { sx: "100%", md: 240 } }}
                onClick={() => toggleSelectNewAddress(false)}
              />
            )}

            <CustomButton
              text="Continuar"
              variant="primary"
              sx={{ width: { sx: "100%", md: 240 } }}
              disabled={!selectedAddress && !newAddress}
              onClick={newAddressForm.handleSubmit(onClickForSetAddress)}
            />
          </Box>
        </Box>
      </AcordionBox>

      <SnackbarMessage
        open={apartmentError}
        type={SnackbarType.error}
        setOpen={setApartmentError}
        message="seleccione un departamento válido para continuar"
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    color: theme.palette.secondary.main,
  },

  btnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: { flexDirection: "column" },
  },
}));

export default ShippingAddressView;
