import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { useTheme } from "@mui/system";
import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import ProfileItem from "./ProfileItem";
import { updateUser } from "store/user.slice";
import { GenderDto } from "interfaces/user.interfaces";

const UpdateGender: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { user, isLoading } = useAppSelector((state) => state.userInformation);

  const [showModal, setShowModal] = useState(false);
  const [gender, setGender] = useState(user?.gender ? user.gender : "");

  useEffect(() => {
    if (user?.gender) setGender(user.gender);
  }, [user?.gender]);

  const handleChange = (e: SelectChangeEvent) => {
    const val = e.target.value as GenderDto;
    setGender(val);
  };

  const onSubmit = () => {
    dispatch(updateUser({ gender: gender as GenderDto })).then(() =>
      setShowModal(false)
    );
  };

  return (
    <ProfileItem
      open={showModal}
      loading={isLoading}
      setOpen={setShowModal}
      title="Modificar Género"
      handleSubmit={onSubmit}
      handleCloseFunction={() => setGender(user?.gender || "")}
      lastOne
      name="Género"
      value={genderToModel[user?.gender ? user.gender : "N"]}
    >
      <Grid item xs={12}>
        <Select
          value={gender}
          defaultValue={gender}
          onChange={handleChange}
          sx={{ width: "100%", backgroundColor: theme.palette.secondary.light }}
        >
          {genreOptions.map((o) => (
            <MenuItem
              key={o.value}
              value={o.value}
              sx={{ px: 3, py: 1.5 }}
              selected={o.value === gender}
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </ProfileItem>
  );
};

export default UpdateGender;

export const genreOptions = [
  {
    label: "Femenino",
    value: GenderDto.Female,
  },
  {
    label: "Masculino",
    value: GenderDto.Male,
  },
  {
    label: "No especificado",
    value: GenderDto.Not_Especified,
  },
];

export const genderToModel = {
  M: "Masculino",
  F: "Femenino",
  N: "No seleccionado",
};
