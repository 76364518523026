import { AxiosResponse } from "axios";
import { IPaymentConfig } from "interfaces/payment.interfaces";
import { parseAxiosErrorObject } from "utils/errors";
import { puntoMallAPI } from "./config/axios.config";

export const PaymentServices = {
  configPayment: async (orderId: string, paymentMethod: string) => {
    try {
      const { data: config }: AxiosResponse<IPaymentConfig> =
        await puntoMallAPI.post(`orders/${orderId}/payment`, { paymentMethod });

      return config;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },

  confirmPayment: async (orderId: string) => {
    try {
      await puntoMallAPI.post(`orders/${orderId}/payment/confirm`);
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },
};
