import { Box } from "@mui/system";
import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, useQuery } from "hooks";
import { loginFromStorage } from "store/user.slice";

import Banner from "./components/Banner";
import OffersList from "./components/OffersList";
import FeaturedProducts from "./components/MostViewedProducts";
import LastViewedHistory from "./components/LastViewedHistory";
import BestSellerProducts from "./components/BestSellersProducts";

const Home: FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    const token = query.get("token");

    if (token) {
      localStorage.setItem("auth", token);
      dispatch(loginFromStorage());
      navigate("/");
    }
  }, [dispatch, navigate, query]);

  return (
    <>
      <Banner />

      <Box sx={{ p: { xs: 2, md: 0 } }}>
        <FeaturedProducts />
        <BestSellerProducts />

        <OffersList />
        {isLoggedIn && <LastViewedHistory />}
      </Box>
    </>
  );
};

export default Home;
