import { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "hooks";
import { getAddressStore } from "store/addresses/addresses.selectors";

import {
  ApartmentType,
  BodyShippingAddress,
} from "interfaces/shipping-addresses.interfaces";

import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";
import SnackbarMessage from "components/atoms/custom-snackbar";
import ShippingAddressForm from "components/organisms/forms/ShippingAddressForm";

import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { createNewShippingAddress } from "store/addresses/addresses.actions";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const CreateShippingAddress: FC<IProps> = (props) => {
  const { open, setOpen } = props;

  const form = useForm();
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(getAddressStore);
  const [apartmentError, setApartmentError] = useState(false);
  const [apartment, setApartment] = useState<ApartmentType | null>(null);

  const onSubmit = (addressBody: FieldValues) => {
    if (!apartment) setApartmentError(true);
    else
      dispatch(
        createNewShippingAddress({
          ...addressBody,
          apartment,
        } as BodyShippingAddress)
      ).then(() => {
        setOpen(false);
        setApartment(null);
        form.reset();
      });
  };

  return (
    <>
      <CustomModal handleClose={setOpen} open={open} size="big">
        <Typography sx={{ mb: 2 }}>Crear dirección</Typography>

        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <ShippingAddressForm
              formProps={form}
              apartmentObject={{ setValue: setApartment, value: apartment }}
            />
          </Grid>

          <Grid item xs={12} md={3} marginTop={2}>
            <CustomButton
              text="Guardar"
              variant="primary"
              loading={isLoading}
              disabled={isLoading}
              sx={{ width: "100%" }}
              onClick={form.handleSubmit(onSubmit)}
            />
          </Grid>

          <Grid item xs={12} md={3} marginTop={{ md: 2 }}>
            <CustomButton
              text="Cancelar"
              disabled={isLoading}
              variant="secondary"
              sx={{ width: "100%" }}
              onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </CustomModal>

      <SnackbarMessage
        open={apartmentError}
        type={SnackbarType.error}
        setOpen={setApartmentError}
        message="seleccione un departamento válido para continuar"
      />
    </>
  );
};

export default CreateShippingAddress;
