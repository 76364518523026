import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Box } from "@mui/system";
import { Container, Paper, Typography } from "@mui/material";

import theme from "styles/muiTheme";
import puntoMallLogin from "assets/logos/puntoMallLogin.png";

/**
 * Component based on MUI that displays custom layout integrated with a navbar and footer
 * and receives a children and render it
 * @param props
 * @example
 * return <AppLayout>{children}</AppLayout>
 */

const AuthLayout: FC = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container disableGutters sx={{ p: 0, m: 0, minWidth: "100%" }}>
      <Box>
        <Box
          pt={2}
          mb={5}
          display="flex"
          justifyContent="center"
          onClick={() => navigate("/")}
          sx={{
            height: "37vh",
            minHeight: "165px",
            cursor: "pointer",
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Box component="img" height={64} src={puntoMallLogin} />
        </Box>

        <Box
          mx={2}
          mb="-105px"
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{ position: "relative", top: "-27vh", minHeight: "60vh" }}
        >
          <Paper sx={{ width: "100%", maxWidth: "520px" }}>
            <Box py={5} px={4}>
              {props.children}
            </Box>
          </Paper>

          <Box
            width="100%"
            paddingX={1}
            marginTop={3}
            maxWidth={520}
            textAlign="center"
          >
            {location.pathname === "/auth/signin" ||
            location.pathname === "/auth/signup" ? (
              <>
                <Typography color={theme.palette.text.disabled} fontSize={14}>
                  Al registrarte estás aceptando nuestros{" "}
                  <Box
                    component="a"
                    target={"_blank"}
                    href="https://puntomio.uy/ayuda"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Términos y Condiciones
                  </Box>{" "}
                  y nuestras{" "}
                  <Box
                    component="a"
                    target={"_blank"}
                    href="https://puntomio.uy/ayuda"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    Políticas de Privacidad
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Typography
        color="#716C80"
        align="center"
        sx={{ backgroundColor: "white", py: 5, px: 2 }}
      >
        © 2021 <strong>PuntoMall®</strong> - Todos los derechos reservados -
        Todas las marcas son propiedad de sus respectivos dueños
      </Typography>
    </Container>
  );
};

export default AuthLayout;
