import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { IOffer } from "interfaces/offers.interfaces";

export const OfferServices = {
  getAllOffers: async (): Promise<IOffer[]> => {
    try {
      const offers: AxiosResponse<IOffer[]> = await puntoMallAPI.get("offers");
      return offers.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },
};
