import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
