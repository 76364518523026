import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

import {
  Grid,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  Box,
} from "@mui/material";

import { useTheme } from "@mui/system";

import { inputValidations } from "utils/forms/validations";
import { apartmentList } from "utils/forms/aparments.options";
import { ApartmentType } from "interfaces/shipping-addresses.interfaces";

import Input from "components/atoms/input";

interface IProps {
  customLabel?: string;
  formProps: UseFormReturn<any>;
  apartmentObject: ApartmentObject;
}

interface ApartmentObject {
  value: ApartmentType | null;
  setValue: (val: ApartmentType) => void;
}

const ShippingAddressForm: FC<IProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = props.formProps;

  const { apartmentObject } = props;

  const theme = useTheme();

  const apartmentOptions = apartmentList.map((a) => ({
    label: Object.values(a)[0] as string,
    value: Object.keys(a)[0],
  }));

  const apartmentHandleChange = (e: SelectChangeEvent) => {
    const val = e.target.value as ApartmentType;
    apartmentObject.setValue(val);
  };

  return (
    <Box>
      {props.customLabel ? (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textTransform: "uppercase", mt: 2, mb: 1 }}
        >
          {props.customLabel}
        </Typography>
      ) : null}

      <Grid container columnSpacing={{ md: 4 }}>
        <Grid item xs={12} md={6}>
          <Input
            name="address"
            control={control}
            label="Dirección *"
            error={errors.address}
            rules={{ required: "Campo obligatorio" }}
          />
        </Grid>

        <Grid item xs={12} md={6} my={0.5}>
          <Input
            name="doorNumber"
            control={control}
            label="Apartamento"
            error={errors.doorNumber}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="neighborhood"
            control={control}
            error={errors.neighborhood}
            label="Ciudad / Barrio *"
            rules={{ required: "Campo obligatorio" }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="body2"
            lineHeight="24px"
            color="text.secondary"
            sx={{ mb: 0.25 }}
          >
            Departamento *
          </Typography>

          <Select
            sx={{ backgroundColor: theme.palette.secondary.light }}
            size="small"
            fullWidth
            displayEmpty
            name="apartment"
            value={apartmentObject.value || ""}
            onChange={apartmentHandleChange}
          >
            <MenuItem value="">
              <Typography color="text.disabled">
                Elige un departamento
              </Typography>
            </MenuItem>

            {apartmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="zipCode"
            control={control}
            error={errors.zipCode}
            label="Código Postal *"
            rules={{
              required: "Campo obligatorio",
              pattern: inputValidations.onlyNumbers(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="phoneNumber"
            control={control}
            error={errors.phoneNumber}
            label="Teléfono / Celular *"
            rules={{
              required: "Campo obligatorio",
              pattern: {
                value: /^09[0-9]{7}$/,
                message:
                  "El valor del celular debe tener un formato 09x xxx xxx, sin espacios",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            disabled
            name="country"
            label="País *"
            control={control}
            defaultValue="Uruguay"
            error={errors.country}
            rules={{ required: "Campo obligatorio" }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="observations"
            control={control}
            error={errors.observations}
            label="Observaciones"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShippingAddressForm;
