import { FC } from "react";

import { makeStyles } from "@mui/styles";
import { Box, Theme, Typography } from "@mui/material";

import {
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
} from "@mui/icons-material/";

interface IProps {
  title: string;
  productId: string;
}

const MobileShareSingleProduct: FC<IProps> = (props) => {
  const styles = useStyles();

  const whatsappAPI = "https://api.whatsapp.com/send";
  const linkToProduct = `${process.env.REACT_APP_SELF_URL}products?asin=${props.productId}`;
  const shareMessage = `Puntomall: Ver el siguiente producto: ${props.title}. ${linkToProduct}`;

  return (
    <Box className={styles.root}>
      <Typography fontSize={12} variant="body2" mr={1}>
        Compartir:
      </Typography>

      <Box
        className={styles.iconsButton}
        component="a"
        target="_blank"
        href={`mailto:?subject=${shareMessage}&amp;body=${linkToProduct}`}
      >
        <EmailIcon fontSize="small" className={styles.icon} />
      </Box>

      <Box
        component="a"
        target="_blank"
        className={styles.iconsButton}
        data-action="share/whatsapp/share"
        href={`${whatsappAPI}?text=${shareMessage}`}
      >
        <WhatsAppIcon fontSize="small" className={styles.icon} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: { color: "#EA7443" },
  root: { display: "flex", alignItems: "center" },
  iconsButton: {
    display: "flex",
    borderRadius: 4,
    cursor: "pointer",
    alignItems: "center",
    background: "#FCEEE8",
    justifyContent: "center",
    padding: theme.spacing(0.5),
    margin: `0 ${theme.spacing(0.5)}`,
  },
}));

export default MobileShareSingleProduct;
