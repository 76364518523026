import { FC, MouseEvent } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { DashboardType } from "interfaces/dashboard.interfaces";

import {
  Password as PasswordIcon,
  AccountBox as AccountBoxIcon,
  ReceiptLong as ReceiptLongIcon,
} from "@mui/icons-material";

import {
  List,
  Theme,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";

interface ItemsProps {
  url: string;
  title: string;
  selected?: boolean;
}

interface IProps {
  current: DashboardType;
  displayTitle?: boolean;
}

const Item: FC<ItemsProps> = (props) => {
  const s = useStyles();
  const navigate = useNavigate();

  const goToLink = (e: MouseEvent<HTMLElement>) =>
    navigate(`/profile/${props.url}`);

  return (
    <ListItem
      button
      onClick={goToLink}
      selected={props.selected}
      sx={{ p: 2.5, py: 1 }}
    >
      <ListItemIcon
        sx={{ minWidth: 42 }}
        className={props.selected ? s.selectedListItem : ""}
      >
        {props.children}
      </ListItemIcon>

      <ListItemText
        primary={props.title}
        primaryTypographyProps={{ fontSize: 14 }}
        className={props.selected ? s.selectedListItem : ""}
      />
    </ListItem>
  );
};

const MainListItems: FC<IProps> = ({ current, displayTitle = true }) => {
  const s = useStyles();

  return (
    <List className={s.root}>
      {displayTitle ? (
        <Typography variant="h6" sx={{ p: 2, ml: 1 }}>
          Mi cuenta
        </Typography>
      ) : null}

      <Item
        title="Infomación de la cuenta"
        url="account-settings"
        selected={current === DashboardType.account}
      >
        <AccountBoxIcon />
      </Item>

      <Item
        url="orders"
        title="Mis órdenes"
        selected={current === DashboardType.orders}
      >
        <ReceiptLongIcon />
      </Item>

      {/* <Item
        url="refunds"
        title="Devolución de productos"
        selected={current === DashboardType.refunds}
      >
        <CachedIcon />
      </Item> */}

      <Item
        url="security"
        title="Seguridad"
        selected={current === DashboardType.security}
      >
        <PasswordIcon />
      </Item>

      {/* <Item
        url="wallet"
        title="Mi billetera"
        selected={current === DashboardType.wallet}
      >
        <MonetizationOnIcon />
      </Item> */}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 0,
    paddingTop: 0,
    background: theme.palette.background.paper,
  },

  selectedListItem: {
    color: theme.palette.secondary.main,
  },
}));

export default MainListItems;
