import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";

import bannerMobile from "assets/images/bannerMobile.png";

interface IProps {
  onClick: (asin: string) => void;
}

const MobileBanner: FC<IProps> = (props) => {
  const { onClick } = props;

  return (
    <Box
      sx={{
        pt: 4,
        height: 356,
        display: "flex",
        alignItems: "center",
        position: "relative",
        background: "#0a195b",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          px: 1,
          py: 0.5,
          fontSize: 12,
          color: "#EA7443",
          borderRadius: "4px",
          textTransform: "uppercase",
          backgroundColor: "rgba(234, 116, 67, 0.2)",
        }}
      >
        Preventa
      </Typography>

      <Typography
        mt={1}
        color="white"
        fontSize={24}
        fontWeight={700}
        letterSpacing={1}
        textTransform="uppercase"
      >
        NUEVO IPHONE 13
      </Typography>

      <Typography fontSize={16} color="white" component="div">
        <Box display="inline-block" mr="2px">
          <Typography fontSize={16} color="white" pr="3px">
            pre-venta exclusiva
          </Typography>
          <Box borderTop="2px solid #EA7443" mt="-7px" pr="3px"></Box>
        </Box>
        en PuntoMall
      </Typography>

      <Button
        sx={{
          mt: 1,
          py: 0.5,
          fontSize: 16,
          color: "white",
          fontWeight: 500,
          textTransform: "none",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
        }}
        onClick={() => onClick("B09LP9TM5L")}
      >
        ¿Te lo vas a perder?
      </Button>

      <Box
        height={164}
        component="img"
        src={bannerMobile}
        alt="banner-iphone-13"
        sx={{ objectFit: "contain", position: "absolute", bottom: 0 }}
      />
    </Box>
  );
};

export default MobileBanner;
