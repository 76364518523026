import { FC } from "react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";

import { getPriceInUSD, getPriceInUYU } from "utils/commons";
import { ProductList } from "interfaces/products.interfaces";
import { Currency } from "interfaces/shopping-cart.interfaces";
import { Rating, Typography } from "@mui/material";
import { StarBorder } from "@mui/icons-material";

interface IProps {
  product: ProductList;
}

const MobileProductCard: FC<IProps> = (props) => {
  const { product } = props;

  const s = useStyles();
  const navigate = useNavigate();
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const price =
    currency === Currency.usd
      ? getPriceInUSD(product.price)
      : getPriceInUYU(product.price, cotization);

  const showRating = () => {
    if (!product.productRating) return null;
    if (typeof product.productRating === "number") return product.productRating;
    return Number(product.productRating.split(" ")[0]);
  };

  const description =
    product.productDescription?.length > 60
      ? `${product.productDescription.slice(0, 60)}...`
      : product.productDescription;

  const onClick = () => navigate(`/products?asin=${product.asin}`);

  return (
    <Box className={s.container} onClick={onClick}>
      <Box
        component="img"
        alt={description}
        className={s.img}
        src={product.imgUrl}
      />

      <Box width="70%">
        <Typography variant="body1" color="text.primary" my={1}>
          {description}
        </Typography>

        {product.countReview ? (
          <Box className={s.rating}>
            <Rating
              readOnly
              size="small"
              precision={0.5}
              value={showRating()}
              name="product-feedback"
              emptyIcon={
                <StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography ml={0.5} color="#007171" variant="body2">
              {product.countReview}
            </Typography>
          </Box>
        ) : null}

        {product.price ? (
          <Box sx={{ py: 0.2, display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" sx={{ p: 0, fontWeight: 600 }}>
              {currency === Currency.usd ? "U$S " : "$ "}
              {price[0]}
            </Typography>

            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1em",
                verticalAlign: ".7em",
              }}
            >
              {price[1] ?? ""}
            </Typography>
          </Box>
        ) : (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2em",
              verticalAlign: ".7em",
            }}
          >
            Consultar Precio
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderTop: "1px solid #F1F1F1",
  },
  img: {
    width: 120,
    height: 120,
    objectFit: "contain",
    marginRight: theme.spacing(2),
  },
  rating: { width: "100%", display: "flex", alignItems: "center" },
}));

export default MobileProductCard;
