import { getApartmentLabel } from "utils/forms/aparments.options";
import { Dispatch, FC, MouseEvent, SetStateAction, useState } from "react";

import {
  ApartmentType,
  IShippingAddress,
} from "interfaces/shipping-addresses.interfaces";

import { Box } from "@mui/system";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

import DeleteAddress from "./DeleteAddress";
import UpdateShippingAddress from "./UpdateShippingAddress";
import DefaultAddressFormComponent from "./SetDefaultAddress";

interface AddressItemProps {
  address: IShippingAddress;
  totalAddresses: number;
}

interface AddressMenuProps {
  hideDeleteModal: boolean;
  hideSetDefaultModal: boolean;
  deleteModal: Dispatch<SetStateAction<boolean>>;
  updateModal: Dispatch<SetStateAction<boolean>>;
  setDefaultModal: Dispatch<SetStateAction<boolean>>;
}

const AddressItem: FC<AddressItemProps> = (props) => {
  const { address, totalAddresses } = props;

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showDefaultAddress, setShowDefaultAddress] = useState<boolean>(false);

  return (
    <Box sx={{ position: "relative", p: 3 }}>
      {address.defaultAddress && (
        <Box mb={1}>
          <Typography
            component="span"
            color="text.secondary"
            sx={{
              p: 1,
              ml: -0.5,
              fontSize: 14,
              borderRadius: "6px",
              background: "#F0F0F0",
            }}
          >
            Dirección de envio por defecto
          </Typography>
        </Box>
      )}

      <Typography
        sx={{ textTransform: "capitalize", fontSize: 14, width: { xs: "80%" } }}
      >
        {address.address}, {address.doorNumber}
      </Typography>
      <Typography sx={{ textTransform: "capitalize", fontSize: 14 }}>
        {address.neighborhood} ({address.zipCode})
      </Typography>
      <Typography sx={{ textTransform: "capitalize", fontSize: 14 }}>
        {getApartmentLabel(address.apartment as ApartmentType)}, Uruguay
      </Typography>
      <Typography sx={{ textTransform: "capitalize", fontSize: 14 }}>
        Teléfono: {address.phoneNumber}
      </Typography>

      <AddressMenu
        deleteModal={setShowDeleteModal}
        updateModal={setShowUpdateModal}
        hideDeleteModal={totalAddresses < 2}
        setDefaultModal={setShowDefaultAddress}
        hideSetDefaultModal={address.defaultAddress}
      />

      <DeleteAddress
        addressId={address.id}
        open={showDeleteModal}
        setOpen={() => setShowDeleteModal(false)}
      />

      <DefaultAddressFormComponent
        addressId={address.id}
        open={showDefaultAddress}
        setOpen={() => setShowDefaultAddress(false)}
      />

      <UpdateShippingAddress
        address={address}
        open={showUpdateModal}
        setOpen={() => setShowUpdateModal(false)}
      />
    </Box>
  );
};

const AddressMenu: FC<AddressMenuProps> = (props) => {
  const {
    deleteModal,
    updateModal,
    setDefaultModal,
    hideDeleteModal,
    hideSetDefaultModal,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);

  const handleDeleteAddress = () => {
    setAnchorEl(null);
    deleteModal(true);
  };

  const handleUpdateDefaultAddress = () => {
    setAnchorEl(null);
    setDefaultModal(true);
  };

  const handleUpdateAddress = () => {
    setAnchorEl(null);
    updateModal(true);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ position: "absolute", top: 12, right: 12 }}
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        aria-haspopup="true"
        aria-controls="basic-menu"
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleUpdateAddress}>Editar</MenuItem>

        <MenuItem
          disabled={hideSetDefaultModal}
          onClick={handleUpdateDefaultAddress}
        >
          Dirección de envio por defecto
        </MenuItem>

        <MenuItem onClick={handleDeleteAddress} disabled={hideDeleteModal}>
          Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddressItem;
