import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";

import {
  IFees,
  EditCartBody,
  IShoppingCart,
  AddItemToCartBody,
  IShoppingCartBack,
  CreateOrMergeShoppingCartDto,
  FeesDto,
  ICartAvailabilityResponse,
} from "interfaces/shopping-cart.interfaces";

import { localStorageAPI } from "utils/localStorage";
import { parseAxiosErrorObject } from "utils/errors";

export const shoppingCartServices = {
  /**
   * A function that makes axios request to puntoMall API (/users/:id/cart) and get an shopping cart
   * @returns {IShoppingCart} a shopping cart
   */
  getUserCart: async (): Promise<IShoppingCart> => {
    const shoppingCart: AxiosResponse<IShoppingCartBack> =
      await puntoMallAPI.get(`/users/cart`);

    const { currency, id, cartItems, total, user } = shoppingCart.data;
    return { items: cartItems, total, user, currency, id };
  },

  /**
   * Create or merge a shopping cart by userId and can receive an array with items
   * @param {CreateOrMergeShoppingCartDto} dto
   */
  createOrMergeShoppingCart: async (products: CreateOrMergeShoppingCartDto) => {
    try {
      const token = localStorageAPI.getItem("auth");
      const response: AxiosResponse<any> = await puntoMallAPI.post(
        "/cart",
        { products },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios POST request to puntoMall API (/cart/:id/items) and add new item into shopping cart
   * @param {AddItemToCartBody} body
   * @returns {IShoppingCart} a shopping cart
   */
  addItemToCart: async (body: AddItemToCartBody): Promise<IShoppingCart> => {
    const shoppingCart: AxiosResponse<IShoppingCartBack> =
      await puntoMallAPI.post(`/cart/items`, body);

    return { ...shoppingCart.data, items: shoppingCart.data.cartItems };
  },

  /**
   * A function that makes axios DELETE request to puntoMall API (/cart/:id/items/:itemId) and remove an item into from shopping cart
   * @param {String} itemId
   * @returns {IShoppingCart} a shopping cart
   */
  removeItemFromCart: async (itemId: string): Promise<IShoppingCart> => {
    const shoppingCart: AxiosResponse<IShoppingCartBack> =
      await puntoMallAPI.delete(`/cart/items/${itemId}`);

    return { ...shoppingCart.data, items: shoppingCart.data.cartItems };
  },

  /**
   * A function that makes axios PATCH request to puntoMall API (/cart/:id)
   * and add or remove a given quantity to an item cart
   * @param {EditCartBody} body
   * @returns {IShoppingCart} a shopping cart
   */
  updateICartItem: async (body: EditCartBody): Promise<IShoppingCart> => {
    const shoppingCart: AxiosResponse<IShoppingCartBack> =
      await puntoMallAPI.put(`/cart/items`, body);

    return { ...shoppingCart.data, items: shoppingCart.data.cartItems };
  },

  /**
   * A function that makes axios GET request to puntoMall API (/cart/get-fees)
   * and get the fees that corresponds to a cart
   * @returns {IFees} The fees
   */
  getCurrentFees: async (body: FeesDto): Promise<IFees> => {
    const fees: AxiosResponse<IFees> = await puntoMallAPI.post("/fees", body);

    return fees.data;
  },

  /**
   * A function that makes axios GET request to puntoMall API (/cart/:id/availability)
   * and get the availability of products cart
   * @returns {ICartAvailabilityResponse} The cart availability
   */
  checkProductCartavailability:
    async (): Promise<ICartAvailabilityResponse> => {
      const response: AxiosResponse<ICartAvailabilityResponse> =
        await puntoMallAPI.get("/cart/availability");

      return response.data;
    },
};
