import { FC } from "react";
import { useAppDispatch } from "hooks";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { loginOrRegisterWithFacebook } from "store/user.slice";

import SocialMediaButton from "components/atoms/social-media-button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const FacebookCard: FC = () => {
  const dispatch = useAppDispatch();

  const onClick = async (e: ReactFacebookLoginInfo) => {
    await dispatch(loginOrRegisterWithFacebook(e.accessToken));
  };

  return (
    <FacebookLogin
      callback={onClick}
      fields="name, email"
      appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? "364100512183050"}
      render={({ onClick, isDisabled, isProcessing, isSdkLoaded }: any) => (
        <SocialMediaButton
          variant="facebook"
          onClick={onClick}
          sx={{ width: "100%" }}
          text="Continuar con Facebook"
          disabled={!(isDisabled || isProcessing || isSdkLoaded)}
        />
      )}
    />
  );
};

export default FacebookCard;
