export const productVariationNames = {
  size_name: {
    EN: "Size",
    ES: "Tamaño",
  },

  color_name: {
    EN: "Color",
    ES: "Color",
  },

  variation_size_name: {
    EN: "Size",
    ES: "Tamaño",
  },

  variation_color_name: {
    EN: "Color",
    ES: "Color",
  },

  Color: {
    EN: "Color",
    ES: "Color",
  },

  Capacity: {
    EN: "Capacity",
    ES: "Capacidad",
  },

  variation_service_provider: {
    EN: "Provider",
    ES: "Provedor",
  },

  variation_product_grade: {
    EN: "Product grade",
    ES: "Product grade",
  },
};
