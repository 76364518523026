import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useQuery } from "hooks";

import { Box, useTheme } from "@mui/system";
import { Paper, Typography } from "@mui/material";

import ProductBuyOptions from "./ProductBuyOptions";
import TextWithIcon, { IconTypes } from "components/atoms/text-icon";
import AddOrRemoveFavoriteSection from "./AddOrRemoveFavoriteSection";

import { Currency } from "interfaces/shopping-cart.interfaces";
import { getPriceSavingInformation, getPriceText } from "utils/commons";

import {
  getStateSingleProduct,
  getStateProductsHelpers,
} from "store/products/products.selectors";

import notFoundImage from "assets/images/image_not_found.png";

interface IProps {
  isLoggedIn: boolean;
  hideFavoriteIcon?: boolean;
}

const ProductBuySection: FC<IProps> = (props) => {
  const query = useQuery();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const {
    price,
    mainImage,
    salePrice,
    retailPrice,
    priceSaving,
    productTitle,
    imageUrlList,
    asin: productBaseId,
  } = useAppSelector(getStateSingleProduct);

  const { disableForMissingVariantSelected } = useAppSelector(
    getStateProductsHelpers
  );

  const { productIsNotValid } = useAppSelector((state) => state.products);
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const asin = query.get("asin") || productBaseId;

  const discountInformation = getPriceSavingInformation({
    price,
    currency,
    cotization,
    retailPrice,
  });

  const finalPriceToShow = price || salePrice || retailPrice;

  return (
    <Paper sx={{ p: 2, position: "relative", mx: { md: 2 } }} elevation={1}>
      {price && retailPrice && priceSaving ? (
        <>
          <Typography
            fontSize={14}
            variant="body2"
            component="div"
            color="text.secondary"
            sx={{ display: "flex" }}
          >
            Precio:
            <Typography
              variant="body2"
              sx={{ textDecorationLine: "line-through", ml: 0.5 }}
            >
              {currency === Currency.usd ? "U$S" : "$"}{" "}
              {discountInformation.retail}
            </Typography>
          </Typography>

          <Typography variant="body2" fontSize={14} color="text.secondary">
            {`Ahorras: ${currency === Currency.usd ? "U$S" : "$"} ${
              typeof discountInformation.saving === "number"
                ? Math.ceil(discountInformation.saving * 100) / 100
                : discountInformation.saving
            } ${discountInformation.discount}`}
          </Typography>
        </>
      ) : null}

      {!finalPriceToShow ? (
        <Typography my={1} variant="h6">
          Producto no disponible
        </Typography>
      ) : (
        <Typography
          my={1}
          variant="h5"
          fontWeight="bold"
          color={palette.secondary.main}
        >
          {getPriceText({
            currency,
            salePrice,
            cotization,
            price: finalPriceToShow,
          })}
        </Typography>
      )}

      <TextWithIcon
        icon={IconTypes.star}
        text="​Recibí este producto de 5 a 10 días hábiles seleccionando envío express"
      />

      <TextWithIcon
        icon={IconTypes.truck}
        text="Agrega el producto al carrito para conocer los costos de envío"
      />

      <TextWithIcon
        icon={IconTypes.creditCard}
        text="Hasta 12 cuotas fijas en pesos"
      />

      {productIsNotValid ? (
        <Box my={2}>
          <Typography my={1} variant="subtitle1" color="text.secondary">
            Producto prohibido por la aduana de su país.
          </Typography>

          <Typography
            variant="body1"
            onClick={() => navigate(-1)}
            sx={{ color: palette.secondary.main, cursor: "pointer" }}
          >
            Continúa comprando
          </Typography>
        </Box>
      ) : (
        <>
          <ProductBuyOptions
            asin={asin}
            title={productTitle}
            price={price || salePrice || retailPrice}
            show={!!price || !!salePrice || !!retailPrice}
            mainImage={
              mainImage?.imageUrl ||
              (imageUrlList && imageUrlList[0]) ||
              notFoundImage
            }
            showVariantLimitation={disableForMissingVariantSelected}
          />

          <Box display="flex" justifyContent="center">
            <TextWithIcon
              icon={IconTypes.verified}
              text="Este producto tiene Garantía de Entrega"
            />
          </Box>

          {props.isLoggedIn &&
          !props.hideFavoriteIcon &&
          !disableForMissingVariantSelected ? (
            <AddOrRemoveFavoriteSection asin={asin} />
          ) : null}
        </>
      )}
    </Paper>
  );
};

export default ProductBuySection;
