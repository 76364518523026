import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";

import { parseAxiosErrorObject } from "utils/errors";
import { IHistory } from "interfaces/user.interfaces";

export const HistoryServices = {
  /**
   * A function that makes axios GET request to puntoMall API (/history) and get an array with history records
   * @returns {IHistory[]} array of history records
   */
  getAllHistoryRecords: async (): Promise<IHistory[]> => {
    try {
      const history: AxiosResponse<IHistory[]> = await puntoMallAPI.get(
        "/history"
      );

      return history.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios POST request to puntoMall API (/history) and create a new history records
   * @returns {IHistory} reated history records
   */
  createNewHistoryRecord: async (
    productId: string,
    title: string
  ): Promise<IHistory> => {
    try {
      const history: AxiosResponse<IHistory> = await puntoMallAPI.post(
        "/history",
        { productId, title }
      );

      return history.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
