import { FC } from "react";
import { useAppSelector } from "hooks";
import { Box, Typography } from "@mui/material";

import CarrouselImage from "./CarrouselImage";
import ProductFeatures from "../web/ProductFeatures";
import ProductVariations from "../web/ProductVariations";
import ProductBuySection from "../web/ProductBuySection";
import ProductDetailsMobile from "./ProductDetailsMobile";
import RatingStars from "components/molecules/rating-stars";
import SimilarProductsList from "../commons/SimilarProductsList";
import MobileShareSingleProduct from "./MobileShareSingleProduct";

import { getStateSingleProduct } from "store/products/products.selectors";

const MainMobileView: FC = () => {
  const single = useAppSelector(getStateSingleProduct);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  const showRating = () => {
    if (typeof single.productRating === "number") return single.productRating;
    return Number(single.productRating?.split(" ")[0]);
  };

  return (
    <Box padding={3}>
      <Typography>
        {single.productTitle || single.productDescription}
      </Typography>

      {single.productRating && single.productRating.split ? (
        <Box paddingY={0.5}>
          <RatingStars
            countReview={single.countReview}
            value={showRating() ?? 0}
          />
        </Box>
      ) : null}

      {single.imageUrlList && single.imageUrlList.length ? (
        <CarrouselImage
          productId={single.asin}
          isLoggedIn={isLoggedIn}
          images={single.imageUrlList}
        />
      ) : null}

      <MobileShareSingleProduct
        productId={single.asin}
        title={single.productTitle}
      />

      <ProductVariations variations={single.variations} />
      <ProductBuySection isLoggedIn={isLoggedIn} hideFavoriteIcon />

      <Box my={2}>
        <ProductFeatures />

        <ProductDetailsMobile
          features={single.features}
          productDetails={single.productDetails}
        />

        {single.productDescription ? (
          <>
            <Typography
              my={1}
              fontSize={18}
              variant="subtitle1"
              color="text.secondary"
            >
              Más detalles del producto
            </Typography>

            {single.productDescription?.split(". ").map((p, i) => (
              <Typography
                my={1}
                key={i}
                component="p"
                fontSize={14}
                variant="body2"
                color="text.primary"
              >
                {p}.
              </Typography>
            ))}
          </>
        ) : null}

        <SimilarProductsList searchQuery={single.productTitle} hideArrows />
      </Box>
    </Box>
  );
};

export default MainMobileView;
