import { FC } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

interface IProps {
  description: string;
}

const ProductDescription: FC<IProps> = (props) => {
  const { description } = props;

  return (
    <Box width="95%">
      <Typography
        my={1}
        fontSize={18}
        variant="subtitle1"
        color="text.secondary"
      >
        Más detalles del producto
      </Typography>

      <Typography variant="body2" component="p" fontSize={14}>
        {description}
      </Typography>
    </Box>
  );
};

export default ProductDescription;
