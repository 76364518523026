import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/material";

import { parseProductInformation } from "utils/commons";
import { getAllHistoryRecords } from "store/user.slice";
import { ProductList } from "interfaces/products.interfaces";

import ProductCardSkeleton from "components/atoms/product-card-skeleton";
import CustomProductList from "components/molecules/custom-product-list";

const LastViewedHistory: FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading, isLoggedIn, navigationHistory, isnNavigationFetched } =
    useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (isLoggedIn && !isnNavigationFetched) dispatch(getAllHistoryRecords());
  }, [dispatch, isLoggedIn, isnNavigationFetched]);

  const products: ProductList[] = navigationHistory
    .slice(0, 5)
    .map((record) => parseProductInformation(record.product, record.productId));

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" mt={3}>
        <ProductCardSkeleton numberOfItems={5} skeletonTitle />
      </Box>
    );

  return (
    <CustomProductList
      products={products}
      title="Basado en tu última visita"
      emptyMsj="Aún no viste publicaciones."
      urlTo={{ title: "Ver historial →", url: "navigation" }}
    />
  );
};

export default LastViewedHistory;
