import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { IProduct, ProductList } from "interfaces/products.interfaces";

export const productsServices = {
  /**
   * A function that makes axios request to puntoMall API (/products) and get an array of products
   * @param {String} keyboard
   * @returns {ProductList[]} array of product
   */
  getProducts: async (
    searchQuery: string,
    page: number
  ): Promise<{ products: ProductList[]; currentPage: number }> => {
    try {
      const products: AxiosResponse<ProductList[]> = await puntoMallAPI.get(
        `/products?${searchQuery}`
      );

      return { products: products.data, currentPage: page };
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios request to API (/products/:asin`) and get a single product
   * @param {String} asin (id) of the product
   * @returns {IProduct} single product
   */
  getProductByAsin: async (asin: String): Promise<IProduct> => {
    try {
      const product: AxiosResponse<IProduct> = await puntoMallAPI.get(
        `/products/${asin}`
      );

      return product.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
