import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { parseProductInformation } from "utils/commons";
import { IStatistic } from "interfaces/statistics.interfaces";
import { getBestSellersProducts } from "store/statistics.slice";

import CustomProductList from "components/molecules/custom-product-list";

const BestSellerProducts: FC = () => {
  const dispatch = useAppDispatch();
  const { bestSellers, isLoading } = useAppSelector(
    (state) => state.statistics
  );

  useEffect(() => {
    if (!bestSellers.length) dispatch(getBestSellersProducts());
  }, [dispatch, bestSellers.length]);

  const parseStatisticsToProductList = (statistic: IStatistic[]) =>
    statistic
      .map((a) =>
        a.product && a.product.asin
          ? parseProductInformation(a?.product, a.productId)
          : ({} as any)
      )
      .slice(0, 5);

  if (isLoading) return null;

  return (
    <>
      {bestSellers && bestSellers.length ? (
        <CustomProductList
          title="Productos más vendidos"
          products={parseStatisticsToProductList(bestSellers)}
          urlTo={{ title: "Ver todas →", url: "best-sellers" }}
        />
      ) : null}
    </>
  );
};

export default BestSellerProducts;
