import { Grid } from "@mui/material";
import { useAppSelector } from "hooks";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import ValidationCard from "./components/GenValidationCard";

const GenValidationCode: FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  return (
    <Grid
      container
      marginTop={6}
      columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
    >
      <Grid item xs={4} sm={5} md={6}>
        <ValidationCard />
      </Grid>
    </Grid>
  );
};

export default GenValidationCode;
