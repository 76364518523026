import { FC } from "react";
import { Box, useTheme } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";

const SimpleFooter: FC = () => {
  const { breakpoints } = useTheme();
  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));

  return (
    <Box width="100%">
      <Typography
        fontSize={12}
        align="center"
        color="text.secondary"
        sx={{ background: "#ffff", m: 0, p: 2 }}
      >
        {downToMiddleScreenSize
          ? `© 2021 PuntoMall®  by SpaceDev`
          : `© 2022 PuntoMall® - Todos los derechos reservados - Todas las marcas son propiedad de sus respectivos dueños`}
      </Typography>

      <Box
        sx={{
          m: 0,
          px: 2,
          py: { xs: 2, md: 3 },
          background: "#061B5E",
        }}
      >
        <Typography
          fontSize={12}
          align="center"
          sx={{
            mx: "auto",
            color: "#ffffff8f",
            width: { md: "80%", lg: "70%" },
          }}
        >
          Con tu registro GRATIS en PuntoMall obtienes tu casillero o dirección
          postal para compras en Estados Unidos. Compra tus marcas preferidas en
          tiendas de Estados Unidos como Carter's, GAP, Macys, Nike, Amazon,
          Zappos y Victoria's Secret, entre muchas más. PuntoMall las lleva a tu
          casa en el país de América Latina y el Caribe donde residas.
        </Typography>
      </Box>
    </Box>
  );
};

export default SimpleFooter;
