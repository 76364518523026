import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";

import {
  IShippingAddress,
  BodyShippingAddress,
} from "interfaces/shipping-addresses.interfaces";

export const ShippingAddressesService = {
  /**
   * A function that makes axios request to puntoMall API (/shipping-addresses)
   * and get an array of addresses by userId
   * @returns {IShippingAddress} array with all addresses
   */
  getShippingAddresses: async (): Promise<IShippingAddress[]> => {
    const addresses: AxiosResponse<IShippingAddress[]> = await puntoMallAPI.get(
      "/shipping-addresses"
    );

    return addresses.data;
  },

  /**
   * Create a new shipping address with the specified fields by userId
   * @param {BodyShippingAddress} body
   * @returns created shipping address
   */
  createShippingAddress: async (
    body: BodyShippingAddress
  ): Promise<IShippingAddress> => {
    try {
      const response: AxiosResponse<IShippingAddress> = await puntoMallAPI.post(
        "/shipping-addresses",
        body
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * Delete an existing shipping address by id
   * @param {string} id
   */
  removeShippingAddress: async (id: string): Promise<void> => {
    try {
      const response: AxiosResponse<void> = await puntoMallAPI.delete(
        `/shipping-addresses/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * Update an existing shipping address by id
   * @param {string} id
   * @param {BodyShippingAddress} body
   * @returns updated shipping address
   */
  updateShippingAddress: async (
    id: string,
    body: BodyShippingAddress
  ): Promise<IShippingAddress> => {
    try {
      const response: AxiosResponse<IShippingAddress> = await puntoMallAPI.put(
        `/shipping-addresses/${id}`,
        body
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * Update defautly shipping address and remove it from the another addresses.
   * @param { string} id
   */
  updateDefaultShippingAddress: async (id: string): Promise<void> => {
    try {
      const response: AxiosResponse<void> = await puntoMallAPI.put(
        `/shipping-addresses/${id}/default`
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
