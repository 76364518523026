import { FC } from "react";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const PickupInformation: FC = () => {
  return (
    <Box px={1} m={0} mb={1}>
      <Typography component="div" fontSize={16}>
        <Typography fontWeight="bold">Sucursal:</Typography>
        Dr. José María Montero 2881, 11300 Montevideo, Departamento de
        Montevideo.
      </Typography>
    </Box>
  );
};

export default PickupInformation;
