import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";

import { getAddressStore } from "store/addresses/addresses.selectors";
import { removeShippingAddress } from "store/addresses/addresses.actions";

import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";

interface IProps {
  open: boolean;
  addressId: string;
  setOpen: (value: boolean) => void;
}

const DeleteAddress: FC<IProps> = (props) => {
  const { open, setOpen, addressId } = props;

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(getAddressStore);

  const onDelete = () =>
    dispatch(removeShippingAddress(addressId)).then(() => setOpen(false));

  return (
    <CustomModal handleClose={setOpen} open={open} size="small">
      <Typography sx={{ mb: 2 }}>Eliminar dirección</Typography>

      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={14} color="text.secondary">
            ¿Está seguro que desea eliminar permanente esta dirección de
            entrega?
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <CustomButton
            text="Continuar"
            variant="primary"
            onClick={onDelete}
            disabled={isLoading}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomButton
            text="Cancelar"
            disabled={isLoading}
            variant="secondary"
            sx={{ width: "100%" }}
            onClick={() => setOpen(false)}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default DeleteAddress;
