import { ChangeEvent, FC } from "react";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

export interface IPagination {
  total: number;
  current: number;
}

interface IProps {
  page: IPagination;
  handlerFunction: (num: number) => void;
}

/**
 * Component based on MUI for create a custom pagination.
 * @param {IPagination} page
 * @param {Function} handlerFunction
 *
 * @example
 * return <CustomPagination />
 */

const CustomPagination: FC<IProps> = (props) => {
  const { handlerFunction, page } = props;

  const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
    handlerFunction(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination
        size="large"
        shape="rounded"
        count={page.total}
        page={page.current}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default CustomPagination;
