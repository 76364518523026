import { KeyboardEvent } from "react";
import { useAppSelector } from "hooks";
import { useStyles } from "./styles";

import { AccountCircle } from "@mui/icons-material";
import { Box, Divider, Drawer, Typography } from "@mui/material";

import AccountList from "./AccountList";
import MobileCategoriesList from "./MobileCategories";
import MoreAccountOptions from "./MoreAccountOptions";
import MobileCurrencyOptions from "./MobileCurrencyOptions";

interface IProps {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const SwipeableMobileMenu: any = (props: IProps) => {
  const { open, setOpen } = props;

  const s = useStyles();
  const { isLoggedIn, user } = useAppSelector((state) => state.userInformation);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event?.type === "keydown" &&
      ((event as KeyboardEvent).key === "Tab" ||
        (event as KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  return (
    <Drawer
      open={open}
      anchor="left"
      // onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
    >
      <Box role="presentation" minWidth="300px">
        <Box className={s.header}>
          <AccountCircle sx={{ fontSize: 36 }} />

          <Box sx={{ mx: 1 }}>
            <Typography m={0} fontSize={12}>
              Hola
            </Typography>

            <Typography m={0} variant="body2">
              {isLoggedIn ? user?.firstName : "Bienvenido"}
            </Typography>
          </Box>
        </Box>

        <AccountList isLoggedIn={isLoggedIn} toggleView={setOpen} />

        <MobileCurrencyOptions toggleView={setOpen} />
        <MobileCategoriesList toggleView={setOpen} />
        <MoreAccountOptions toggleView={setOpen} />
        <Divider />
      </Box>
    </Drawer>
  );
};

export default SwipeableMobileMenu;
