import { Navigate, Route, Routes } from "react-router-dom";

import SignInPage from "pages/signIn";
import SignUpPage from "pages/signUp";
import ForgotPassword from "pages/forgot-password";
import EmailValidation from "pages/email-validation";

import AuthLayout from "components/organisms/auth-layout";
import CheckIfDontHaveUser from "./utils/CheckIfDontHaveUser";

const AuthRoutes = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route
          path="/signin"
          element={
            <CheckIfDontHaveUser>
              <SignInPage />
            </CheckIfDontHaveUser>
          }
        />

        <Route
          path="/signup"
          element={
            <CheckIfDontHaveUser>
              <SignUpPage />
            </CheckIfDontHaveUser>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <CheckIfDontHaveUser>
              <ForgotPassword />
            </CheckIfDontHaveUser>
          }
        />

        <Route
          path="/email-validation"
          element={
            <CheckIfDontHaveUser>
              <EmailValidation />
            </CheckIfDontHaveUser>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthLayout>
  );
};

export default AuthRoutes;
