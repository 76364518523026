import { FC } from "react";
import { Chip } from "@mui/material";
import { IOrder, OrderStatusEnum } from "interfaces/orders.interfaces";

interface IOrderStatusChipProps {
  label: string;
  color: "red" | "green";
}

export const OrderStatusChip: FC<IOrderStatusChipProps> = ({
  label,
  color,
}) => {
  if (color === "red") {
    return (
      <Chip
        sx={{
          backgroundColor: "#FFE9E9",
          color: "#FF4D4D",
          paddingX: 1.25,
          paddingY: 0.5,
          borderRadius: 1,
        }}
        label={label}
      />
    );
  }

  return (
    <Chip
      sx={{
        backgroundColor: "#ECF7ED",
        color: "#37833B",
        paddingX: 1.25,
        paddingY: 0.5,
        borderRadius: 0.5,
      }}
      label={label}
    />
  );
};

interface IOrderStatusProps {
  order: IOrder;
}

const OrderStatus: FC<IOrderStatusProps> = ({
  order: { status, amazonTrackigs },
}) => {
  const getOrderStatusChip = () => {
    switch (status) {
      case OrderStatusEnum.FAILED:
      case OrderStatusEnum.CANCELED:
        return <OrderStatusChip color="red" label="Cancelada" />;

      case OrderStatusEnum.REFUND:
        return <OrderStatusChip color="red" label="Procesando devolución" />;

      case OrderStatusEnum.PICK_UP:
      case OrderStatusEnum.COMPLETED:
      case OrderStatusEnum.DELIVERED:
        return <OrderStatusChip color="green" label="Entregado" />;

      case OrderStatusEnum.SHIPPING:
      case OrderStatusEnum.IN_DISTRIBUTION: // viaje a uruguay
        return <OrderStatusChip color="green" label="En viaje a Uruguay" />;

      case OrderStatusEnum.READY_TO_DELIVER: // skypostal por enviar a uruguay
        return <OrderStatusChip color="green" label="Pronto para salir" />;

      case OrderStatusEnum.PACKAGE_SHIPPED:
        return <OrderStatusChip color="green" label="Recibido en Uruguay" />;

      case OrderStatusEnum.PACKAGE_RECEIVED: // llega a skypostal
        return <OrderStatusChip color="green" label="Recibido en depósito" />;

      case OrderStatusEnum.PAID:
      case OrderStatusEnum.ZINC_PAID:
      case OrderStatusEnum.ZINC_PROCCESSING:
        if (amazonTrackigs.filter((tracking) => tracking.status).length) {
          return (
            <OrderStatusChip color="green" label="En camino al depósito" />
          );
        }
        return <OrderStatusChip color="green" label="Confirmada" />;

      case OrderStatusEnum.NEW:
      case OrderStatusEnum.PROCESSING:
      default:
        return <OrderStatusChip color="red" label="Pendiente" />;
    }
  };

  return <>{getOrderStatusChip()}</>;
};

export default OrderStatus;
