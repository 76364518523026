import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getFees } from "./fees.actions";
import { IFeesState } from "./fees.interfaces";
import { IFees } from "interfaces/shopping-cart.interfaces";

const initialState: IFeesState = {
  data: {
    total: 0,
    feePuntoMall: 0,
    weight: 0,
    freight: 0,
    handling: 0,
    realWeight: 0,
    deliveryFee: 0,
    productsTotal: 0,
    bigPriceDetected: false,
    excededLimitImportationAmount: false,
  },
  alerts: {
    bigPriceDetected: false,
    excededQuantityLimit: false,
    excededLimitImportationAmount: false,
  },
  loaders: {
    getFeesLoader: false,
  },
};

const feesSlice = createSlice({
  name: "fees-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFees.fulfilled, (state, action: PayloadAction<IFees>) => {
        const {
          bigPriceDetected,
          excededQuantityLimit,
          excededLimitImportationAmount,
        } = action.payload;

        state.data = action.payload;
        state.loaders.getFeesLoader = false;

        if (excededLimitImportationAmount)
          state.alerts.excededLimitImportationAmount = true;

        if (bigPriceDetected) state.alerts.bigPriceDetected = true;
        if (excededQuantityLimit) state.alerts.excededQuantityLimit = true;
      })
      .addCase(getFees.pending, (state) => {
        state.loaders.getFeesLoader = true;
        state.alerts = initialState.alerts;
      })
      .addCase(getFees.rejected, (state) => {
        state = initialState;
      });
  },
});

export default feesSlice.reducer;
