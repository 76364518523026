import { FC } from "react";
import { genRandomId } from "utils/commons";
import { useNavigate } from "react-router-dom";
import { ProductList } from "interfaces/products.interfaces";

import { makeStyles } from "@mui/styles";
import { Container, Grid, Box, Theme, Typography } from "@mui/material";

import ProductCard from "../product-card";
import bubblesImg from "assets/images/bubbles_card.svg";

interface IProps {
  urlTo: ILink;
  title: string;
  emptyMsj?: string;
  products: ProductList[];
}

interface ILink {
  url: string;
  title: string;
}

const StyledProductList: FC<IProps> = (props) => {
  const { products, title, urlTo, emptyMsj } = props;

  const s = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={s.root}>
      <Box className={s.decorator} />

      <Box
        src={bubblesImg}
        component="img"
        className={s.decorator}
        sx={{ objectFit: "cover" }}
      />

      <Container sx={{ mt: { md: 6, xs: 2 } }} maxWidth="xl">
        <Box
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="#FFFF" mx={1}>
            {title}
          </Typography>

          <Typography
            variant="body2"
            className={s.btn}
            onClick={() => navigate(`/${urlTo.url}`)}
          >
            {urlTo.title}
          </Typography>
        </Box>

        {products.length ? (
          <Grid container>
            {products.map((product) => (
              <Grid
                item
                sm={6}
                md={4}
                lg={3}
                xs={12}
                xl={2.4}
                key={genRandomId()}
              >
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" ml={{ md: 1 }}>
            {emptyMsj}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    paddingTop: theme.spacing(4),
  },

  decorator: {
    zIndex: -1,
    height: 440,
    width: "100vw",
    position: "absolute",
    background: theme.palette.primary.light,
  },

  btn: {
    color: "#ffff",
    borderRadius: 6,
    cursor: "pointer",
    textDecoration: "none",
    marginRight: theme.spacing(1),
    background: theme.palette.secondary.main,
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
  },
}));

export default StyledProductList;
