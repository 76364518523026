import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";

import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from "@mui/icons-material";

import {
  removeFavorite,
  getUserFavorites,
  createNewFavorite,
} from "store/favorites/favorites.actions";

import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { getFavoritesList } from "store/favorites/favorites.selectors";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

interface IProps {
  asin: string;
  getFavorites?: boolean;
  useTextButton?: boolean;
  withBorderShadow?: boolean;
  withIconAbsolute?: boolean;
}

const AddOrRemoveFavoriteSection: FC<IProps> = (props) => {
  const {
    asin,
    useTextButton,
    withBorderShadow,
    getFavorites = true,
    withIconAbsolute = true,
  } = props;

  const s = useStyles();
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(getFavoritesList);

  useEffect(() => {
    if (!favorites.length && getFavorites) dispatch(getUserFavorites());
  }, [dispatch, favorites.length, getFavorites]);

  const isFavorite = favorites.filter((item) => item.productId === asin);

  const deleteFav = () => {
    dispatch(removeFavorite(isFavorite[0]?.id)).then(() =>
      dispatch(
        createSnackbarMessage({
          snackbarType: SnackbarType.success,
          message: "Eliminado correctamente de favoritos",
          origin: { horizontal: "center", vertical: "top" },
        })
      )
    );
  };

  const addFavorite = () => {
    dispatch(createNewFavorite({ productId: asin })).then(() =>
      dispatch(
        createSnackbarMessage({
          snackbarType: SnackbarType.success,
          origin: { horizontal: "center", vertical: "top" },
          message: "Agregado correctamente a la lista de favoritos",
        })
      )
    );
  };

  if (!favorites) return null;

  if (isFavorite.length) {
    if (useTextButton) {
      return (
        <Typography
          color="#007171"
          borderLeft="1px solid #D9DDE1"
          onClick={deleteFav}
          sx={{ cursor: "pointer", pl: 3, ml: 3 }}
        >
          Eliminar de mi lista de deseos
        </Typography>
      );
    }

    return (
      <FavoriteIcon
        fontSize="large"
        onClick={deleteFav}
        className={`${s.icon} ${s.fill} ${
          withBorderShadow ? s.borderShadow : null
        } ${withIconAbsolute ? s.iconAbsolute : null}`}
      />
    );
  }

  if (useTextButton) {
    return (
      <Typography
        color="#007171"
        onClick={addFavorite}
        borderLeft="1px solid #D9DDE1"
        sx={{ cursor: "pointer", pl: 3, ml: 3 }}
      >
        Guardar en mi lista de deseos
      </Typography>
    );
  }

  return (
    <FavoriteBorderIcon
      fontSize="large"
      onClick={addFavorite}
      className={`${s.icon} ${s.empty}  ${
        withBorderShadow ? s.borderShadow : null
      } ${withIconAbsolute ? s.iconAbsolute : null}`}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  fill: { color: theme.palette.secondary.main },
  empty: { color: "rgba(8, 35, 48, 0.24)" },
  icon: { cursor: "pointer" },
  iconAbsolute: { top: 12, right: 12, position: "absolute" },
  borderShadow: {
    padding: 3,
    borderRadius: 6,
    background: "rgba(119, 117, 119, 0.14)",
  },
}));

export default AddOrRemoveFavoriteSection;
