import { useNavigate } from "react-router";
import { FC, MouseEvent, ReactChild } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";

import VariationDropdown from "./VariationDropdown";
import TextVariationCard from "components/molecules/text-variation-card";
import ImageVariationCard from "components/molecules/image-variation-card";

import { Locale } from "interfaces/shopping-cart.interfaces";
import { productVariationNames } from "utils/locale/variations";
import { checkIfIsVariationDropdown, sortByKey } from "utils/commons";
import { getStateProductsHelpers } from "store/products/products.selectors";
import { setDisabledForMissingVariantSelected } from "store/products/products.slice";

import {
  IProductVariation,
  IProductVariationValue,
} from "interfaces/products.interfaces";

interface IProps {
  variations: IProductVariation[];
}
interface IVariationCard {
  variationName: string;
  val: IProductVariationValue;
}

const ProductVariations: FC<IProps> = (props) => {
  const { variations } = props;
  const { locale } = useAppSelector((state) => state.globals);

  const sortedVariations: IProductVariation[] =
    variations && variations.length
      ? sortByKey(variations, "variationName")
      : [];

  return (
    <Box mt={1} mb={1}>
      {variations && variations.length
        ? sortedVariations.map((variation, index) => (
            <Box my={1.5} key={index}>
              {variation.values.length ? (
                <Typography variant="body2" component="h6">
                  {productVariationNames[variation.variationName]
                    ? productVariationNames[variation.variationName][
                        locale || Locale.es
                      ]
                    : "Variante"}
                </Typography>
              ) : null}

              <VariationContainer>
                {checkIfIsVariationDropdown(variation) ? (
                  <VariationDropdown variation={variation} />
                ) : (
                  <>
                    {variation.values.length
                      ? variation.values
                          .slice()
                          .sort((a, b) => {
                            if (a.value < b.value) return -1;
                            if (a.value > b.value) return 1;
                            return 0;
                          })
                          .map((raw, index) => (
                            <VariationCard
                              val={raw}
                              key={index}
                              variationName={variation.variationName}
                            />
                          ))
                      : null}
                  </>
                )}
              </VariationContainer>
            </Box>
          ))
        : null}

      {variations && variations.length ? (
        <Divider sx={{ mt: 1, mb: 1 }} />
      ) : null}
    </Box>
  );
};

const VariationCard: FC<IVariationCard> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { disableForMissingVariantSelected } = useAppSelector(
    getStateProductsHelpers
  );

  const available = props.val.available;
  const isTextCard = !props.val.imageUrl;
  const asin = props.val.asin || props.val.dpUrl;

  const onClick = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!available) return;

    navigate(
      `/products?asin=${asin}&${props.variationName}=${props.val.value}`,
      { replace: disableForMissingVariantSelected }
    );

    dispatch(setDisabledForMissingVariantSelected(false));
  };

  const cardProps = {
    onClick: onClick,
    variation: props.val,
    variationName: props.variationName,
    key: props.val.asin ?? props.val.dpUrl,
  };

  if (!asin || props.val.value === "Select") return null;

  return isTextCard ? (
    <TextVariationCard {...cardProps} />
  ) : (
    <ImageVariationCard {...cardProps} />
  );
};

const VariationContainer = ({ children }: { children: ReactChild }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      {children}
    </Box>
  );
};

export default ProductVariations;
