import { FC } from "react";
import { useNavigate } from "react-router-dom";

import theme from "styles/muiTheme";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import banner from "assets/images/banner.svg";
import MobileBanner from "./MobileBanner";

const Banner: FC = () => {
  const navigate = useNavigate();
  const downToMiddleScreenSize = useMediaQuery(theme.breakpoints.down("md"));

  const goToProduct = (asin: string) => navigate(`/products?asin=${asin}`);

  if (downToMiddleScreenSize) return <MobileBanner onClick={goToProduct} />;

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        src={banner}
        width="100vw"
        component="img"
        alt="banner-iphone-13"
        sx={{ objectFit: "contain" }}
      />

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{
          top: "50%",
          right: "15%",
          position: "absolute",
          transform: "translateY(-50%)",
        }}
      >
        <Typography
          sx={{
            px: 1,
            py: 0.5,
            fontSize: 14,
            borderRadius: "4px",
            color: "#EA7443",
            textTransform: "uppercase",
            backgroundColor: "rgba(234, 116, 67, 0.2)",
          }}
        >
          Preventa
        </Typography>

        <Typography
          color="white"
          fontSize={36}
          fontWeight={700}
          letterSpacing={1}
          textTransform="uppercase"
        >
          NUEVO IPHONE 13
        </Typography>

        <Typography fontSize={18} color="white" component="div">
          <Box display="inline-block" mr="2px">
            <Typography fontSize={18} color="white" pr="3px">
              pre-venta exclusiva
            </Typography>
            <Box borderTop="2px solid #EA7443" mt="-7px" pr="3px"></Box>
          </Box>
          en PuntoMall
        </Typography>

        <Button
          sx={{
            mt: 2,
            px: 2,
            py: 0.5,
            color: "white",
            fontWeight: 500,
            fontSize: "18px",
            textTransform: "none",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
          }}
          onClick={() => goToProduct("B09LP9TM5L")}
        >
          ¿Te lo vas a perder?
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
