import { Box, Grid } from "@mui/material";
import { scrollTop } from "utils/commons";
import { useNavigate } from "react-router-dom";
import { FC, Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { getCartState } from "store/cart/cart.selectors";
import { fetchCartByUserId } from "store/cart/cart.actions";
import { deleteCartExceptions } from "store/cart/cart.slice";
import { resetCheckoutState } from "store/checkout/checkout.slice";
import { getAddressStore } from "store/addresses/addresses.selectors";
import { getShippingAddresses } from "store/addresses/addresses.actions";

import {
  getCheckoutHelpers,
  getIfShowConfirmationView,
} from "store/checkout/checkout.selectors";

import SimpleTitle from "components/atoms/simple-title";
import CustomAlert from "components/atoms/custom-alert";
import PaymentMethods from "./components/PaymentMethods";
import ConfirmPurchase from "./components/ConfirmPurchase";
import CustomLayout from "components/atoms/simple-layout";
import CheckoutStepper from "./components/CheckoutStepper";
import DeliveryMethods from "./components/DeliveryMethods";
import BackdropLoader from "components/atoms/backdrop-loader";
import ShippingAddressView from "./components/ShippingAddress";
import PersonalInformation from "./components/PersonalInformation";
import ShoppingCartFees from "components/organisms/shopping-cart-fees";
import PaymentCheckoutIcons from "components/molecules/payment-checkout-icons";

const CheckoutPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isActiveCart, exceptions } = useAppSelector(getCartState);
  const { hideStepAddres } = useAppSelector(getCheckoutHelpers);
  const confirmationView = useAppSelector(getIfShowConfirmationView);
  const { list, isFetchched, isLoading } = useAppSelector(getAddressStore);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (!isFetchched) dispatch(getShippingAddresses());
  }, [dispatch, isFetchched]);

  useEffect(() => {
    if (!isActiveCart)
      dispatch(fetchCartByUserId()).then((data: any) => {
        if (!data?.payload?.total)
          navigate("/shopping-cart", { replace: true });
      });
  }, [dispatch, isActiveCart, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetCheckoutState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (exceptions.length > 0) {
      setTimeout(() => {
        dispatch(deleteCartExceptions());
      }, 10000);
    }
  }, [dispatch, exceptions]);

  if (isLoading && !isFetchched) return <BackdropLoader />;

  return (
    <CustomLayout pd={4}>
      {confirmationView && <PaymentMethods />}

      <Fragment>
        <Box paddingLeft={2} marginBottom={1}>
          <SimpleTitle title="Finalizar compra" />

          <Box display={{ xs: "none", md: "block" }}>
            <CheckoutStepper />
          </Box>
        </Box>

        <Box sx={{ mx: 2, my: 2 }}>
          {exceptions && exceptions.length
            ? exceptions.map((exception) => (
                <CustomAlert
                  severity="warning"
                  title="Importante"
                  message={exception.message}
                />
              ))
            : null}
        </Box>

        <Grid container spacing={{ md: 1 }}>
          <Grid item xs={12} md={8} lg={9}>
            <PersonalInformation />
            <DeliveryMethods />

            {!hideStepAddres && <ShippingAddressView addresses={list} />}

            <ConfirmPurchase />
          </Grid>

          <Grid item xs={12} md={4} lg={3} sx={{ mt: 1 }}>
            <Box marginX={2}>
              <PaymentCheckoutIcons />
              <ShoppingCartFees showNavigation={false} />
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    </CustomLayout>
  );
};

export default CheckoutPage;
