import { FC } from "react";
import { Typography } from "@mui/material";

interface IProps {
  label?: string;
  onClick?: () => void;
}

const StepOptionButton: FC<IProps> = (props) => {
  const { label, onClick } = props;

  return (
    <Typography
      sx={{
        px: 2,
        py: 0.7,
        fontSize: 12,
        borderRadius: 1,
        color: "#1758FF",
        cursor: "pointer",
        background: "#DBE5FF",
      }}
      onClick={onClick && onClick}
    >
      {label || "Editar"}
    </Typography>
  );
};

export default StepOptionButton;
