import { useQuery } from "hooks";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import { SelectChangeEvent } from "@mui/material";
import { IProductVariation } from "interfaces/products.interfaces";
import { getStateProductsHelpers } from "store/products/products.selectors";
import { setDisabledForMissingVariantSelected } from "store/products/products.slice";

import CustomSelect from "components/atoms/custom-select";

interface IProps {
  variation: IProductVariation;
}

const VariationDropdown: FC<IProps> = (props) => {
  const { values, variationName } = props.variation;

  const dispatch = useAppDispatch();

  const { disableForMissingVariantSelected } = useAppSelector(
    getStateProductsHelpers
  );

  const query = useQuery();
  const navigate = useNavigate();
  const asin = query.get("asin");

  const s = values.find((val) => val.asin === asin || val.dpUrl === asin);
  const [value, setValue] = useState(s?.asin || s?.dpUrl || "");

  const options = values.map((val) => ({
    label: val.value,
    disabled: !val.available,
    value: val.asin || val.dpUrl,
  }));

  const onChange = async (e: SelectChangeEvent) => {
    const asin = e.target.value;
    setValue(asin);

    const variation: any = values.find(
      (val) => val.asin === asin || val.dpUrl === asin
    );

    navigate(`/products?asin=${asin}&${variationName}=${variation.value}`, {
      replace: disableForMissingVariantSelected,
    });

    dispatch(setDisabledForMissingVariantSelected(false));
  };

  return (
    <CustomSelect
      width="100%"
      value={value}
      defaultValue=""
      options={options}
      handleChange={onChange}
      sxSelecet={{ color: "black" }}
      sxItem={{ color: "text.primary" }}
      sx={{
        marginTop: 1,
        marginLeft: 0,
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid lightgray",
      }}
    />
  );
};

export default VariationDropdown;
