import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

interface IProps {
  title: string;
  productId: string;
}

const ShareButton: FC<IProps> = (props) => {
  const linkToProduct = `${process.env.REACT_APP_SELF_URL}products?asin=${props.productId}`;
  const shareMessage = `Puntomall: Ver el siguiente producto: ${props.title}. ${linkToProduct}`;

  return (
    <Box
      sx={{
        mt: 3,
        mb: 1,
        mx: 1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div></div>

      <Typography
        component="a"
        target="_blank"
        variant="body2"
        sx={{ color: "#1758FF", cursor: "pointer" }}
        href={`mailto:?subject=${shareMessage}&amp;body=${linkToProduct}`}
      >
        Compartir
      </Typography>
    </Box>
  );
};

export default ShareButton;
