import { FC } from "react";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { Currency } from "interfaces/shopping-cart.interfaces";
import { useAppSelector } from "hooks";
import { getFixesWeight, getPriceInUYU } from "utils/commons";
import { getCheckoutHelpers } from "store/checkout/checkout.selectors";
import { CheckoutSteps } from "store/checkout/checkout.interfaces";

interface IFeesProps {
  total: number;
  weight: number;
  freight: number;
  handling: number;
  realWeight: number;
  feePuntoMall: number;
  productsTotal: number;
  excededLimitImportationAmount: boolean;

  iva?: number;
  extraFees?: number;
  isLoading: boolean;
  deliveryFee: number;
  francheseFees?: number;
  ursecFee?: number;
  ursecFeeForPhones?: number;
}

interface IFeeItemProps {
  label: string;
  value: string;
}

interface IFeeSubItemProps {
  label: string;
  value: string;
}

const Fees: FC<IFeesProps> = (props) => {
  const {
    iva,
    total,
    weight,
    freight,
    handling,
    extraFees,
    isLoading,
    realWeight,
    deliveryFee,
    feePuntoMall,
    productsTotal,
    francheseFees,
    excededLimitImportationAmount,

    ursecFee,
    ursecFeeForPhones,
  } = props;

  const { currenStep } = useAppSelector(getCheckoutHelpers);
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const numberToMoney = (number: number) => {
    const currencyType = currency === Currency.usd ? "U$S" : "$";

    const parsedNumber =
      currency === Currency.usd
        ? number
        : Number(getPriceInUYU(number, cotization).join("."));

    return `${currencyType} ${(Math.round(parsedNumber * 100) / 100).toFixed(
      2
    )}`;
  };

  return (
    <Box>
      <Typography fontSize={14} fontWeight={500}>
        {excededLimitImportationAmount
          ? "Compras de importación (avión)"
          : "Compras con franquicia"}
      </Typography>

      {isLoading ? (
        <Box marginY={2}>
          <Skeleton />
          <Skeleton width="70%" />
          <Skeleton width="70%" sx={{ mb: 1 }} />

          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <Box marginY={2}>
          <FeeItem
            label="Factura en USA"
            value={numberToMoney(productsTotal)}
          />
          <FeeSubItem label="Productos" value={numberToMoney(productsTotal)} />
          <FeeSubItem label="Envío a Miami" value="Gratis" />

          <FeeItem
            label="Tarifa Puntomall"
            value={numberToMoney(feePuntoMall)}
          />
          <FeeItem label="Empaque y Manejo" value={numberToMoney(handling)} />

          <FeeItem
            label={`Envío a Uruguay (${getFixesWeight(realWeight || weight)})`}
            value={numberToMoney(freight)}
          />

          {excededLimitImportationAmount ? (
            <>
              <Divider />

              <FeeItem
                label="Aranceles de importación"
                value={numberToMoney(extraFees as number)}
              />

              {iva && (
                <FeeItem
                  label="IVA descontable"
                  value={numberToMoney(iva as number)}
                />
              )}
            </>
          ) : null}

          {francheseFees ? (
            <FeeItem
              label="Impuestos"
              value={numberToMoney(francheseFees as number)}
            />
          ) : null}

          {ursecFee ? (
            <FeeItem
              label="Trámite de URSEC"
              value={numberToMoney(ursecFee as number)}
            />
          ) : null}

          {ursecFeeForPhones ? (
            <FeeSubItem
              label="Importación de celulares"
              value={numberToMoney(ursecFeeForPhones)}
            />
          ) : null}

          {currenStep === CheckoutSteps.PAYMENT_METHODS ? (
            <FeeItem
              label="Envío a domicilio/ Pickup Express"
              value={numberToMoney(deliveryFee)}
            />
          ) : null}
        </Box>
      )}

      {isLoading ? (
        <Skeleton height={30} />
      ) : (
        <Box display="flex" justifyContent="space-between">
          <BoldText>Total</BoldText>
          <BoldText>{numberToMoney(total)}</BoldText>
        </Box>
      )}
    </Box>
  );
};

export const BoldText: FC = ({ children }) => {
  return (
    <Typography display="block" fontWeight={700} fontSize={18}>
      {children}
    </Typography>
  );
};

export const FeeItem: FC<IFeeItemProps> = ({ value, label }) => {
  return (
    <Box display="flex" justifyContent="space-between" my={1}>
      <Typography fontSize={12} fontWeight={500} color="#7A7979">
        {label}
      </Typography>

      <Typography fontSize={12} fontWeight={500}>
        {value}
      </Typography>
    </Box>
  );
};

export const FeeSubItem: FC<IFeeSubItemProps> = ({ value, label }) => {
  return (
    <Box display="flex" justifyContent="space-between" marginY={1}>
      <Typography
        marginLeft={2}
        fontSize={12}
        fontWeight={500}
        color="#989797"
        display="block"
      >
        {label}
      </Typography>

      <Typography
        marginLeft={2}
        fontSize={12}
        fontWeight={500}
        color="#989797"
        display="block"
      >
        {value}
      </Typography>
    </Box>
  );
};

export default Fees;
