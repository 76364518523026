import { FC } from "react";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";

interface IProps {
  title?: string;
  numberOfItems?: number;
  skeletonTitle?: boolean;
}

const ProductCardSkeleton: FC<IProps> = (props) => {
  const { title, numberOfItems, skeletonTitle } = props;

  const arr = Array(numberOfItems || 4)
    .fill(null)
    .map((_, i) => i);

  return (
    <Box mt={2}>
      {skeletonTitle && (
        <Skeleton
          width="30%"
          height="8%"
          variant="text"
          sx={{ borderRadius: 2, my: 2 }}
        />
      )}

      <Typography variant="h5" color="text.secondary" mx={1}>
        {title}
      </Typography>

      <Grid container spacing={3}>
        {arr.map((_, i) => (
          <Grid item xl={2.4} lg={3} md={4} xs={12} key={i}>
            <Stack spacing={0} width={270} height={435} mx={0.5}>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant="rectangular"
                width={270}
                height={314}
              />

              <Skeleton
                sx={{ borderRadius: 1 }}
                variant="text"
                width={270}
                height={100}
              />

              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="40%" />
              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="60%" />
              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="90%" />
              <Skeleton sx={{ borderRadius: 2 }} variant="text" width="100%" />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductCardSkeleton;
