import { FC } from "react";
import { useTheme } from "@mui/styles";
import { Breakpoint, Container, Theme, useMediaQuery } from "@mui/material";

interface IProps {
  mg?: number;
  pd?: number;
  maxWidth?: false | Breakpoint | undefined;
}

/**
 * Component for showing custom layout that takes two OPTIONAL props:
 * @param {Number} margin
 * @param {Number} p
 *
 * @example
 * return (
 *   <CustomLayout mg={6}>{children}</CustomLayout>
 * )
 */

const CustomLayout: FC<IProps> = (props) => {
  const { maxWidth, children, mg, pd } = props;

  const theme: Theme = useTheme();
  const upToMiddleScreenSize = useMediaQuery(theme.breakpoints.up("md"));

  if (!upToMiddleScreenSize)
    return <Container sx={{ m: 0, py: 2, px: 1 }}>{children}</Container>;

  return (
    <Container
      maxWidth={maxWidth || "xl"}
      sx={{ m: mg || "auto", p: pd || "auto" }}
    >
      {children}
    </Container>
  );
};

export default CustomLayout;
