import { FC, MouseEvent } from "react";
import { IProductVariationValue } from "interfaces/products.interfaces";

import { Box } from "@mui/system";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  disabled: { opacity: 0.3, cursor: "not-allowed" },
  imgCard: { width: 42, height: 42, objectFit: "contain" },
  selectedCard: { border: `1px solid ${theme.palette.secondary.main}` },
}));

const variationCardCss = {
  py: 0.5,
  px: 0.5,
  width: 52,
  height: 52,
  margin: 0.5,
  borderRadius: 2,
  cursor: "pointer",
  border: "1px solid lightgray",
};

interface ISingleVariationCard {
  variation: IProductVariationValue;
  onClick: (e: MouseEvent<HTMLElement>) => Promise<void>;
}

/**
 * Component for showing a simple card image that takes two props:
 *
 * @param {IProductVariationValue} variation
 * @param {Function} onClick event function that is executed when clicking on the component
 */

const ImageVariationCard: FC<ISingleVariationCard> = (props) => {
  const s = useStyles();
  const selected = props.variation.selected;

  return (
    <Box
      onClick={!selected ? props.onClick : undefined}
      sx={{ ...variationCardCss }}
      className={`${selected ? s.selectedCard : undefined} ${
        !props.variation.available ? s.disabled : undefined
      }`}
    >
      <img
        className={s.imgCard}
        alt={props.variation.value}
        src={props.variation.imageUrl as string}
      />
    </Box>
  );
};

export default ImageVariationCard;
