import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { parseProductInformation } from "utils/commons";
import { getTrendingProducts } from "store/statistics.slice";
import { IStatistic } from "interfaces/statistics.interfaces";

import CustomProductList from "components/molecules/custom-product-list";

const MostViewedProducts: FC = () => {
  const dispatch = useAppDispatch();
  const { mostViewed, isLoading } = useAppSelector((state) => state.statistics);

  useEffect(() => {
    if (!mostViewed.length) dispatch(getTrendingProducts());
  }, [dispatch, mostViewed.length]);

  const parseStatisticsToProductList = (statistic: IStatistic[]) =>
    statistic
      .map((a) =>
        a.product && a.product.asin
          ? parseProductInformation(a?.product, a.productId)
          : (null as any)
      )
      .filter((a) => a !== null)
      .slice(0, 5);

  if (isLoading) return null;

  return (
    <>
      {mostViewed && mostViewed.length ? (
        <CustomProductList
          title="Productos más visitados"
          products={parseStatisticsToProductList(mostViewed)}
          urlTo={{ title: "Ver todas →", url: "trending-products" }}
        />
      ) : null}
    </>
  );
};

export default MostViewedProducts;
