import { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks";

import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";

import { getLSCart } from "store/lscart.slice";
import { fetchCartByUserId } from "store/cart/cart.actions";

import SkypostalPanel from "pages/skypostal-panel-demo";

const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (auth.isLoggedIn) dispatch(fetchCartByUserId());
    else dispatch(getLSCart());
  }, [dispatch, auth.isLoggedIn]);

  return (
    <Routes>
      <Route path="*" element={<MainRoutes />} />
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/skypostal-panel" element={<SkypostalPanel />} />
    </Routes>
  );
};

export default AppRoutes;
