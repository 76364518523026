import { FC } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Container, Divider, Grid, Theme, Typography } from "@mui/material";

import TextWithIconFooter from "./TextWithIcon";
import largelogo from "assets/logos/extended_logo.png";

import {
  PartnersAssociated,
  CopyrightAndSocialMedia,
  PaymentMethodsAssociated,
} from "./ComplementInformation";

const bgMain = "#ffffff";

const CompleteFooter: FC = () => {
  const s = useStyles();

  const goToEmail = () =>
    window.open("mailto:info@puntomall.com.uy)", "_blank");

  const goToPhone = () => window.open("https://wa.me/59897814037", "_blank");

  const gotToMapDirections = () =>
    window.open("https://goo.gl/maps/QoToGBCLcSWyz7BR9", "_blank");

  return (
    <Box className={s.container}>
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 1 }}>
          <Grid item xs={12} md={4}>
            <img src={largelogo} alt="large-logo-puntomall" className={s.img} />
            <Typography
              variant="body2"
              color="text.disabled"
              className={s.mainText}
            >
              Comprando en PuntoMall estarás comprando excento de impuestos y
              directo en Estados Unidos bajo el Decreto 356/014.
              <br />
              <br />
              PuntoMall cuenta con los certificados verificados para garantizar
              la seguridad de la compra.
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}></Grid>

          <Grid item xs={12} md={3}>
            <Typography sx={{ mb: 3 }} fontWeight="bold">
              PuntoMall® Miami
            </Typography>

            <TextWithIconFooter
              icon="location"
              onClick={gotToMapDirections}
              text="7805 NW 15th St, Doral, FL 33126, Estados Unidos"
            />

            <TextWithIconFooter
              icon="phone"
              text="+59 897 814 037"
              onClick={goToPhone}
            />

            <TextWithIconFooter
              icon="email"
              text="info@puntomall.com.uy"
              onClick={goToEmail}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <FooterLink
              label="Acerca de nosotros"
              url="https://puntomio.uy/servicios"
            />

            <FooterLink
              label="Como comprar"
              url={`${process.env.REACT_APP_SELF_URL}information/how-works`}
            />

            <FooterLink label="Contacto" url="https://puntomio.uy/contacto" />

            <FooterLink
              label="Calculadora"
              url="https://puntomio.uy/calculadora"
            />

            <FooterLink
              label="Preguntas frecuentes"
              url={`${process.env.REACT_APP_SELF_URL}information/fees`}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, mx: 3 }} />

        <Grid container spacing={{ xs: 1 }}>
          <Grid item xs={12} md={6}>
            <CopyrightAndSocialMedia />
          </Grid>

          <Grid item xs={12} md={2}>
            <PartnersAssociated />
          </Grid>

          <Grid item xs={12} md={4}>
            <PaymentMethodsAssociated />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

interface IFooterLinkProps {
  label: string;
  url: string;
}

const FooterLink: FC<IFooterLinkProps> = (props) => {
  const { label, url } = props;

  const styles = useStyles();

  return (
    <Box>
      <Typography
        href={url}
        component="a"
        variant="body2"
        color="text.disabled"
        className={styles.link}
      >
        {label}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: "50%",
    maxWidth: 210,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: { width: "100%" },
  },

  container: { padding: theme.spacing(4), background: bgMain },

  mainText: {
    width: "70%",
    margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: `${theme.spacing(1)}`,
    },
  },

  link: {
    display: "inline-block",
    margin: `${theme.spacing(1)} 0`,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
}));

export default CompleteFooter;
