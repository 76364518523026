import { FC } from "react";
import { genRandomId } from "utils/commons";
import { useNavigate } from "react-router-dom";
import { ProductList } from "interfaces/products.interfaces";

import { Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Grid, Paper, Typography, Box } from "@mui/material";

import MobileProductCard from "../mobile-product-card";

interface IProps {
  urlTo: ILink;
  title: string;
  emptyMsj?: string;
  products: ProductList[];
}

interface ILink {
  url: string;
  title: string;
}

const CustomMobileProductList: FC<IProps> = (props) => {
  const { products, title, urlTo, emptyMsj } = props;

  const s = useStyles();
  const navigate = useNavigate();
  const onClick = () => navigate(`/${urlTo.url}`);

  return (
    <Box my={2}>
      <Typography variant="h6" color="text.secondary" mb={1}>
        {title}
      </Typography>

      <Paper>
        {products.length ? (
          <Grid container>
            {products.map((product) => (
              <Grid item xs={12} key={genRandomId()}>
                <MobileProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" align="center" p={2}>
            {emptyMsj}
          </Typography>
        )}

        <Typography
          align="center"
          variant="body2"
          onClick={onClick}
          className={s.mobileButton}
        >
          {urlTo.title}
        </Typography>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mobileButton: {
    fontSize: 18,
    cursor: "pointer",
    padding: theme.spacing(2),
    borderTop: "1px solid #F1F1F1",
    color: theme.palette.secondary.main,
  },
}));

export default CustomMobileProductList;
