import { ShippingAddress } from "store/checkout/checkout.interfaces";

import {
  Currency,
  IShoppingCart,
  FrancheseType,
  DeliveryMethodsFees,
} from "./shopping-cart.interfaces";

export enum OrderStatusEnum {
  NEW = "NEW",

  PAID = "PAID",
  PROCESSING = "PROCESSING",

  ZINC_PROCCESSING = "ZINC_PROCCESSING",
  ZINC_PAID = "ZINC_PAID",

  SHIPPING = "SHIPPING",
  PACKAGE_RECEIVED = "PACKAGE_RECEIVED",
  READY_TO_DELIVER = "READY_TO_DELIVER",
  PACKAGE_SHIPPED = "PACKAGE_SHIPPED",
  IN_DISTRIBUTION = "IN_DISTRIBUTION",

  PICK_UP = "PICK_UP",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED",

  CANCELED = "CANCELED",
  FAILED = "FAILED",

  REFUND = "REFUND",
}

export enum OrderStatusTranslation {
  STOPPED = "Retenido",
  PICK_UP = "En pick up",
  COMPLETED = "Entregado",
  IN_URUGUAY = "En Uruguay",
  EXPECTING = "Pre-alertado",
  AT_WAREHOUSE = "Almacenado",
  PACKAGE_SHIPPED = "En camino",
  IN_DISTRIBUTION = "En reparto",
  ABANDONED = "Paquete abandonado",
  READY_TO_DELIVER = "Pronto para salir",
  NOT_DELIVERED = "No pudo ser entregado",
  AUTOMATICALLY_DELIVERED = "Entregado automaticamente",

  PAID = "Paquete pagado",
  PROCESSING = "Paquete en proceso",
  ZINC_PAID = "Paquete pagado en Zinc",
  ZINC_PROCCESSING = "Paquete procesando en zinc",
  PACKAGE_RECEIVED = "Paquete recibido en depósito",
  SHIPPING = "Paquete en viaje a Uruguay",

  FAILED = "Paquete fallido",
  CANCELED = "Paquete cancelado",
  DELIVERED = "Paquete entregado",
  REFUND = "Paquete listo para refund",
}

export enum TrackingStatus {
  PACKAGE_SENT_FROM_AMAZON = "PACKAGE_SENT_FROM_AMAZON",
  PACKAGE_RECEIVED = "PACKAGE_RECEIVED",
}

export interface TrackingProduct {
  asin: string;
  quantity: number;
  price: number;
}

export interface AmazonTracking {
  trackingNumber: string;
  skypostalTracking?: number;
  status: TrackingStatus;
  products: TrackingProduct[];
}

export interface ResponseHistoryEntry {
  code: string;
  transactionId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PaymentInfo {
  paymentOutlet: string;
  transactionId?: string;
  authorizationCode?: string;
  value?: string;
  taxAmount?: string;
  currency?: string;
  returnValue?: string;
  responseCode?: string;
  responseHistory: ResponseHistoryEntry[];
  createdAt: Date;
}

export interface IOrder {
  id: string;
  customerEmail: string;
  currency: Currency;
  status: OrderStatusEnum;
  notes?: string;
  amount: number;
  cart: IShoppingCart;
  address: ShippingAddress;
  paymentInfo: PaymentInfo;
  events: IOrderEvent[];
  user: string;
  zincOrderId?: string | null;
  dateAdded: Date;
  amazonTrackigs: AmazonTracking[];
  consolidationProcessed?: Date;
  issue?: string;
  skypostalTracking?: number;
  zincDeliveryDate?: string;
}

export interface IOrderEvent {
  code?: string;
  date: Date;
  description: string;
  visible: boolean;
  deleted?: boolean;
}

export interface CreateOrderDto {
  notes?: string;
  cartId: string;
  userId?: string;
  amount: number;
  currency?: Currency;
  customerEmail: string;
  address: ShippingAddress;
  useFranchese: FrancheseType;
  deliveryMethod: DeliveryMethodsFees;
}
