import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/system";
import { SelectChangeEvent, Typography } from "@mui/material";

import CustomSelect from "components/atoms/custom-select";
import CustomButton from "components/atoms/custom-button";

import {
  EditCartBody,
  AddItemToCartBody,
} from "interfaces/shopping-cart.interfaces";

import { genRandomId } from "utils/commons";
import { getCartState } from "store/cart/cart.selectors";
import { mergeShoppingCartWithLS } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { addItemToCart, updateCartItem } from "store/cart/cart.actions";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";
import { addNewItemToLsCart, updateLsCartItem } from "store/lscart.slice";
import { getStateSingleProduct } from "store/products/products.selectors";

interface IProps {
  title: string;
  asin: string;
  price: number;
  show: boolean;
  mainImage: string;
  showVariantLimitation: boolean;
}

const ProductBuyOptions: FC<IProps> = (props) => {
  const { asin, mainImage, price, title, show, showVariantLimitation } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [quantity, setQuantity] = useState<string>("1");

  const single = useAppSelector(getStateSingleProduct);
  const { shoppingCart } = useAppSelector(getCartState);
  const lsShoppingCart = useAppSelector((state) => state.lscart);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  const handleChange = (e: SelectChangeEvent) => setQuantity(e.target.value);

  /**
   * anonimous' shopping cart (localStorage)
   */
  const addItemToLSCart = () => {
    const found = lsShoppingCart.items.filter(
      (item) => item.productId === asin
    );

    if (found.length) {
      dispatch(
        updateLsCartItem({
          id: found[0].id,
          quantity: found[0].quantity + Number(quantity),
        })
      );
    } else {
      dispatch(
        addNewItemToLsCart({
          price: price,
          product: single,
          productId: asin,
          image: mainImage,
          id: genRandomId(),
          quantity: Number(quantity),
          title: single.productTitle || single.productDescription,
        })
      );
    }
  };

  /**
   * user's shopping cart (persist with DB)
   */
  const addItemToDBCart = () => {
    const found = shoppingCart.items.filter((item) => item.productId === asin);

    if (found.length) {
      updateExistingItem({
        cartItemId: found[0].id,
        quantity: Number(found[0].quantity) + Number(quantity),
      });
    } else {
      addNewItemToCart({
        title,
        price: price,
        productId: asin,
        image: mainImage,
        quantity: Number(quantity),
      });
    }
  };

  const updateExistingItem = (body: EditCartBody) =>
    dispatch(updateCartItem(body));

  const addNewItemToCart = (body: AddItemToCartBody) =>
    dispatch(addItemToCart(body));

  const addToCart = () => {
    dispatch(
      createSnackbarMessage({
        snackbarType: SnackbarType.success,
        message: "Producto agregado correctamente al carrito",
      })
    );

    if (!isLoggedIn) {
      addItemToLSCart();
      return;
    }

    if (!shoppingCart.id) {
      dispatch(mergeShoppingCartWithLS()).then(() => {
        addItemToDBCart();
      });

      return;
    }

    addItemToDBCart();
  };

  if (showVariantLimitation)
    return (
      <Typography py={2} variant="body2">
        Seleccione las variantes correspondientes antes de continuar
      </Typography>
    );

  return (
    <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
      <CustomSelect
        width={"100%"}
        value={quantity}
        options={selectOption}
        defaultValue={quantity}
        handleChange={handleChange}
        sx={{
          margin: 0,
          padding: 0,
          marginTop: 1,
          marginBottom: 1,
          borderRadius: "5px",
          border: "1px solid lightgrey",
        }}
        sxSelecet={{ height: "36px", color: "#221F1F", textAlign: "center" }}
      />

      <CustomButton
        disabled={!show}
        text="Comprar"
        variant="primary"
        onClick={() => {
          addToCart();
          navigate("/shopping-cart");
        }}
      />

      <CustomButton
        disabled={!show}
        variant="secondary"
        onClick={addToCart}
        text="Agregar al carrito"
      />
    </Box>
  );
};

export default ProductBuyOptions;

const selectOption = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];
