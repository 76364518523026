import { FC, useEffect } from "react";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";
import { FormGroup, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";

import theme from "styles/muiTheme";
import Input from "components/atoms/input";
import CustomButton from "components/atoms/custom-button";

import { inputValidations } from "utils/forms/validations";
import { setForgotPassword } from "store/credentials.slice";
import { cleanUserSliceErrors, login } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

const LoginCard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(cleanUserSliceErrors());
  }, [dispatch]);

  const onSubmit = (data: FieldValues) => {
    dispatch(login({ email: data.email, password: data.password })).then(
      (data: any) => {
        if (data.error) {
          if (data.payload.message === "La cuenta esta sin verificar") {
            dispatch(
              createSnackbarMessage({
                snackbarType: SnackbarType.error,
                message:
                  "Su cuenta aún no esta verificada. Revise su casilla de correo electrónico para finalizar la verificación",
                origin: { horizontal: "center", vertical: "top" },
              })
            );
          } else
            dispatch(
              createSnackbarMessage({
                snackbarType: SnackbarType.error,
                message:
                  "Credenciales inválidas, verifique sus datos e intente nuevamente!",
                origin: { horizontal: "center", vertical: "top" },
              })
            );
        } else navigate("/");
      }
    );
  };

  const goToforgotPassword = () => dispatch(setForgotPassword(true));

  return (
    <FormGroup>
      <Typography
        fontSize={24}
        textAlign="center"
        fontWeight={500}
        marginBottom={3}
      >
        ¡Iniciar sesión!
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          control={control}
          error={errors.email}
          label="Tu dirección de correo electrónico"
          rules={{
            required: "Campo obligatorio",
            pattern: inputValidations.email(),
          }}
        />

        <Input
          name="password"
          type="password"
          control={control}
          error={errors.password}
          label="Contraseña"
          rules={{
            required: "Campo obligatorio",
            pattern: inputValidations.password(),
          }}
        />

        <CustomButton
          variant="primary"
          text="Iniciar sesión"
          sx={{ marginTop: 2, marginBottom: 1, height: "48px", width: "100%" }}
          type="submit"
        />
      </form>

      <Typography
        fontSize={12}
        fontWeight={500}
        textAlign="center"
        onClick={goToforgotPassword}
        color={theme.palette.secondary.main}
        sx={{ textDecoration: "none", cursor: "pointer" }}
      >
        ¿Olvidaste tu contraseña?
      </Typography>
    </FormGroup>
  );
};

export default LoginCard;
