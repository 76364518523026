import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";
import { Typography } from "@mui/material";

import mailIcon from "assets/icons/mail.svg";
import phoneIcon from "assets/icons/phone.svg";
import mapIcon from "assets/icons/mapMarker.svg";

interface TextWithIconProps {
  text: string;
  onClick?: () => void;
  icon: "location" | "phone" | "email";
}

const TextWithIconFooter: FC<TextWithIconProps> = (props) => {
  const { icon, text, onClick } = props;

  const s = useStyles();

  const Icons = {
    phone: <Box component="img" src={phoneIcon} alt="email" />,
    email: <Box component="img" src={mailIcon} alt="email" />,
    location: <Box alt="location" component="img" src={mapIcon} />,
  };

  return (
    <Box className={s.container} onClick={onClick}>
      {Icons[icon]}

      <Typography variant="body2" color="text.disabled" className={s.text}>
        {text}
      </Typography>
    </Box>
  );
};

export default TextWithIconFooter;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    margin: `${theme.spacing(2)} ${theme.spacing(0)}`,
    [theme.breakpoints.down("md")]: { margin: `${theme.spacing(1)}` },
  },

  text: {
    width: "70%",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: theme.spacing(1),
    },
  },
}));
