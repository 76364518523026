import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { FC, useEffect, useRef, useState } from "react";

import { Box } from "@mui/system";
import { Divider, Paper, Typography } from "@mui/material";
import { Verified as VerifiedIcon } from "@mui/icons-material";

import { getFees } from "store/fees/fees.actions";
import { getTotalCartPrice } from "store/cart/cart.selectors";
import { checkCartavailability } from "store/cart/cart.actions";
import { FeesDto, FrancheseType } from "interfaces/shopping-cart.interfaces";

import Fees from "components/molecules/fees";
import CustomButton from "components/atoms/custom-button";

import {
  deleteCartExceptions,
  setShowAvailabilityErrorsInCart,
} from "store/cart/cart.slice";

import {
  feesDataSelector,
  feesLoadersSelector,
} from "store/fees/fees.selectors";

interface IProps {
  showNavigation: boolean;
}

const ShoppingCartFees: FC<IProps> = (props) => {
  const { showNavigation } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLElement>();

  const [, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const fees = useAppSelector(feesDataSelector);
  const { getFeesLoader } = useAppSelector(feesLoadersSelector);

  const totalCartPrice = useAppSelector(getTotalCartPrice);
  const { total } = useAppSelector((state) => state.lscart);
  const { items } = useAppSelector((state) => state.lscart);

  const { isLoggedIn, user } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    const totalCartAmmount = isLoggedIn ? totalCartPrice : total;

    let params: FeesDto = {
      userId: isLoggedIn ? user?.id : undefined,

      useFranchise:
        totalCartAmmount < 200
          ? FrancheseType.USE
          : FrancheseType.NOT_APPLICABLE,

      productsAsin: isLoggedIn
        ? undefined
        : items.map((item) => ({
            asin: item.productId,
            quantity: item.quantity,
          })),
    };

    dispatch(getFees(params));
  }, [dispatch, isLoggedIn, total, totalCartPrice, user, items]);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getWidth = () => (ref && ref.current ? ref.current.offsetWidth / 2 : 0);

  const handleSubmit = () => {
    dispatch(checkCartavailability()).then((data: any) => {
      if (isLoggedIn) {
        if (data.payload?.cart?.total) navigate("/checkout");
        else {
          dispatch(setShowAvailabilityErrorsInCart());

          setTimeout(() => {
            dispatch(deleteCartExceptions());
          }, 8000);
        }
      } else navigate("/checkout");
    });
  };

  return (
    <Paper
      sx={{ background: "#ECF1FF", pb: 4, width: "100%", margin: "0 auto" }}
    >
      <Box paddingX={4} paddingY={1} sx={{ background: "#5182FF" }} ref={ref}>
        <Typography
          color="white"
          fontSize={24}
          sx={{ pt: 1 }}
          fontWeight={700}
          textAlign="center"
        >
          Resumen del pedido
        </Typography>
      </Box>

      <Box
        sx={{
          height: 0,
          borderTop: "12px solid #5182FF",
          borderLeft: `${getWidth()}px solid transparent`,
          borderRight: `${getWidth()}px solid transparent`,
        }}
      />

      <Box px={3} mt={3}>
        <Fees {...fees} isLoading={getFeesLoader} />

        <Divider sx={{ my: 2 }} />

        {showNavigation && (
          <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
            <CustomButton
              variant="primary"
              onClick={handleSubmit}
              text="Finalizar compra"
            />

            <CustomButton
              variant="secondary"
              onClick={() => navigate("/")}
              text="Continúa comprando"
            />
          </Box>
        )}

        <Box display="flex" justifyContent="center" alignItems="center">
          <VerifiedIcon color="success" fontSize="small" />
          <Typography fontSize={12} textAlign="center" ml={1}>
            Este producto tiene Garantía de Entrega
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ShoppingCartFees;
