import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";
import { useAppDispatch, useAppSelector } from "hooks";

import { Container, Typography } from "@mui/material";
import { getUserFavorites } from "store/favorites/favorites.actions";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

import {
  getFavoritesExceptions,
  getStateFavorites,
} from "store/favorites/favorites.selectors";

import FavoritesList from "./components/FavoritesList";
import BackdropLoader from "components/atoms/backdrop-loader";
import EmptyFavoriteList from "./components/EmptyFavoriteList";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { cleanFavoritesExceptions } from "store/favorites/favorites.slice";

const FavoritesView: FC = () => {
  const dispatch = useAppDispatch();

  const exceptions = useAppSelector(getFavoritesExceptions);
  const { list, isLoading, isFetched } = useAppSelector(getStateFavorites);

  useEffect(() => {
    if (!isFetched) dispatch(getUserFavorites());
  }, [dispatch, isFetched]);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (exceptions.length > 0) {
      exceptions.forEach((exception) => {
        dispatch(
          createSnackbarMessage({
            message: exception.message,
            snackbarType: SnackbarType.info,
          })
        );
      });

      dispatch(cleanFavoritesExceptions());
    }
  }, [dispatch, exceptions]);

  if (isLoading) return <BackdropLoader />;

  return (
    <Container sx={{ my: { md: 5, xs: 2 } }} maxWidth="lg">
      <Typography variant="h6" color="text.secondary" mb={1}>
        Favoritos
      </Typography>

      {!list.length ? (
        <EmptyFavoriteList />
      ) : (
        <FavoritesList favorites={list} />
      )}
    </Container>
  );
};

export default FavoritesView;
