import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { RequestError } from "interfaces/errors.interfaces";
import { PaymentServices } from "services/payment.services";
import { IPaymentConfig } from "interfaces/payment.interfaces";

interface IPaymentStore {
  config: IPaymentConfig | null;
  isLoading: boolean;
  error: RequestError | null;
}

const initialState: IPaymentStore = {
  config: null,
  error: null,
  isLoading: false,
};

/**
 * Config a payment
 */
export const configPayment = createAsyncThunk(
  "payment/config",
  async (
    { orderId, paymentMethod }: { orderId: string; paymentMethod: string },
    thunkApi
  ) => {
    try {
      const config = await PaymentServices.configPayment(
        orderId,
        paymentMethod
      ).then(async (config) => {
        await PaymentServices.confirmPayment(orderId);

        return config;
      });
      return config;
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(configPayment.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.config = action.payload as IPaymentConfig;
      })
      .addCase(configPayment.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(configPayment.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export default paymentSlice.reducer;
