import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestError } from "interfaces/errors.interfaces";

import {
  EditCartBody,
  AddItemToCartBody,
} from "interfaces/shopping-cart.interfaces";

import {
  setFetchHistoryStatus,
  mergeShoppingCartWithLS,
} from "store/user.slice";

import { setFetchFavoriteStatus } from "store/favorites/favorites.slice";
import { shoppingCartServices as cartServices } from "services/shopping-cart.services";

/**
 * A function that fetch shopping cart based on userId pased for
 * parameter and then set the list into cart-reducer
 */
export const fetchCartByUserId = createAsyncThunk(
  "cart/fetchCartByUserId",
  async (_, thunkApi) => {
    try {
      const response = await cartServices.getUserCart();
      return response;
    } catch (err: any) {
      console.log(err.response.status);

      if (err.response.status === 404) {
        await thunkApi.dispatch(mergeShoppingCartWithLS());
        return thunkApi.rejectWithValue(err as RequestError);
      } else return thunkApi.rejectWithValue(err as RequestError);
    }
  }
);

/**
 * A function that update cartItem quantity and set new shopping cart with new values
 * @param {String} cartId
 * @param {EditCartBody} body
 */
export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async (body: EditCartBody, thunkApi) => {
    try {
      await cartServices.updateICartItem(body);
      return thunkApi.dispatch(fetchCartByUserId());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that create a new cartItem into existing cart
 * @param {String} cartId
 * @param {AddItemToCartBody} body
 */
export const addItemToCart = createAsyncThunk(
  "cart/addItemToCart",
  async (body: AddItemToCartBody, thunkApi) => {
    try {
      await cartServices.addItemToCart(body);
      return thunkApi.dispatch(fetchCartByUserId());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that delete a cartItem from existing cart
 * @param {String} itemId
 */
export const removeItemToCart = createAsyncThunk(
  "cart/removeItemToCart",
  async (itemId: string, thunkApi) => {
    try {
      await cartServices.removeItemFromCart(itemId);
      return thunkApi.dispatch(fetchCartByUserId());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 *
 */
export const checkCartavailability = createAsyncThunk(
  "cart/checkCartavailability",
  async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const response = await cartServices.checkProductCartavailability();

    if (response.exceptions.length) {
      dispatch(setFetchHistoryStatus(false));
      dispatch(setFetchFavoriteStatus(false));
    }

    return response;
  }
);
