import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestError } from "interfaces/errors.interfaces";

import { SearchRequest } from "interfaces/searchResults.interfaces";
import { productsServices as service } from "services/products.services";

/**
 * A function that fetch an array of products based on keywoard pased for
 * parameter and then set the list into product-reducer
 * @param {String} keywoard
 */
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ searchValue, page }: { searchValue: string; page: number }) => {
    const query = searchValue.toLowerCase().trim().replaceAll(" ", "%20");

    const searchRequest: SearchRequest = {
      keyword: query,
      page: page || 1,
      domainCode: "com",
    };

    const searchQuery: string = Object.keys(searchRequest)
      .map(
        (key) =>
          key.toString() +
          "=" +
          searchRequest[key as keyof typeof searchRequest].toString()
      )
      .join("&");

    return await service.getProducts(searchQuery, searchRequest.page);
  }
);

export const fetchSimilarProducts = createAsyncThunk(
  "products/fetchSimilarProducts",
  async (query: string, thunkApi) => {
    try {
      const keyword = query.toLowerCase().trim().replaceAll(" ", "%20");
      const searchRequest = { page: 1, domainCode: "com", keyword };

      const searchQuery: string = Object.keys(searchRequest)
        .map(
          (key) =>
            key.toString() +
            "=" +
            searchRequest[key as keyof typeof searchRequest].toString()
        )
        .join("&");

      const { products } = await service.getProducts(
        searchQuery,
        searchRequest.page
      );

      return { list: products, query: searchQuery };
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that fetch a single product based on id pased for
 * parameter and then set the product into product-reducer
 * @param {String} id
 */
export const fetchSingleProduct = createAsyncThunk(
  "products/fetchSingleProduct",
  async (id: string) => {
    return await service.getProductByAsin(id);
  }
);
