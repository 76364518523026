import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";

import { parseAxiosErrorObject } from "utils/errors";
import { ICategory } from "interfaces/categories.interfaces";

export const CategoryServices = {
  /**
   *  A function that makes axios GET request to puntoMall API (/categories) and
   * get a list of categories with subcategories
   * @returns array with all categories
   */
  getAllCategories: async (): Promise<ICategory[]> => {
    try {
      const categories: AxiosResponse<ICategory[]> = await puntoMallAPI.get(
        "/categories"
      );

      return categories.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   *  A function that makes axios GET request to puntoMall API (/categories/:id) and
   * get a single categorywith his subcategories
   * @param {string} id
   * @returns single category
   */
  getSingleCategoryById: async (id: string): Promise<ICategory> => {
    try {
      const categories: AxiosResponse<ICategory> = await puntoMallAPI.get(
        `/categories/${id}`
      );

      return categories.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
