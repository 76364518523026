import { FC, MouseEvent } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Theme } from "@mui/material";

import ButtonSpinner from "../button-spinner";

interface IProps {
  sx?: any;
  text: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  type?: "button" | "submit" | "reset" | undefined;
  variant: "primary" | "secondary" | "grey" | "grayWithoutBorder";
}

/**
 * Custom button based on MUI with some parameters like variant, click function and text
 * @param {string} text
 * @param {string} variant
 * @param {Function} onClick
 * @param {boolean} disabled (optional)
 *
 * @example
 * return (
 *   <CustomButton text="Buy" variant="primary" onClick={() => "hello world"} />
 * )
 */

const CustomButton: FC<IProps> = (props) => {
  const s = useStyles();
  const { variant, text, onClick, disabled, sx, loading } = props;

  return (
    <Button
      disableElevation
      onClick={onClick}
      type={props.type}
      variant="contained"
      sx={{ my: 0.5, ...sx }}
      disabled={disabled || false}
      className={`${s.root} ${
        s[variant as "primary" | "secondary" | "grey" | "grayWithoutBorder"]
      }`}
    >
      {loading ? <ButtonSpinner /> : text}
    </Button>
  );
};

const secondaryBgColor = "#FCEEE8";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#ffffffff",
    textTransform: "none",
    fontSize: "16px",
  },

  primary: {
    background: theme.palette.secondary.main,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  secondary: {
    background: secondaryBgColor,
    color: theme.palette.secondary.main,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: secondaryBgColor,
    },
  },

  grey: {
    color: theme.palette.text.primary,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    background: "linear-gradient(180deg, #FAFAFA 0%, #F3F3F3 100%)",
    "&:hover": {
      opacity: 0.6,
      backgroundColor: "linear-gradient(180deg, #FAFAFA 0%, #F3F3F3 100%);",
    },
  },

  grayWithoutBorder: {
    color: "#777577",
    background: "transparent",
    border: "1px solid transparent",
    "&:hover": { opacity: 0.6, backgroundColor: "transparent" },
  },
}));

export default CustomButton;
