import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";

import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Divider, Grid, Paper, Theme, Typography } from "@mui/material";

import mainImage from "assets/images/how-works-img.svg";
import compraIcon from "assets/icons/static-compra.svg";
import deliveryIcon from "assets/icons/static-delivery.svg";
import registerIcon from "assets/icons/static-register.svg";
import orangeBubbleIcon from "assets/icons/orange-bubble.svg";

import CustomLayout from "components/atoms/simple-layout";

interface ITextComponent {
  label: string;
  simple?: boolean;
}

const TextListItemComponent: FC<ITextComponent> = (props) => {
  const { label, simple } = props;
  const s = styles();

  return (
    <Typography
      className={s.textItem}
      component={simple ? "p" : "li"}
      sx={{ my: simple ? 1 : "0.7" }}
    >
      {label}
    </Typography>
  );
};

const HowWorksPage: FC = () => {
  const s = styles();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <CustomLayout maxWidth="xl">
      <Box component="img" src={mainImage} alt="how-works" className={s.img} />

      <Typography className={s.title} variant="h4">
        ¿Cómo funciona compra
        <br /> en USA con{" "}
        <Typography component="span" variant="h4" className={s.span}>
          PuntoMall
        </Typography>
        ?
      </Typography>

      <Grid container spacing={3} className={s.cardContainer}>
        <Grid item xs={12} md={4} className={s.gridItem}>
          <Paper className={s.card}>
            <Box component="img" src={orangeBubbleIcon} className={s.bubble} />
            <Box component="img" src={registerIcon} sx={{ mb: 2 }} />

            <Typography className={s.itemTitle}>Registrate</Typography>
            <Divider className={s.divider} />

            <TextListItemComponent simple label="Carga tus datos personales" />

            <TextListItemComponent label="Nombre" />
            <TextListItemComponent label="Documento de identidad" />
            <TextListItemComponent label="Mail" />

            <TextListItemComponent label="Dirección" />
            <TextListItemComponent label="Método de pago" />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} className={s.gridItem}>
          <Paper className={s.card}>
            <Box component="img" src={orangeBubbleIcon} className={s.bubble} />
            <Box component="img" src={compraIcon} sx={{ mb: 2 }} />

            <Typography className={s.itemTitle}>Compra</Typography>
            <Divider className={s.divider} />

            <TextListItemComponent label="Ingresa al buscador" />
            <TextListItemComponent label="Selecciona entre miles de productos" />
            <TextListItemComponent label="Cargalos al carrito" />
            <TextListItemComponent label="Elige método de entrega" />
            <TextListItemComponent label="Paga por tus compras" />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} className={s.gridItem}>
          <Paper className={s.card}>
            <Box component="img" src={orangeBubbleIcon} className={s.bubble} />
            <Box component="img" src={deliveryIcon} sx={{ mb: 2 }} />

            <Typography className={s.itemTitle}>Recíbelo</Typography>
            <Divider className={s.divider} />

            <TextListItemComponent
              simple
              label="Elegí entre las distintas opciones que te brindamos"
            />

            <TextListItemComponent label="Retíralo en un pick up center" />
            <TextListItemComponent label="Recíbelo en tu casa" />
          </Paper>
        </Grid>
      </Grid>
    </CustomLayout>
  );
};

export default HowWorksPage;

const styles = makeStyles((theme: Theme) => ({
  img: {
    left: 0,
    zIndex: -1,
    height: 300,
    width: "100%",
    objectFit: "cover",
    position: "absolute",
  },

  span: {
    fontWeight: "bold",
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: theme.palette.secondary.main,
  },

  card: {
    height: 380,
    width: "100%",
    display: "flex",
    margin: "0 auto",
    borderRadius: 12,
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(3),
  },

  textItem: { alignSelf: "flex-start", fontSize: 16 },
  divider: { width: "100%", margin: `${theme.spacing(1)} 0` },
  itemTitle: { color: "#061B5E", fontWeight: "bold", fontSize: 18 },
  bubble: { position: "absolute", left: 0, right: 64, margin: "auto", top: 12 },

  cardContainer: {
    top: 180,
    width: "100%",
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down("md")]: { top: 70 },
  },

  gridItem: {
    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
      margin: `${theme.spacing(1)} 0`,
    },
  },

  title: {
    color: "#061B5E",
    paddingBottom: 0,
    padding: theme.spacing(12),
    [theme.breakpoints.down("md")]: { padding: theme.spacing(4) },
  },
}));
