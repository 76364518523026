import { MutableRefObject, useEffect, useRef, useState } from "react";

const useDetectClickOut = (initState: boolean) => {
  const triggerRef: MutableRefObject<any> = useRef(null); // optional
  const nodeRef: MutableRefObject<any> = useRef(null); // required

  const [show, setShow] = useState(initState);

  const handleClickOutside = (event: any) => {
    //if click is on trigger element, toggle modal
    if (triggerRef.current && triggerRef.current.contains(event.target))
      return setShow(!show);

    //if modal is open and click is outside modal, close it
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return {
    show,
    setShow,
    nodeRef,
    triggerRef,
  };
};

export default useDetectClickOut;
