import { FC } from "react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import { Grid, Paper, Typography } from "@mui/material";

import {
  truncate,
  getWeight,
  getPriceInUSD,
  getPriceInUYU,
} from "utils/commons";

import { IProduct } from "interfaces/products.interfaces";
import { Currency } from "interfaces/shopping-cart.interfaces";
import notFoundImage from "assets/images/image_not_found.png";

import ActionButtons from "./ActionButtons";

interface ICartItemProps {
  id: string;
  price: number;
  cartId: string;
  quantity: number;
  productId: string;
  product: IProduct;
  withPaper: boolean;
  isNew: boolean;
}

const CartItem: FC<ICartItemProps> = (props) => {
  const { id, product, price, quantity, withPaper, productId, isNew } = props;

  const navigate = useNavigate();
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const goToProductDetails = () => navigate(`/products?asin=${productId}`);

  const convertedPrice =
    currency === Currency.usd
      ? getPriceInUSD(price)
      : getPriceInUYU(price, cotization);

  const getContent = () => (
    <>
      <Box display="flex">
        <Box
          onClick={goToProductDetails}
          sx={{
            mr: 3,
            display: "flex",
            borderRadius: 1,
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #D9DDE1",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <Box>
            <Box
              p={0.8}
              alt="img-card"
              component="img"
              height={{ md: "120px" }}
              sx={{ objectFit: "contain" }}
              width={{ xs: "96px", md: "120px" }}
              src={
                product?.mainImage?.imageUrl ||
                (product?.imageUrlList && product?.imageUrlList[0]) ||
                notFoundImage
              }
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          <Grid container justifyContent="space-between">
            <Grid item md={8}>
              <Box
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Typography
                  onClick={goToProductDetails}
                  display={{ xs: "inline-block", md: "none" }}
                >
                  {truncate(product?.productTitle)}
                </Typography>

                <Typography
                  onClick={goToProductDetails}
                  display={{
                    xs: "none",
                    md: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  {truncate(product?.productTitle, 80)}
                </Typography>

                <Typography
                  fontSize={18}
                  marginTop={0}
                  fontWeight="800"
                  display={{ xs: "inline-block", md: "none" }}
                >
                  ${convertedPrice[0]}
                </Typography>

                <Typography
                  component="span"
                  display={{ xs: "inline-block", md: "none" }}
                  sx={{ fontWeight: 600, fontSize: 14, verticalAlign: ".5em" }}
                >
                  {convertedPrice[1] ?? ""}
                </Typography>

                <Box
                  marginTop={{ xs: 0.5, md: 1 }}
                  mb={{ md: 1 }}
                  display="flex"
                >
                  <Typography marginRight={1} sx={{ opacity: 0.6 }}>
                    Peso:{" "}
                  </Typography>
                  <Typography>{getWeight(product.productDetails)}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item>
              <Box
                onClick={goToProductDetails}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography
                  display={{ xs: "none", md: "inline-block" }}
                  fontSize={18}
                  fontWeight="800"
                >
                  ${convertedPrice[0]}
                </Typography>

                <Typography
                  component="span"
                  display={{ xs: "none", md: "inline-block" }}
                  sx={{ fontWeight: 600, fontSize: 14, verticalAlign: ".5em" }}
                >
                  {convertedPrice[1] ?? ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            mt="auto"
            alignItems="center"
            display={{ xs: "none", md: "flex" }}
          >
            <ActionButtons asin={productId} quantity={quantity} id={id} />
          </Box>
        </Box>
      </Box>

      <Box
        mt={1}
        display={{ xs: "flex", md: "none" }}
        justifyContent="space-between"
      >
        <ActionButtons asin={productId} icons quantity={quantity} id={id} />
      </Box>

      {!isNew && (
        <Typography mt={2} fontSize={14}>
          * Este producto es recertificado, renovado o refabricado. Esto
          significa que el mismo ha sido reacondicionado o reparado por el
          vendedor para lucir y funcionar correctamente. El producto puede venir
          en caja genérica.
        </Typography>
      )}
    </>
  );

  return (
    <>
      {withPaper ? (
        <Paper sx={{ marginTop: 1, width: "100%", padding: 2 }}>
          {getContent()}
        </Paper>
      ) : (
        <>{getContent()}</>
      )}
    </>
  );
};

export default CartItem;
