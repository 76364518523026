import { FC } from "react";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import { truncate } from "utils/commons";
import { TrackingStatus } from "interfaces/orders.interfaces";
import { ICartItem } from "interfaces/shopping-cart.interfaces";

interface IOrderItem {
  item: ICartItem;
  status?: TrackingStatus;
}

const OrderItem: FC<IOrderItem> = ({ item, status }) => {
  // const getStatusText = () => {
  //   switch (status) {
  //     case TrackingStatus.PACKAGE_SENT_FROM_AMAZON:
  //       return <OrderStatusChip color="green" label="En viaje" />;
  //     case TrackingStatus.PACKAGE_RECEIVED:
  //       return <OrderStatusChip color="green" label="En Miami" />;
  //     default:
  //       return <OrderStatusChip color="red" label="Pendiente" />;
  //   }
  // };

  return (
    <Grid item xs={12} sm={6} md={4} key={item.id} display="flex">
      <Box
        sx={{
          p: 1,
          mr: 3,
          borderRadius: 1,
          objectFit: "contain",
          border: "1px solid #D9DDE1",
        }}
        width={96}
        height={96}
        alt="img-item"
        component="img"
        src={item.image}
      />

      <Box width="138px">
        <Typography fontSize="14px" fontWeight={500} mb={1}>
          {truncate(item.title)}
        </Typography>

        <Box display="flex" mb={1}>
          <Typography mr={1} color="text.disabled" fontSize="14px">
            Cant:
          </Typography>
          <Typography fontSize="14px">{item.quantity}</Typography>
        </Box>

        <Box display="flex" mb={1}>
          <Typography mr={1} color="text.disabled" fontSize="14px">
            Precio:
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            {item.price}
          </Typography>
        </Box>

        {/* 
        <Box display="flex">
          <Typography mr={1} color="text.disabled" fontSize="14px">
            Estado:
          </Typography>
          {getStatusText()}
        </Box> */}
      </Box>
    </Grid>
  );
};

export default OrderItem;
