import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box, useTheme } from "@mui/system";
import { Divider, Typography } from "@mui/material";

import { getAddressStore } from "store/addresses/addresses.selectors";
import { getShippingAddresses } from "store/addresses/addresses.actions";

import AddressItem from "./ShippingAddressItem";
import CreateShippingAddress from "./CreateNewAddress";

const ShippingAddressList: FC = () => {
  const dispatch = useAppDispatch();

  const { palette } = useTheme();
  const [newAddress, setNewAddress] = useState<boolean>(false);
  const { list, isFetchched } = useAppSelector(getAddressStore);

  useEffect(() => {
    if (!isFetchched) dispatch(getShippingAddresses());
  }, [dispatch, isFetchched]);

  const renderAddresses = () => {
    const addresses = [...list].sort((a, b) =>
      a.defaultAddress === b.defaultAddress ? 0 : a.defaultAddress ? -1 : 1
    );

    return addresses.map((address) => (
      <Box key={address.id}>
        <AddressItem address={address} totalAddresses={list.length} />
        <Divider />
      </Box>
    ));
  };

  return (
    <Box>
      {renderAddresses()}

      {list && list.length < 3 && (
        <Typography
          fontSize={14}
          color={palette.secondary.main}
          onClick={() => setNewAddress(!newAddress)}
          sx={{ p: 2, cursor: "pointer" }}
        >
          Agregar dirección
        </Typography>
      )}

      <CreateShippingAddress open={newAddress} setOpen={setNewAddress} />
    </Box>
  );
};

export default ShippingAddressList;
