import { FC } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddOrRemoveFavoriteSection from "../web/AddOrRemoveFavoriteSection";

interface IProps {
  images: string[];
  productId: string;
  isLoggedIn: boolean;
}

const CarrouselImage: FC<IProps> = (props) => {
  const { productId, images, isLoggedIn } = props;

  const s = useStyles();

  return (
    <Box p={3} my={1} className={s.box}>
      <Carousel
        swipeable
        infiniteLoop
        showIndicators
        showStatus={false}
        showThumbs={false}
        showArrows={false}
      >
        {images.map((img) => (
          <Box
            key={img}
            src={img}
            height={280}
            component="img"
            alt="img-product"
            sx={{ objectFit: "contain" }}
          />
        ))}
      </Carousel>

      {isLoggedIn && (
        <AddOrRemoveFavoriteSection asin={productId} withBorderShadow />
      )}
    </Box>
  );
};

export default CarrouselImage;

const useStyles = makeStyles({
  box: {
    borderRadius: 6,
    background: "#fff",
    position: "relative",
    border: "1px solid #D9DDE1",
  },
});
