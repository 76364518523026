import { Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";

export const useFavoriteStyles = makeStyles((theme: Theme) => ({
  img: {
    width: 96,
    height: 96,
    padding: 6,
    borderRadius: 6,
    objectFit: "contain",
    border: "1px solid #EDEEF1",
    [theme.breakpoints.down("md")]: {
      width: 120,
      height: 120,
    },
  },

  btnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  rating: {
    width: "100%",
    display: "flex",
    marginLeft: 4,
    alignItems: "center",
  },
}));
