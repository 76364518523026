import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Grid, GridSize, Link, Typography } from "@mui/material";

interface ITextProps {
  to?: string;
  fs?: number;
  label: string;
  end?: boolean;
  xs: boolean | GridSize | undefined;
}

const HeaderText: FC<ITextProps> = (props) => (
  <Grid
    item
    xs={props.xs}
    sx={{
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: props.end ? "center" : "normal",
    }}
  >
    <Link underline="none" component={RouterLink} to={props.to ?? "#"}>
      <Typography variant="body2" fontSize={props.fs || 13} color="#E5E5E5">
        {props.label}
      </Typography>
    </Link>
  </Grid>
);

export default HeaderText;
