import { IOrder } from "interfaces/orders.interfaces";
import { CurrencyEntity } from "interfaces/globals.interface";
import { Currency } from "interfaces/shopping-cart.interfaces";

import {
  IProduct,
  ProductList,
  ProductDetail,
  IProductVariation,
} from "interfaces/products.interfaces";

import notFoundImage from "assets/images/image_not_found.png";

interface WeightObject {
  measure: string;
  ammount: number;
}

// list with possible weights, if is necessary add more just write the name of the weight in the list
const possibleWeightNames = [
  "weight",
  "itemweight",
  "packageweight",
  "productdimensions",
  "packagedimensions",
];

/**
 * A funciont that generate random Id
 * @returns random Id
 */
export const genRandomId = () => Math.random().toString(36).slice(2);

export const getPercentage = (
  partialValue: number,
  totalValue: number
): string => `(${((100 * partialValue) / totalValue).toFixed(0)}%)`;

export const parseProductInformation = (
  product: IProduct,
  optionalId?: string
): ProductList => ({
  asin: optionalId || product?.asin || "",
  price: product?.price,
  series: product?.series,
  dpUrl: product?.dpUrl || "",
  prime: product?.prime || false,
  variations: product?.variations,
  countReview: product?.countReview,
  retailPrice: product?.retailPrice,
  productRating: product?.productRating,
  productDescription: product?.productTitle,
  imgUrl:
    product?.mainImage?.imageUrl ||
    (product?.imageUrlList && product?.imageUrlList[0]) ||
    notFoundImage,
});

export const getPriceInUSD = (price: number): string[] =>
  price?.toString().split(".");

export const getPriceInUYU = (
  price: number,
  cotization: CurrencyEntity
): string[] => {
  const uyuCotization = (cotization.USD.buy + cotization.USD.sell) / 2;

  const parsed = Number(
    parseFloat((price * uyuCotization).toString()).toFixed(2)
  );

  return getPriceInUSD(parsed);
};

interface IGetPriceSavingInformation {
  price?: number;
  currency: Currency;
  retailPrice?: number;
  cotization: CurrencyEntity;
}

interface IReturntPriceSavingInformation {
  retail: number | null;
  saving: number | null;
  discount: string | null;
}

export const getPriceSavingInformation = (
  args: IGetPriceSavingInformation
): IReturntPriceSavingInformation => {
  const { cotization, currency, price, retailPrice } = args;

  if (!retailPrice || !price)
    return { retail: null, saving: null, discount: null };

  const retail =
    currency === Currency.usd
      ? getPriceInUSD(retailPrice)
      : getPriceInUYU(retailPrice, cotization);

  const savingUSD = retailPrice - price;

  const saving =
    currency === Currency.usd
      ? getPriceInUSD(savingUSD)
      : getPriceInUYU(savingUSD, cotization);

  return {
    discount: getPercentage(savingUSD, retailPrice),
    saving: Number(`${saving[0]}${saving[1] ? "." + saving[1] : ""} `),
    retail: Number(`${retail[0]}${retail[1] ? "." + retail[1] : ""} `),
  };
};

export const headerWithoutSearchBar = [
  "/checkout",
  "/successful-purchase",
  "/pending-purchase",
  "/failed-purchase",
];

export const footerWithoutSearchBar = headerWithoutSearchBar.concat([
  "/favorites",
  "/profile/wallet",
  "/profile/orders",
  "/profile/refunds",
  "/profile/security",
  "/profile/account-settings",
]);

/**
 * Truncate a text that is longer than the max length
 * @param text the string to truncate
 * @param maxLength the max length
 * return The truncated string
 */
export const truncate = (text: string, maxLength: number = 40) => {
  if (text?.length < maxLength) return text;

  return text?.substring(0, maxLength + 1) + "...";
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const removeSpacesAndConverStringToLowerCase = (str: string): string =>
  str
    .trim()
    .toLowerCase()
    .replace(/\s/g, "")
    .split("")
    .filter((st) => st !== "‏" && st !== "‎")
    .join("");

export const getWeightAndMeasureFromString = (string: string) => {
  const regexForMeasure = /\b(\w+)$/;
  const regexForValue =
    /[+-]?([0-9]*[.])?[0-9]+(?= ounces?| pounds?| kilograms?| grams? | kg | g)/;

  const value = string.match(regexForValue);
  const measure = string.match(regexForMeasure);

  if (!value || !measure) return { measure: "", ammount: 0 };

  return {
    measure: measure[0],
    ammount: Number(value[0]),
  };
};

export const getGreaterNumberFromArray = (array: number[]): number => {
  return array.reduce((prev, curr) => {
    return prev > curr ? prev : curr;
  }, 0);
};

export const getCalculatedWeightInGrames = (
  weightObjetc: WeightObject
): number => {
  const { ammount, measure } = weightObjetc;

  if (measure === "ounces" || measure === "ounce") return ammount * 28.34952;
  if (measure === "pounds" || measure === "pound") return ammount * 453.59237;
  if (measure === "kilograms" || measure === "kilogram" || measure === "kg")
    return ammount * 1000;

  return ammount;
};

export const getTotalWeightInGrames = (weights: number[]): number => {
  return weights.reduce((prev, curr) => {
    return prev + curr;
  }, 0);
};

export const getWeight = (productDetails: ProductDetail[]) => {
  if (!productDetails || !productDetails.length) return "No especificado";

  const possibleWeights = productDetails
    .filter((detail) => {
      const name = removeSpacesAndConverStringToLowerCase(detail.name);
      return possibleWeightNames.includes(name);
    })
    .map((detail) => {
      const name = removeSpacesAndConverStringToLowerCase(detail.name);
      return { name, value: detail.value.toLowerCase() };
    });

  if (!possibleWeights.length) return getFixesWeight(340);

  const weights = possibleWeights
    .map((weight) => getWeightAndMeasureFromString(weight.value))
    .filter((weight) => weight);

  const calculatedWeights = weights.map((weight) =>
    getCalculatedWeightInGrames(weight)
  );

  const weight = Number(
    getGreaterNumberFromArray(calculatedWeights).toFixed(2)
  );

  return getFixesWeight(weight);
};

export const getFixesWeight = (weight: number) => {
  if (weight > 1000) return `${(weight / 1000).toFixed(2)} kilogramos`;
  return `${weight.toFixed(2)} gramos`;
};

export const getPriceText = ({
  currency,
  price,
  salePrice,
  cotization,
}: {
  currency: Currency;
  price?: number;
  salePrice?: number;
  cotization: CurrencyEntity;
}) => {
  const parsedPrice =
    currency === Currency.usd
      ? getPriceInUSD(price || salePrice || 0)
      : getPriceInUYU(price || salePrice || 0, cotization);

  return `${currency === Currency.usd ? "U$S" : "$"} ${parsedPrice[0]}${
    parsedPrice[1] ? "." + parsedPrice[1] : ""
  }`;
};

export const getImageFromProduct = (product: IProduct) => {
  return (
    product.mainImage?.imageUrl ||
    (product.imageUrlList && product.imageUrlList[0]) ||
    notFoundImage
  );
};

export const shuffleArray = (array: any[]): any[] => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const getOrderId = (order: IOrder) => {
  if (order.skypostalTracking) {
    return order.skypostalTracking;
  }

  if (order.amazonTrackigs.length && order.amazonTrackigs[0].trackingNumber) {
    return order.amazonTrackigs[0].trackingNumber;
  }

  return order.id;
};

export const checkIfIsVariationDropdown = (variation: IProductVariation) => {
  const variations = ["size_name", "variation_size_name"];

  return (
    variations.includes(variation.variationName) && variation.values.length
  );
};

// sort array of objects javascript by key string
export const sortByKey = (array: any[], key: string) =>
  array
    .map((x) => x)
    .sort(function (a, b) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });

export const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date): string => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};
