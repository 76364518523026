import { FC } from "react";
import { CircularProgress } from "@mui/material";

/**
 * Component for showing a spinner inside button component with loader while render it
 * @example
 * return <ButtonSpinner />;
 */

const ButtonSpinner: FC = () => {
  return <CircularProgress sx={{ color: "#F7F7FA" }} size={28} />;
};

export default ButtonSpinner;
