import { useAppDispatch, useAppSelector } from "hooks";
import { ChangeEvent, FC, useEffect, useState } from "react";

import {
  Radio,
  Theme,
  RadioGroup,
  FormControl,
  Typography,
  FormControlLabel,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { getFees } from "store/fees/fees.actions";
import { getTotalCartPrice } from "store/cart/cart.selectors";
import { FeesDto, FrancheseType } from "interfaces/shopping-cart.interfaces";
import { toggleUseFranchiseInPurchase } from "store/checkout/checkout.slice";

const FrancheseCheckoutForm: FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const [useFranchise, setUseFranchise] = useState(FrancheseType.USE);
  const totalCartAmmount = useAppSelector(getTotalCartPrice);
  const { user } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (!(totalCartAmmount > 200)) {
      let params: FeesDto = { userId: user?.id, useFranchise };
      dispatch(getFees(params)).then(() =>
        dispatch(toggleUseFranchiseInPurchase(useFranchise))
      );
    }
  }, [dispatch, user, useFranchise, totalCartAmmount]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setUseFranchise(event.target.value as FrancheseType);

  if (totalCartAmmount > 200) return null;

  return (
    <FormControl sx={{ my: 2 }}>
      <RadioGroup
        row
        value={useFranchise}
        onChange={handleChange}
        sx={{ justifyContent: { xs: "space-between", md: "flex-start" } }}
      >
        <FormControlLabel
          sx={{ mr: { md: 3 } }}
          label="Usar Franquicia"
          className={styles.radio}
          value={FrancheseType.USE}
          control={<Radio size="small" />}
          checked={useFranchise === FrancheseType.USE}
        />

        <FormControlLabel
          sx={{ ml: { md: 3 } }}
          className={styles.radio}
          value={FrancheseType.NOT_USE}
          label="No consumir franquicia"
          control={<Radio size="small" />}
          checked={useFranchise === FrancheseType.NOT_USE}
        />
      </RadioGroup>

      <Typography
        mt={2}
        fontSize={12}
        color="text.secondary"
        width={{ sx: "100%", md: "60%" }}
      >
        {useFranchise ? franchiseText : withoutFranchiseText}
      </Typography>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  radio: { "& .MuiFormControlLabel-label": { fontSize: 13 } },
}));

const franchiseText =
  "Tienes 3 compras por año libre de impuestos hasta U$S 200 en productos.";

const withoutFranchiseText =
  "Ahora puedes pagar los impuestos en Puntomall para no consumir franquicias (60% del valor de los productos) + U$S 2 por gestión";

export default FrancheseCheckoutForm;
