import { FC } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import {
  Star as StarIcon,
  Verified as VerifiedIcon,
  CreditCard as CreditCardIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";

interface IProps {
  text: string;
  icon: IconTypes;
}

export enum IconTypes {
  star = "star",
  truck = "truck",
  verified = "verified",
  creditCard = "creditCard",
}

/**
 * Component for showing custom icon with a text.
 * @param {string} text
 * @param {IconTypes} icon
 *
 * @example
 * return (
 *   <TextWithIcon text="Buy now" icon="creditCard" />
 * )
 */

const TextWithIcon: FC<IProps> = (props) => {
  const { icon, text } = props;

  return (
    <Box display="flex" my={1}>
      {Icons[icon]}

      <Typography
        fontSize={13}
        variant="body2"
        sx={{ ml: 0.5 }}
        color="text.secondary"
      >
        {text}
      </Typography>
    </Box>
  );
};

const Icons = {
  star: <StarIcon fontSize="small" />,
  truck: <LocalShippingIcon fontSize="small" />,
  creditCard: <CreditCardIcon fontSize="small" />,
  verified: <VerifiedIcon fontSize="small" sx={{ color: "#2EAB3F" }} />,
};

export default TextWithIcon;
