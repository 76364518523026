import { FC } from "react";
import { Container, Typography } from "@mui/material";

const EmptySearchList: FC = () => {
  return (
    <Container sx={{ my: 10 }} maxWidth="lg">
      <Typography variant="body1" align="center">
        No hemos encontrado coincidencias con tu busqueda.
      </Typography>

      <Typography align="center" fontSize={12}>
        ¡Intentalo nuevamente con otras palabras!
      </Typography>
    </Container>
  );
};

export default EmptySearchList;
