import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { genRandomId, scrollTop } from "utils/commons";
import { fetchProducts } from "store/products/products.actions";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";

import { Box, useTheme } from "@mui/system";
import { Container, Grid, Paper, useMediaQuery } from "@mui/material";

import Pagination from "components/organisms/pagination";
import EmptySearchList from "./components/EmptySearchList";
import ProductCard from "components/molecules/product-card";
import BackdropLoader from "components/atoms/backdrop-loader";
import MobileProductCard from "components/molecules/mobile-product-card";

import {
  getStateProducts,
  getStateProductsHelpers,
  getStateProductsLoaders,
} from "store/products/products.selectors";

const SearchResults: FC = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { breakpoints } = useTheme();

  const { list, currentPage } = useAppSelector(getStateProducts);
  const { productsLoader } = useAppSelector(getStateProductsLoaders);
  const { fetchedAllProducts } = useAppSelector(getStateProductsHelpers);

  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));

  const keyword = query.get("amz") as string;
  const pagination = Number(query.get("page")) as number;

  useEffect(() => {
    scrollTop();
  }, [pagination]);

  useEffect(() => {
    if (!keyword || !pagination) navigate("/");

    dispatch(fetchProducts({ page: pagination, searchValue: keyword }));
    scrollTop();
  }, [dispatch, navigate, keyword, pagination]);

  const handleChange = (currentPage: number) => {
    navigate(`/search?amz=${keyword}&page=${currentPage}`);
    scrollTop();
  };

  if (productsLoader) return <BackdropLoader />;

  return (
    <Container sx={{ my: { md: 10, xs: 2 } }} maxWidth="lg">
      {!productsLoader && fetchedAllProducts && !list.length ? (
        <EmptySearchList />
      ) : (
        <>
          {downToMiddleScreenSize ? (
            <Paper>
              {list.map((p) => (
                <MobileProductCard product={p} key={genRandomId()} />
              ))}
            </Paper>
          ) : (
            <Grid container>
              {list.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={genRandomId()}>
                  <ProductCard product={item} />
                </Grid>
              ))}
            </Grid>
          )}

          <Box my={2} sx={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              handlerFunction={handleChange}
              page={{ total: 20, current: currentPage }}
            />
          </Box>
        </>
      )}
    </Container>
  );
};
export default SearchResults;
