import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppSelector, useQuery } from "hooks";
import ChangePassword from "./components/ChangePassword";

const RecoverPasswordPage: FC = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const code = query.get("code");
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!code) return navigate("/signin");
  }, [query, navigate, code]);

  return <ChangePassword code={code as string} />;
};

export default RecoverPasswordPage;
