import { FC } from "react";
import { Backdrop, CircularProgress } from "@mui/material";

/**
 * Component for showing a backdrop with loader while render it
 * @example
 * return <BackdropLoader />;
 */

const BackdropLoader: FC = () => {
  return (
    <Backdrop
      open
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoader;
