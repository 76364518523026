import { Box } from "@mui/system";
import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import {
  Chip,
  Grid,
  Table,
  Paper,
  Select,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";

import OrderModal from "./components/OrderModal";
import SimpleTitle from "components/atoms/simple-title";
import CustomButton from "components/atoms/custom-button";
import CustomLayout from "components/atoms/simple-layout";

import { getSingleOrder } from "store/orders.slice";
import { puntoMallAPI } from "services/config/axios.config";
import { TrackingStatus } from "interfaces/orders.interfaces";

const SkypostalPanel: FC = () => {
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState("ALVOL");
  const [showModal, setShowModal] = useState(true);
  const { singleOrder } = useAppSelector((state) => state.orders);

  if (showModal)
    return <OrderModal showModal={showModal} setShowModal={setShowModal} />;

  return (
    <CustomLayout pd={2}>
      <SimpleTitle title="Panel Skypostal" />
      {singleOrder ? (
        <>
          <CustomButton
            variant="primary"
            text="Actualizar datos"
            onClick={() => dispatch(getSingleOrder(singleOrder!.id))}
          />

          <Typography my={1}>Informacion de la orden:</Typography>
          <Typography variant="body2">ID: {singleOrder.id}</Typography>
          <Typography variant="body2">
            Fecha: {singleOrder.dateAdded}
          </Typography>
          <Typography variant="body2">
            Consolidado: {singleOrder.consolidationProcessed ?? "No"}
          </Typography>
          {singleOrder.issue ? (
            <Typography variant="body2">Issue: {singleOrder.issue}</Typography>
          ) : null}
          <Typography variant="body2">
            Estado:
            <Chip sx={{ ml: 2 }} color="primary" label={singleOrder.status} />
          </Typography>

          <Divider sx={{ my: 3 }} />

          <Typography fontSize={18} my={3}>
            Eventos:
          </Typography>

          <Select
            sx={{ mr: 3 }}
            label="Seleccionar un estado"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="ALVOL">1 - Viajando a país de destino</MenuItem>
            <MenuItem value="SHOFD">2 - Paquete en reparto</MenuItem>
            <MenuItem value="SHDEL">3 - El paquete fue entregado</MenuItem>
            <MenuItem value="ALDAN">Items dañados</MenuItem>
            <MenuItem value="ALPEL">Items peligrosos</MenuItem>
            <MenuItem value="ALINC">Incompleto</MenuItem>
            <MenuItem value="ALMCD">
              Falta documentación para la aduana
            </MenuItem>
            <MenuItem value="SINFA">Falta una factura</MenuItem>
            <MenuItem value="ALSDA">Paquete demasiado grande</MenuItem>
            <MenuItem value="ALPER">
              El paquete contiene items perecederos
            </MenuItem>
            <MenuItem value="ALPRB">
              El contenido del paquete está prohibido
            </MenuItem>
            <MenuItem value="ALRES">
              El contenido del paquete está considerado como restringido
            </MenuItem>
          </Select>

          {singleOrder.skypostalTracking ? (
            <CustomButton
              text="Recibir evento"
              variant="secondary"
              onClick={() => {
                puntoMallAPI
                  .post("/fake-skypostal-api/status-hook", {
                    trackingNumber: singleOrder.skypostalTracking,
                    statusCode: status,
                  })
                  .then(() => {
                    dispatch(getSingleOrder(singleOrder.id));
                  });
              }}
            />
          ) : null}

          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Código</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...singleOrder.events]
                  .sort(
                    (a, b) =>
                      new Date(b.date).getTime() - new Date(a.date).getTime()
                  )
                  .map((event, index) => (
                    <TableRow>
                      <TableCell>{event.description}</TableCell>
                      <TableCell>{event.date}</TableCell>
                      <TableCell>{event.code}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ my: 3 }} />

          <Typography fontSize={18}>Paquetes:</Typography>

          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            width="80%"
            sx={{ my: 2 }}
          >
            {singleOrder.amazonTrackigs.map((amazonTracking, index) => (
              <Grid item>
                <Paper sx={{ paddingX: 3, paddingY: 3 }}>
                  <Typography fontSize={20} marginBottom={2}>
                    Paquete {index + 1}
                  </Typography>
                  <Typography>
                    Amazon tracking: {amazonTracking.trackingNumber}
                  </Typography>
                  <Typography>
                    Skypostal Tracking: {amazonTracking.skypostalTracking}
                  </Typography>
                  <Typography>Estado: {amazonTracking.status}</Typography>

                  {amazonTracking.status ===
                  TrackingStatus.PACKAGE_SENT_FROM_AMAZON ? (
                    <Box marginTop={2}>
                      <CustomButton
                        text="Marcar paquete como recibido por skypostal"
                        variant="secondary"
                        onClick={() => {
                          puntoMallAPI
                            .post("/fake-skypostal-api/package-received", {
                              email: singleOrder.customerEmail,
                              externalTracking: amazonTracking.trackingNumber,
                            })
                            .then(() =>
                              dispatch(getSingleOrder(singleOrder.id))
                            );
                        }}
                      />
                    </Box>
                  ) : null}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}
    </CustomLayout>
  );
};

export default SkypostalPanel;
