import { StarBorder } from "@mui/icons-material";
import { Grid, Rating, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "hooks";
import { FC } from "react";
import { Link } from "react-router-dom";
import theme from "styles/muiTheme";
import { getPriceText } from "utils/commons";
import { useFavoriteStyles } from "./styles";

interface InformationSectionProps {
  asin: string;
  title: string;
  price: number;
  salePrice: number;
  countReview: number;
  productRating: string;
}

const FavoriteCardInformation: FC<InformationSectionProps> = (props) => {
  const { price, title, productRating, countReview, asin, salePrice } = props;
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const downToSmallScreenSize = useMediaQuery(theme.breakpoints.down("sm"));

  const s = useFavoriteStyles();

  const showRating = () => {
    if (!productRating) return null;
    if (typeof productRating === "number") return productRating;
    return Number(productRating.split(" ")[0]);
  };

  const url = `/products?asin=${asin}`;

  const parsedTitle = title.length <= 180 ? title : `${title.slice(0, 180)}...`;

  return (
    <Grid item md={7}>
      <Box
        to={url}
        component={Link}
        sx={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography
          fontSize={14}
          color="text.primary"
          sx={{ ml: 1, mb: 0.5, cursor: "pointer" }}
        >
          {downToSmallScreenSize
            ? `${parsedTitle.slice(0, 60)}...`
            : parsedTitle}
        </Typography>

        {countReview ? (
          <Box className={s.rating}>
            <Rating
              readOnly
              size="small"
              value={showRating()}
              precision={0.5}
              name="product-feedback"
              emptyIcon={
                <StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography ml={0.5} color="#007171" variant="body2">
              {countReview}
            </Typography>
          </Box>
        ) : null}

        {price ? (
          <>
            <Typography sx={{ fontWeight: 600, ml: 1 }} fontSize={14}>
              Precio: {getPriceText({ currency, price, salePrice, cotization })}
            </Typography>
          </>
        ) : (
          <Typography
            fontSize={14}
            color="text.primary"
            sx={{ ml: 1, fontWeight: 600 }}
          >
            Consultar Precio
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default FavoriteCardInformation;
