import { FC, Fragment, useState } from "react";
import { ProductDetail } from "interfaces/products.interfaces";

import { Box, useTheme } from "@mui/system";
import { Divider, Grid, Typography } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

interface IProps {
  features: string[];
  productDetails: ProductDetail[];
}
interface IDetailProps {
  detail: ProductDetail;
}

const ProductDetails: FC<IProps> = (props) => {
  const { productDetails, features } = props;

  const { palette } = useTheme();
  const [viewMore, setViewMore] = useState<boolean>(false);

  return (
    <Fragment>
      <Divider sx={{ my: 3 }} />

      <Box mt={1} ml={1}>
        <Grid container>
          {productDetails &&
            productDetails.length &&
            productDetails.map((detail) => (
              <Grid item md={6} lg={6} sm={12} key={detail.name}>
                <Detail detail={detail} />
              </Grid>
            ))}
        </Grid>

        <Typography
          component="a"
          variant="subtitle2"
          color={palette.secondary.main}
          onClick={() => setViewMore(!viewMore)}
          sx={{
            display: "flex",
            alignItems: "center",
            my: 1,
            cursor: "pointer",
          }}
        >
          {viewMore ? "Ver menos" : "Ver más"}
          <ArrowDropDownIcon />
        </Typography>

        {viewMore && (
          <Box component="ul">
            {features.map((text, index) => (
              <Typography my={0.5} key={index} component="li" fontSize={14}>
                {text}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

const Detail: FC<IDetailProps> = (props) => {
  const { palette } = useTheme();

  return (
    <Box my={0.5} sx={{ display: "flex" }}>
      <Typography
        fontSize={14}
        color={palette.text.secondary}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {props.detail.name}:
      </Typography>

      <Typography fontSize={14} ml={0.5}>
        {props.detail.value.slice(0, 50)}
      </Typography>
    </Box>
  );
};

export default ProductDetails;
