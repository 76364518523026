import { FC } from "react";

import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

interface IProps {
  title: string;
  expanded: boolean;
  goBack?: () => void;
  showGoBack?: boolean;
}

const borderColor = "#221f1f2e";

const AcordionBox: FC<IProps> = (props) => {
  const { children, expanded, title, goBack, showGoBack } = props;

  const handleExpandClick = () => {
    if (!expanded && goBack) goBack();
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        borderRadius: "4px",
        border: `1px solid ${
          expanded ? "rgba(81, 130, 255, 0.25)" : "transparent"
        }`,
      }}
    >
      <AccordionSummary
        expandIcon={
          !expanded && showGoBack ? (
            <Typography
              fontSize={12}
              onClick={handleExpandClick}
              sx={{
                p: 0.7,
                px: 2,
                borderRadius: 2,
                color: "#1758FF",
                background: "#DBE5FF",
              }}
            >
              Editar
            </Typography>
          ) : null
        }
        sx={{
          borderBottom: `1px solid ${
            expanded ? `${borderColor}` : "transparent"
          }`,
          px: { xs: 1 },
          mx: { md: 3, xs: 1 },
        }}
      >
        <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AcordionBox;
