import { FC, useState } from "react";
import { Grid } from "@mui/material";
import { updateUser } from "store/user.slice";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import ProfileItem from "./ProfileItem";
import Input from "components/atoms/input";

const UpdatePhoneNumber: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userInformation);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().matches(/^09[0-9]{7}$/, {
      message:
        "El valor del celular debe tener un formato 09x xxx xxx, sin espacios",
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: FieldValues) => {
    dispatch(updateUser({ phone: data.phone }));

    setShowModal(false);
  };

  return (
    <ProfileItem
      name="Teléfono"
      value={user?.phone ?? ""}
      title="Modificar teléfono"
      handleSubmit={handleSubmit(onSubmit)}
      setOpen={setShowModal}
      open={showModal}
    >
      <Grid item xs={12}>
        <Input
          name="phone"
          defaultValue={user?.phone}
          control={control}
          error={errors.phone}
          label="Número de teléfono"
          rules={{ required: "Campo obligatorio" }}
        />
      </Grid>
    </ProfileItem>
  );
};

export default UpdatePhoneNumber;
