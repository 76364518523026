import { FC, Fragment, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";

import { FormGroup, Typography } from "@mui/material";

import { setForgotPassword } from "store/credentials.slice";
import { forgotPasswordAction } from "store/credentials.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";

import Input from "components/atoms/input";
import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";
import SnackbarMessage from "components/atoms/custom-snackbar";

const ForgotPasswordModal: FC = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);

  const {
    error,
    isLoading,
    forgotPassword: open,
  } = useAppSelector((state) => state.credentials);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Ingrese un correo electrónico válido")
      .required("Este campo es requerido"),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: FieldValues) => {
    setEmail(data.email);

    dispatch(forgotPasswordAction(data.email)).then((response: any) => {
      if (!response.error) setConfirmationMsg(true);
      else setErrorMsg(true);

      reset();
      handleClose();
    });
  };

  const handleClose = () => {
    reset();
    setEmail("");
    dispatch(setForgotPassword(false));
  };

  return (
    <Fragment>
      <CustomModal size="small" open={open} handleClose={handleClose}>
        <FormGroup sx={{ py: 1 }}>
          <Typography fontSize={18} mb={2}>
            ¿Olvidaste tu contraseña?
          </Typography>

          <Typography variant="body2" color="text.secondary" mb={2}>
            Por favor, ingresa tu correo electrónico abajo. Recibirás un link
            para restablecer tu contraseña.
          </Typography>

          <Input
            name="email"
            type="email"
            control={control}
            error={errors.email}
            label="Correo Electrónico"
          />

          <CustomButton
            text="Enviar"
            variant="primary"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            sx={{ width: { xs: "100%", md: "50%" }, mt: 1 }}
          />
        </FormGroup>
      </CustomModal>

      {confirmationMsg && (
        <SnackbarMessage
          open={confirmationMsg}
          setOpen={setConfirmationMsg}
          type={SnackbarType.success}
          origin={{ vertical: "top", horizontal: "center" }}
          message={`Hemos enviado un correo a ${email} donde recibirá instrucciones para restablecer su contraseña`}
        />
      )}

      {errorMsg && (
        <SnackbarMessage
          open={errorMsg}
          setOpen={setErrorMsg}
          type={SnackbarType.warning}
          origin={{ vertical: "top", horizontal: "center" }}
          message={
            error && error?.code === 404
              ? invalidMsj
              : "Ha ocurrido un error inesperado"
          }
        />
      )}
    </Fragment>
  );
};

export default ForgotPasswordModal;

const invalidMsj =
  "Esa dirección no es válida, no es un correo electrónico principal verificado o no está asociada con una cuenta de usuario personal";
