import { FC } from "react";
import { useNavigate } from "react-router-dom";

import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import {
  Favorite,
  ReceiptLong,
  AccountBalanceWallet,
  Autorenew,
} from "@mui/icons-material";
import { useStyles } from "./styles";

interface IProps {
  toggleView: (val: boolean) => void;
}

const MoreAccountOptions: FC<IProps> = (props) => {
  const { toggleView } = props;

  const s = useStyles();
  const navigate = useNavigate();

  const goToPageSection = (section: string) => {
    toggleView(false);
    navigate(section);
  };

  return (
    <List sx={{ m: 0, p: 0 }}>
      <ListItemButton
        className={s.listItem}
        onClick={() => goToPageSection("/profile/orders")}
      >
        <ListItemIcon>
          <ReceiptLong />
        </ListItemIcon>

        <ListItemText
          primary="Mis Órdenes"
          sx={{ color: "text.secondary", ml: -2 }}
        />
      </ListItemButton>

      <ListItemButton
        className={s.listItem}
        onClick={() => goToPageSection("/favorites")}
      >
        <ListItemIcon>
          <Favorite />
        </ListItemIcon>

        <ListItemText
          primary="Favoritos"
          sx={{ color: "text.secondary", ml: -2 }}
        />
      </ListItemButton>

      <ListItemButton
        className={s.listItem}
        onClick={() => goToPageSection("/profile/wallet")}
      >
        <ListItemIcon>
          <AccountBalanceWallet />
        </ListItemIcon>

        <ListItemText
          primary="Mi Billetera"
          sx={{ color: "text.secondary", ml: -2, display: "none" }}
        />
      </ListItemButton>

      <ListItemButton
        className={s.listItem}
        onClick={() => goToPageSection("/profile/refunds")}
      >
        <ListItemIcon>
          <Autorenew />
        </ListItemIcon>

        <ListItemText
          primary="Devoluciones"
          sx={{ color: "text.secondary", ml: -2, display: "none" }}
        />
      </ListItemButton>
    </List>
  );
};

export default MoreAccountOptions;
