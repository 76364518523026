import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";

import { Box, Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Divider, Typography } from "@mui/material";

import CustomLayout from "components/atoms/simple-layout";
import CustomButton from "components/atoms/custom-button";
import BackdropLoader from "components/atoms/backdrop-loader";
import OrderCard from "pages/profile/orders/components/OrderCard";
import confirmCheckIcon from "assets/icons/confirm_check_icon.svg";

import { scrollTop } from "utils/commons";
import { getSingleOrder } from "store/orders.slice";

const SuccessfulPurchasePage: FC = () => {
  const s = useStyles();

  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userId = query.get("uid") as string;
  const orderId = query.get("oid") as string;

  const { user } = useAppSelector((state) => state.userInformation);
  const { singleOrder, isLoading } = useAppSelector((state) => state.orders);

  useEffect(() => {
    scrollTop();

    if (!userId || !orderId) navigate("/");
    else dispatch(getSingleOrder(orderId));
  }, [dispatch, navigate, orderId, userId]);

  const goToHomePage = () => {
    scrollTop();
    navigate("/", { replace: true });
    window.location.reload();
  };

  if (isLoading) return <BackdropLoader />;

  return (
    <CustomLayout pd={2}>
      <Box className={s.imgContainer}>
        <Box
          component="img"
          alt="confirm-icon"
          className={s.icon}
          src={confirmCheckIcon}
        />

        <Typography my={2} align="center" color="text.primary" fontSize={30}>
          Genial, {user?.firstName} tu compra ha sido exitosa
        </Typography>

        <Typography
          mx="auto"
          align="center"
          color="text.secondary"
          width={{ xs: "100%", md: "70%" }}
        >
          Recibirás tus productos de 10 a 15 días hábiles a partir de hoy, te
          recomendamos estar atento a tu correo electrónico por cualquier
          eventualidad en el envío.
        </Typography>

        <Divider sx={{ my: 3 }} />

        {singleOrder && (
          <Box p={{ sx: 1, md: 2 }} m={{ sx: 0, md: 2 }}>
            <OrderCard order={singleOrder} />
          </Box>
        )}

        <CustomButton
          variant="primary"
          text="Seguir comprando"
          onClick={goToHomePage}
          sx={{ width: { xs: "100%", md: 254 }, margin: "0 auto" }}
        />
      </Box>
    </CustomLayout>
  );
};

export default SuccessfulPurchasePage;

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 96,
    margin: "0 auto",
    objectFit: "contain",
  },
  imgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(6),
  },
}));
