import { FC } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box, Button, Typography } from "@mui/material";
import { Add, DeleteOutlined, Remove } from "@mui/icons-material";

import { removeItemToLsCart, updateLsCartItem } from "store/lscart.slice";
import { removeItemToCart, updateCartItem } from "store/cart/cart.actions";

import AddOrRemoveFavoriteSection from "pages/single-product/web/AddOrRemoveFavoriteSection";

interface IActionButtonsProps {
  id: string;
  asin: string;
  quantity: any;
  icons?: boolean;
}

enum OperationType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

const ActionButtons: FC<IActionButtonsProps> = ({
  id,
  asin,
  quantity,
  icons = false,
}) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  const parsedQuantity = Number(quantity);

  const updateItem = (type: OperationType) => {
    const body = {
      quantity:
        type === OperationType.ADD ? parsedQuantity + 1 : parsedQuantity - 1,
      cartItemId: id,
    };

    if (!isLoggedIn)
      dispatch(updateLsCartItem({ id, quantity: body.quantity }));
    else dispatch(updateCartItem(body));
  };

  const deleteItem = () => {
    if (!isLoggedIn) dispatch(removeItemToLsCart(id));
    else dispatch(removeItemToCart(id));
  };

  return (
    <>
      <Box
        height="40px"
        display="flex"
        borderRadius={1}
        alignItems="center"
        border="1px solid #DDDDDD"
        width={icons ? "31%" : "120px"}
      >
        <Box
          height="100%"
          display="flex"
          border="none"
          width="33.33%"
          color="#7A7979"
          component="button"
          alignItems="center"
          justifyContent="center"
          disabled={quantity <= 1}
          borderRight="1px solid #DDDDDD"
          onClick={() => updateItem(OperationType.REMOVE)}
          sx={{ padding: 0, backgroundColor: "transparent" }}
        >
          <Remove fontSize="inherit" sx={{ cursor: "pointer" }} />
        </Box>

        <Typography textAlign="center" width="33.33%">
          {quantity}
        </Typography>

        <Box
          border="none"
          display="flex"
          height="100%"
          width="33.33%"
          color="#7A7979"
          component="button"
          alignItems="center"
          justifyContent="center"
          borderLeft="1px solid #DDDDDD"
          onClick={() => updateItem(OperationType.ADD)}
          sx={{ padding: 0, backgroundColor: "transparent" }}
        >
          <Add sx={{ cursor: "pointer" }} />
        </Box>
      </Box>

      {isLoggedIn ? (
        <>
          {icons ? (
            <Button
              sx={{
                width: "31%",
                height: "40px",
                color: "#7A7979",
                border: "1px solid #D9DDE1",
              }}
            >
              <AddOrRemoveFavoriteSection
                asin={asin}
                getFavorites={false}
                withIconAbsolute={false}
              />
            </Button>
          ) : (
            <AddOrRemoveFavoriteSection
              asin={asin}
              getFavorites={false}
              useTextButton={true}
            />
          )}
        </>
      ) : null}

      {icons ? (
        <Button
          sx={{
            width: "31%",
            color: "#7A7979",
            border: "1px solid #D9DDE1",
          }}
          onClick={deleteItem}
        >
          <DeleteOutlined />
        </Button>
      ) : (
        <Typography
          color="#007171"
          onClick={deleteItem}
          borderLeft="1px solid #D9DDE1"
          sx={{ cursor: "pointer", pl: 3, ml: 3 }}
        >
          Eliminar
        </Typography>
      )}
    </>
  );
};

export default ActionButtons;
