import { FC } from "react";
import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";

interface IProps {
  steps: IStep[];
  currentValue: string | number;
}

interface IStep {
  label: string;
  value: string | number;
}

const HorizontalStepper: FC<IProps> = (props) => {
  const { steps, currentValue } = props;
  const { palette } = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {steps.map((step, i) => (
        <Typography
          key={i}
          mr={1}
          sx={{
            color:
              step.value === currentValue
                ? palette.primary.main
                : palette.text.secondary,
          }}
          fontSize={13}
        >
          {step.label} {i < steps.length - 1 && ">"}
        </Typography>
      ))}
    </Box>
  );
};

export default HorizontalStepper;
