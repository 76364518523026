import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import HowWorksPage from "pages/static-pages/HowWorksPage";
import FeesInformationPage from "pages/static-pages/FeesInformationPage";
import RelevantInformationPage from "pages/static-pages/RelevantInformationPage";

const StaticRoutes: FC = () => (
  <Routes>
    <Route path="how-works" element={<HowWorksPage />} />
    <Route path="fees" element={<FeesInformationPage />} />
    <Route path="relevant" element={<RelevantInformationPage />} />
  </Routes>
);

export default StaticRoutes;
