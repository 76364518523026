import { FC } from "react";
import { Typography } from "@mui/material";

interface IProps {
  title: string;
}

const SimpleTitle: FC<IProps> = (props) => {
  return (
    <Typography
      variant="h5"
      mb={{ md: 2 }}
      color="text.primary"
      fontSize={{ xs: 20 }}
    >
      {props.title}
    </Typography>
  );
};

export default SimpleTitle;
