import { Routes, Route, Navigate } from "react-router-dom";

import Home from "pages/home";
import Tracking from "pages/tracking";
import SearchResults from "pages/search";
import NavigationView from "pages/navigation";
import ShoppingCart from "pages/shopping-cart";
import SingleProduct from "pages/single-product";

import AppLayout from "components/organisms/layout";
import RequiredAuthRoutes from "./utils/RequiredAuthRoutes";

import OffersView from "pages/offers";
import CheckoutPage from "pages/checkout";
import FavoritesView from "pages/favorites";
import GenValidationCode from "pages/gen-validation-code";
import BestSellersProductsView from "pages/best-sellers";
import TrendingProductsView from "pages/trending-products";
import SuccessfulPurchasePage from "pages/successful-purchase";
import PendingPurchasePage from "pages/pending-purchase";

import StaticRoutes from "./StaticRoutes";
import ProfileRoutes from "./ProfileRoutes";
import FailedPurchasePage from "pages/failed-purchase";

const MainRoutes = () => {
  return (
    <AppLayout>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/offers" element={<OffersView />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/products" element={<SingleProduct />} />
        <Route path="/navigation" element={<NavigationView />} />

        <Route path="/shopping-cart" element={<ShoppingCart />} />
        <Route path="/gen-validation-code" element={<GenValidationCode />} />
        <Route path="/trending-products" element={<TrendingProductsView />} />
        <Route path="/best-sellers" element={<BestSellersProductsView />} />

        <Route path="/profile/*" element={<ProfileRoutes />} />
        <Route path="/information/*" element={<StaticRoutes />} />

        <Route
          path="/tracking"
          element={
            <RequiredAuthRoutes>
              <Tracking />
            </RequiredAuthRoutes>
          }
        />

        <Route
          path="/checkout"
          element={
            <RequiredAuthRoutes>
              <CheckoutPage />
            </RequiredAuthRoutes>
          }
        />

        <Route
          path="/successful-purchase"
          element={
            <RequiredAuthRoutes>
              <SuccessfulPurchasePage />
            </RequiredAuthRoutes>
          }
        />

        <Route
          path="/pending-purchase"
          element={
            <RequiredAuthRoutes>
              <PendingPurchasePage />
            </RequiredAuthRoutes>
          }
        />

        <Route
          path="/failed-purchase"
          element={
            <RequiredAuthRoutes>
              <FailedPurchasePage />
            </RequiredAuthRoutes>
          }
        />

        <Route
          path="/favorites"
          element={
            <RequiredAuthRoutes>
              <FavoritesView />
            </RequiredAuthRoutes>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AppLayout>
  );
};

export default MainRoutes;
