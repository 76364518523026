import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { userServices } from "services/user.services";
import { RequestError } from "interfaces/errors.interfaces";
import { IUpdatePasswordWithCode } from "interfaces/user.interfaces";

interface CredentialsState {
  isLoading: boolean;
  forgotPassword: boolean;
  error: RequestError | null;
}

const initialState: CredentialsState = {
  error: null,
  isLoading: false,
  forgotPassword: false,
};

/**
 * A function that send an email with a code to recover the password
 */
export const forgotPasswordAction = createAsyncThunk(
  "credentials/forgotPasswordAction",
  async (email: string, { rejectWithValue }) => {
    try {
      return await userServices.forgotPassword(email);
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function the password of the user with a code
 */
export const updatePasswordWithCodeAction = createAsyncThunk(
  "credentials/updatePasswordWithCode",
  async (
    updatePasswordWithCode: IUpdatePasswordWithCode,
    { rejectWithValue }
  ) => {
    try {
      return await userServices.updatePasswordWithCode(updatePasswordWithCode);
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

const CredentialsStore = createSlice({
  name: "credentials-store",
  initialState,
  reducers: {
    setForgotPassword(state, action) {
      state.forgotPassword = action.payload as boolean;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordAction.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(forgotPasswordAction.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(forgotPasswordAction.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(updatePasswordWithCodeAction.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(updatePasswordWithCodeAction.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(updatePasswordWithCodeAction.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export const { setForgotPassword } = CredentialsStore.actions;

export default CredentialsStore.reducer;
