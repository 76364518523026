import { createSlice } from "@reduxjs/toolkit";

import {
  CheckoutState,
  CheckoutSteps,
  SortStepPosition,
} from "./checkout.interfaces";

import checkoutActions from "./checkout.actions";

const initialState: CheckoutState = {
  paymentMethods: null,
  userInformation: null,
  showPurchaseConfirmation: false,

  helpers: {
    hideStepAddres: false,
    currenStep: CheckoutSteps.USER_INFORMATION,
    sortStepPosition: SortStepPosition.USER_INFORMATION,
  },

  addressInformation: {
    address: null,
    deliveryMethod: null,
    addToUserAddresses: false,
  },
};

const CheckoutStore = createSlice({
  initialState,
  name: "checkout-store",
  reducers: checkoutActions,
});

export const {
  setCheckoutStep,
  resetCheckoutState,
  setUserInformation,
  setPaymentMethodType,
  toggleStepAddresView,
  setShippingInformation,
  toogleConfirmPurchaseView,
  toggleUseFranchiseInPurchase,
} = CheckoutStore.actions;

export default CheckoutStore.reducer;
