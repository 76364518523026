import { FC } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";

import brandsImage from "assets/images/brands.png";
import skyPostalLogo from "assets/images/skypostal.png";
import SocialMediaIcons from "components/atoms/social-media-icons";

export const PaymentMethodsAssociated: FC = () => {
  const s = useStyles();

  return (
    <Box className={s.box}>
      <Typography color="text.disabled" className={s.title}>
        Métodos de pago
      </Typography>
      <Box>
        <Box
          component="img"
          src={brandsImage}
          alt="skypostal-logo"
          sx={{ mt: 2 }}
          style={{ width: "100%", objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
};

export const PartnersAssociated: FC = () => {
  const s = useStyles();

  return (
    <Box className={s.box}>
      <Typography color="text.disabled" className={s.title}>
        Partner
      </Typography>

      <Box>
        <Box
          component="img"
          src={skyPostalLogo}
          alt="skypostal-logo"
          style={{ width: 120, objectFit: "contain" }}
        />
      </Box>
    </Box>
  );
};

export const CopyrightAndSocialMedia: FC = () => {
  const s = useStyles();

  const goToExternalLink = () => {
    window.open("https://www.spacedev.uy/", "_blank");
  };

  return (
    <>
      <SocialMediaIcons sx={s.icons} />

      <Typography color="text.disabled" className={s.text}>
        © 2021{" "}
        <Typography component="span" color="text.secondary">
          PuntoMall®{" "}
        </Typography>
        by{" "}
        <Typography
          component="span"
          onClick={goToExternalLink}
          sx={{ cursor: "pointer" }}
        >
          SpaceDev
        </Typography>
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    width: 240,
    paddingLeft: theme.spacing(3),
    margin: `${theme.spacing(3)} 0px`,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      paddingLeft: theme.spacing(0),
    },
  },

  text: {
    paddingLeft: theme.spacing(3),
    margin: `${theme.spacing(1.5)} 0px`,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      textAlign: "center",
    },
  },

  title: {
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2),
    },
  },

  box: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
