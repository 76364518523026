import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getBestSellersProducts } from "store/statistics.slice";
import { parseProductInformation, scrollTop } from "utils/commons";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import CustomLayout from "components/atoms/simple-layout";
import ProductCard from "components/molecules/product-card";
import BackdropLoader from "components/atoms/backdrop-loader";
import EmptyProductList from "components/molecules/empty-product-list";

const BestSellersProductsView: FC = () => {
  const dispatch = useAppDispatch();

  const { bestSellers, isLoading } = useAppSelector(
    (state) => state.statistics
  );

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    if (!bestSellers.length) dispatch(getBestSellersProducts());
  }, [dispatch, bestSellers.length]);

  if (isLoading) return <BackdropLoader />;

  return (
    <CustomLayout maxWidth="xl">
      <Box mt={8} />
      <Typography variant="h5" color="text.primary" mb={2}>
        Productos más vendidos
      </Typography>

      {bestSellers.length ? (
        <Grid container>
          {bestSellers.map((statistic) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={statistic.id}>
              <ProductCard
                product={parseProductInformation(
                  statistic.product,
                  statistic.productId
                )}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyProductList />
      )}
    </CustomLayout>
  );
};

export default BestSellersProductsView;
