import { ApartmentType } from "interfaces/shipping-addresses.interfaces";
import { FrancheseType } from "interfaces/shopping-cart.interfaces";

export interface CheckoutState {
  helpers: {
    hideStepAddres: boolean;
    currenStep: CheckoutSteps;
    sortStepPosition: SortStepPosition;
  };

  useFranchise?: FrancheseType;
  showPurchaseConfirmation: boolean;
  userInformation: null | UserInformation;
  paymentMethods: null | PaymentMethodTypes;

  addressInformation: {
    addToUserAddresses: boolean;
    address: null | ShippingAddress;
    deliveryMethod: null | DeliveryMethods;
  };
}

export interface ShippingAddress {
  id?: string;
  userId?: string;

  address: string;
  zipCode: string;
  phoneNumber: string;
  apartment: ApartmentType;

  doorNumber?: string;
  neighborhood?: string;
  observations?: string;
}

export interface UserInformation {
  document: string;
  firstName: string;
  lastName: string;
  birthday: Date;
  middleName: string;
  secondLastName: string;
}

export enum CheckoutSteps {
  ADDRESS = "shippingAddress",
  PAYMENT_METHODS = "paymentMethods",
  DELIVERY_METHOD = "deliveryMethod",
  USER_INFORMATION = "userInformation",
}

export enum SortStepPosition {
  USER_INFORMATION = 1,
  DELIVERY_METHOD = 2,
  ADDRESS = 3,
  PAYMENT_METHODS = 4,
}

export enum DeliveryMethods {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}

export enum PaymentMethodTypes {
  ABITAB = "abitab",
  SISTARBANC_OCA = "sistarbanc_oca",
  SISTARBANC_BBVA = "sistarbanc_bbva",
  SISTARBANC_VISA = "sistarbanc_visa",
  SISTARBANC_ITAU = "sistarbanc_itau",
  SISTARBANC_HSBC = "sistarbanc_hsbc",
  SISTARBANC_BROU = "sistarbanc_brou",
  SISTARBANC_HERITAGE = "sistarbanc_heritage",
  SISTARBANC_SANTANDER = "sistarbanc_santander",
  SISTARBANC_SCOTIABANK = "sistarbanc_scotiabank",
}
