import { FC } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const EmptyFavoriteList: FC = () => {
  return (
    <Box
      display="flex"
      height="40vh"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="body1" align="center">
        Todavía no tienes productos en tus favoritos
      </Typography>

      <Typography align="center" fontSize={12}>
        ¡Empezá a descubrir todo lo que tenemos para vos!
      </Typography>
    </Box>
  );
};

export default EmptyFavoriteList;
