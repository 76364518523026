import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/system";
import { Grid, Paper } from "@mui/material";

import CustomButton from "components/atoms/custom-button";
import SnackbarMessage from "components/atoms/custom-snackbar";
import FavoriteCardInformation from "./FavoriteCardInformation";

import { getCartState } from "store/cart/cart.selectors";
import { IProduct } from "interfaces/products.interfaces";
import { removeFavorite } from "store/favorites/favorites.actions";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { addItemToCart, updateCartItem } from "store/cart/cart.actions";

import { useFavoriteStyles } from "./styles";
import notFoundImage from "assets/images/image_not_found.png";

interface IProps {
  product: IProduct;
  productId: string;
  favoriteId: string;
}

/**
 * Component that displays a favorite card with two
 * options (add item to cart or delete item from favorites)
 * @param {IProps} props
 * @example
 * return <FavoriteCard product={product} key={id} favoriteId={id} />
 */

const FavoriteCard: FC<IProps> = (props) => {
  const { product, favoriteId, productId } = props;

  const {
    asin,
    price,
    mainImage,
    salePrice,
    countReview,
    retailPrice,
    priceSaving,
    imageUrlList,
    productTitle,
    productRating,
  } = product;

  const s = useFavoriteStyles();
  const dispatch = useAppDispatch();

  const [successMessage, setSuccessMessage] = useState(false);
  const { shoppingCart } = useAppSelector(getCartState);

  const addToCart = () => {
    const found = shoppingCart.items?.filter((item) => item.productId === asin);

    if (found?.length)
      dispatch(
        updateCartItem({
          cartItemId: found[0].id,
          quantity: Number(found[0].quantity) + 1,
        })
      );
    else
      dispatch(
        addItemToCart({
          quantity: 1,
          price: price,
          productId: asin,
          title: productTitle,
          image: mainImage?.imageUrl || notFoundImage,
        })
      );

    setSuccessMessage(true);
  };

  const url = `/products?asin=${productId}`;

  const onRemove = () => dispatch(removeFavorite(favoriteId));

  const favoriteImage =
    mainImage?.imageUrl || (imageUrlList && imageUrlList[0]) || notFoundImage;

  return (
    <Paper elevation={3} sx={{ p: 2, my: 2 }}>
      <Grid container>
        <Grid item xs={5} md={1.5}>
          <Box
            to={url}
            component={Link}
            sx={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              width={96}
              height={96}
              component="img"
              className={s.img}
              src={favoriteImage}
              sx={{
                objectFit: "contain",
                border: "1px solid gray",
                cursor: "pointer",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6} md={7}>
          <FavoriteCardInformation
            asin={productId}
            title={productTitle}
            salePrice={salePrice}
            countReview={countReview}
            price={price || salePrice}
            productRating={productRating}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3.5}
          sx={{ px: { md: 4 } }}
          className={s.btnContainer}
        >
          <CustomButton
            variant="secondary"
            onClick={addToCart}
            text="Agregar al carrito"
            disabled={!price && !salePrice && !retailPrice && !priceSaving}
            sx={{ width: { xs: "100%" } }}
          />

          <CustomButton
            onClick={onRemove}
            variant="grayWithoutBorder"
            text="Eliminar de la lista"
          />
        </Grid>
      </Grid>

      <SnackbarMessage
        open={successMessage}
        type={SnackbarType.success}
        setOpen={setSuccessMessage}
        message="Producto agregado correctamenteal carrito"
      />
    </Paper>
  );
};

export default FavoriteCard;
