import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import theme from "styles/muiTheme";

interface IProps {
  name: string;
  rules?: any;
  label?: string;
  disabled?: boolean;
  defaultValue?: any;
  error?: { message: string };
  control: Control<FieldValues, object>;
  type?: React.InputHTMLAttributes<unknown>["type"];
}

/**
 * A custom input based on MUI TextField and integrated with react-hook-form
 * @param {IProps} props
 * @example
 *  <Input
      name="fullName"
      control={control}
      error={errors.fullName}
      label="Nombre y Apellido"
      rules={{ required: "Campo obligatorio" }}
    />
 */

const Input: FC<IProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: props.name,
    rules: props.rules,
    control: props.control,
    defaultValue: props.defaultValue ? props.defaultValue : "",
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const getType = () => {
    if (!props.type) return "text";
    if (props.type === "password" && showPassword) return "text";
    return props.type;
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ my: 0.5 }}>
      <Typography
        variant="body2"
        lineHeight="24px"
        sx={{ mb: 0.25 }}
        color="text.secondary"
      >
        {props.label}
      </Typography>

      <TextField
        sx={{
          backgroundColor: theme.palette.secondary.light,
        }}
        id={name}
        fullWidth
        name={name}
        size="small"
        inputRef={ref}
        onBlur={onBlur}
        type={getType()}
        value={value || ""}
        onChange={onChange}
        disabled={props.disabled}
        error={!!props.error?.message}
        helperText={props.error?.message}
        InputProps={{
          endAdornment:
            props.type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  sx={{ color: theme.palette.text.primary }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
      />
    </Box>
  );
};

export default Input;
