import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { IExternalTrackingEvent } from "interfaces/tracking.interfaces";
import { FC } from "react";
import TrackingItem from "./TrackingItem";

interface IProps {
  items: IExternalTrackingEvent[];
}

const TrackingItemsList: FC<IProps> = (props) => {
  const { items } = props;

  return (
    <Box>
      <Grid container spacing={{ xs: 1, md: 1 }} justifyContent="center">
        {items.map((item) => (
          <TrackingItem key={item.description} {...item} />
        ))}
      </Grid>
    </Box>
  );
};

export default TrackingItemsList;
