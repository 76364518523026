import { FC } from "react";
import { Alert, AlertTitle } from "@mui/material";

interface IProps {
  title?: string;
  message: string;
  severity: "error" | "info" | "success" | "warning";
}

const CustomAlert: FC<IProps> = (props) => {
  const { message, severity, title } = props;

  return (
    <Alert severity={severity} sx={{ width: { md: "60%", xs: "100%" }, m: 0 }}>
      {title && <AlertTitle>{title}</AlertTitle>}

      {message}
    </Alert>
  );
};

export default CustomAlert;
