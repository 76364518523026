import { Box } from "@mui/system";
import { makeStyles, useTheme } from "@mui/styles";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Container, Theme, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";
import { setKeywordSearch } from "store/globals.slice";

import {
  fetchKeywordSearch,
  fetchKeywordSearchByUser,
} from "store/keywords-search.slice";

import Header from "components/organisms/header";
import CartIcon from "components/atoms/cart-icon";
import SwipeableMobileMenu from "../swipable-menu";
import CustomLogo from "components/atoms/custom-logo";
import SearchBar from "components/molecules/search-bar";
import AuthButton from "components/molecules/auth-button";
import FavoritesIcon from "components/atoms/favorite-icon";
import CurrencyButton from "components/molecules/currency-button";

const NavBar: FC = () => {
  const s = useStyles();
  const navigate = useNavigate();
  const theme: Theme = useTheme();
  const dispatch = useAppDispatch();

  const [toSearch, setToSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const upToMiddleScreenSize = useMediaQuery(theme.breakpoints.up("md"));

  const { keywords } = useAppSelector((state) => state.search);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  const onPress = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.type === "click") {
      setToSearch(true);
    }
  };

  const searchProduct = useCallback(() => {
    if (searchValue) {
      dispatch(setKeywordSearch(searchValue));
      navigate(`/search?amz=${searchValue}&page=1`);
    }
  }, [dispatch, navigate, searchValue]);

  useEffect(() => {
    if (toSearch) {
      searchProduct();
      setToSearch(false);
    }
  }, [toSearch, searchProduct]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      dispatch(fetchKeywordSearch(searchValue)).then(() => {
        if (isLoggedIn) dispatch(fetchKeywordSearchByUser(searchValue));
      });
    }
  }, [searchValue, dispatch, isLoggedIn]);

  if (upToMiddleScreenSize)
    return (
      <>
        <Container className={s.root}>
          <Box className={s.img}>
            <CustomLogo width={90} />
          </Box>

          <SearchBar
            onPress={onPress}
            options={keywords}
            value={searchValue}
            onChange={onChange}
            onClick={searchProduct}
            onSetValue={setSearchValue}
          />

          <CurrencyButton />
          <AuthButton />
          <FavoritesIcon />
          <CartIcon />
        </Container>

        <Header />
      </>
    );

  return (
    <>
      <Container className={s.root}>
        <MenuIcon
          fontSize="large"
          onClick={() => setOpenMobileMenu(!openMobileMenu)}
          sx={{ cursor: "pointer", color: theme.palette.background.default }}
        />

        <SwipeableMobileMenu
          open={openMobileMenu}
          setOpen={setOpenMobileMenu}
        />

        <Box maxWidth="md" className={s.img}>
          <CustomLogo width={86} />
        </Box>
        <CartIcon />
      </Container>

      <Container className={s.mobileRoot}>
        <SearchBar
          onPress={onPress}
          options={keywords}
          value={searchValue}
          onChange={onChange}
          onClick={searchProduct}
          onSetValue={setSearchValue}
        />
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    minWidth: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1758FF",
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(1)} 0`,
      justifyContent: "space-around",
    },
  },

  mobileRoot: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#5182FF",
  },

  img: {
    [theme.breakpoints.up("md")]: { marginRight: theme.spacing(6) },
    [theme.breakpoints.down("md")]: { marginTop: theme.spacing(1) },
  },
}));

export default NavBar;
