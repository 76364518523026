import { scrollTop } from "utils/commons";
import { useNavigate } from "react-router";
import { FC, Fragment, useEffect } from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";

import LoginCard from "./components/LoginCard";
import GoogleCard from "./components/GoogleCard";
import FacebookCard from "./components/FacebookCard";
import CustomButton from "components/atoms/custom-button";
import ForgotPasswordModal from "pages/forgot-password/components/ForgotPasswordModal";

const SignInPage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Fragment>
      <LoginCard />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Divider sx={{ marginBottom: 3, marginTop: 3 }}>
          <Box width={180}>
            <Typography fontSize={14} fontWeight={500} color="#777577">
              O
            </Typography>
          </Box>
        </Divider>

        <Grid container columns={12} justifyContent="center" marginBottom={2}>
          <Grid item xs={12} sm={10} md={8}>
            <GoogleCard />
          </Grid>

          <Grid item xs={12} sm={10} md={8}>
            <FacebookCard />
          </Grid>
        </Grid>

        <Divider>
          <Box width={180}>
            <Typography fontSize={14} fontWeight={500} color="#777577">
              ¿No tienes una cuenta?
            </Typography>
          </Box>
        </Divider>

        <CustomButton
          variant="grey"
          text="Regístrate"
          sx={{ marginTop: 2, height: "48px" }}
          onClick={() => navigate("/auth/signup")}
        />
      </Box>

      <ForgotPasswordModal />
    </Fragment>
  );
};

export default SignInPage;
