import { FC } from "react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";

import { Badge, useTheme } from "@mui/material";
import { FavoriteBorder as FavoriteBorderIcon } from "@mui/icons-material";

/**
 * Component for showing favorite icon and redirect to favorite (if user is logged in)
 * @example
 * return <FavoritesIcon />
 */

const FavoritesIcon: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const goToFavorites = () => navigate("/favorites");

  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  if (!isLoggedIn) return null;

  return (
    <Badge onClick={goToFavorites} sx={{ ml: 4, cursor: "pointer" }}>
      <FavoriteBorderIcon
        fontSize="large"
        sx={{ color: theme.palette.background.default }}
      />
    </Badge>
  );
};

export default FavoritesIcon;
