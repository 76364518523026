import { FC } from "react";
import theme from "styles/muiTheme";
import { Box, FormControlLabel, Grid, Radio } from "@mui/material";

interface IPaymentMethodRadioProps {
  value: string;
  image: string;
  selected: string;
}

const PaymentMethodRadio: FC<IPaymentMethodRadioProps> = ({
  value,
  image,
  selected,
}) => {
  const getStyles = () => {
    if (selected !== value) return {};

    return {
      backgroundColor: "rgba(251, 130, 0, 0.05)",
      borderColor: theme.palette.secondary.main,
    };
  };

  return (
    <Grid item xs={1}>
      <FormControlLabel
        labelPlacement="top"
        label={
          <Box marginBottom="-4px" component="img" src={image} alt="VISA" />
        }
        value={value}
        sx={{
          padding: "12px",
          paddingBottom: "3px",
          borderRadius: "8px",
          borderWidth: "2px",
          border: "solid",
          borderColor: "#EEEEEE",
          marginLeft: 0,
          marginRight: "8px",
          ...getStyles(),
        }}
        control={
          <Radio
            sx={{
              "&.Mui-checked": {
                color: theme.palette.secondary.main,
              },
            }}
          />
        }
      />
    </Grid>
  );
};

export default PaymentMethodRadio;
