import { createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { getOffersList } from "./offers.actions";
import { IOffer } from "interfaces/offers.interfaces";
import { RequestError } from "interfaces/errors.interfaces";

interface IOfferSlice {
  offers: IOffer[];
  isFetched: boolean;
  isLoading: boolean;
  error: RequestError | null;
}

const initialState: IOfferSlice = {
  error: null,
  offers: [],
  isFetched: false,
  isLoading: false,
};

const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOffersList.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isFetched = true;
        state.offers = action.payload as IOffer[];
      })
      .addCase(getOffersList.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getOffersList.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isFetched = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export default offerSlice.reducer;
