import axios, { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { SignupDto } from "interfaces/user.interfaces";
import { ILogin, ILoginReturn } from "interfaces/auth.interfaces";

export const AuthServices = {
  /**
   * Register a user into puntoMall API using email, fullName and password.
   * @param {SignupDto} signupDto
   * @returns void
   */
  signupPuntoMallAPI: async (signupDto: SignupDto): Promise<void> => {
    try {
      const response: AxiosResponse<any> = await puntoMallAPI.post(
        "/auth/signup",
        signupDto
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * A function that makes axios request to puntoMall API (/auth) and get a jwt token
   * @param {String} email
   * @param {String} password
   * @returns {ILogin} The access token
   */
  login: async (email: string, password: string): Promise<ILoginReturn> => {
    try {
      const token: AxiosResponse<ILogin> = await puntoMallAPI.post(
        "auth/signin",
        { email, password }
      );

      localStorage.setItem("auth", token.data.accessToken);

      return token.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw parseAxiosErrorObject(error.response?.data as any);
      }

      throw parseAxiosErrorObject({
        message: "Ha ocurrido un error por favor intentar nuevamente",
        statusCode: 500,
      });
    }
  },

  /**
   * A function that makes axios request to puntoMall API (/auth/account_verification) and validate email
   * @param {String} validationCode
   * @returns {ILogin} The access token
   */
  validateEmail: async (validationCode: string): Promise<ILoginReturn> => {
    try {
      const token: AxiosResponse<ILogin> = await puntoMallAPI.post(
        "/auth/account_verification",
        {
          code: validationCode,
        }
      );

      localStorage.setItem("auth", token.data.accessToken);
      return token.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw parseAxiosErrorObject(error.response?.data as any);
      }

      throw parseAxiosErrorObject({
        message: "Ha ocurrido un error por favor intentar nuevamente",
        statusCode: 500,
      });
    }
  },

  /**
   * A function that makes axios request to puntoMall API (/auth/account_verification) and validate email
   * @param {String} email
   * @returns {ILogin} The access token
   */
  genValidationCode: async (email: string): Promise<{ message: string }> => {
    try {
      const response: AxiosResponse<{ message: string }> =
        await puntoMallAPI.post("/auth/gen-validation-code", {
          email,
        });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw parseAxiosErrorObject(error.response?.data as any);
      }

      throw parseAxiosErrorObject({
        message: "Ha ocurrido un error por favor intentar nuevamente",
        statusCode: 500,
      });
    }
  },

  /**
   * Login or register with facebook
   * @param accessToken The facebook accessToken
   * @returns An accessToken
   */
  async loginOrRegisterWithFacebook(
    accessToken: string
  ): Promise<ILoginReturn> {
    try {
      const response: AxiosResponse<ILogin> = await puntoMallAPI.post(
        "/auth/facebook",
        {
          accessToken: accessToken,
        }
      );

      localStorage.setItem("auth", response.data.accessToken);
      return response.data;
    } catch (error) {
      throw parseAxiosErrorObject({
        message: "Ha ocurrido un error por favor intentar nuevamente",
        statusCode: 500,
      });
    }
  },

  /**
   * Login or register with google
   * @param accessToken The google accessToken
   * @returns An accessToken
   */
  async loginOrRegisterWithGoogle(accessToken: string): Promise<ILoginReturn> {
    try {
      const response: AxiosResponse<ILogin> = await puntoMallAPI.post(
        "/auth/google",
        {
          accessToken: accessToken,
        }
      );

      localStorage.setItem("auth", response.data.accessToken);
      return response.data;
    } catch (error) {
      throw parseAxiosErrorObject({
        message: "Ha ocurrido un error por favor intentar nuevamente",
        statusCode: 500,
      });
    }
  },
};
