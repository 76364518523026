import { FC } from "react";
import { useAppDispatch } from "hooks";
import GoogleLogin from "react-google-login";
import { loginOrRegisterWithGoogle } from "store/user.slice";

import SocialMediaButton from "components/atoms/social-media-button";

const GoogleCard: FC = () => {
  const dispatch = useAppDispatch();

  const onSuccess = async (e: any) => {
    await dispatch(loginOrRegisterWithGoogle(e.accessToken));
  };

  return (
    <GoogleLogin
      clientId={
        process.env.REACT_APP_GOOGLE_CLIENT_ID ??
        "324359881714-lv3avbh34ht6dtn40u4v13kig9j8mkd5.apps.googleusercontent.com"
      }
      onSuccess={onSuccess}
      cookiePolicy={"single_host_origin"}
      render={({ onClick, disabled }) => (
        <SocialMediaButton
          variant="google"
          onClick={onClick}
          disabled={disabled}
          sx={{ width: "100%" }}
          text="Continuar con Google"
        />
      )}
    />
  );
};

export default GoogleCard;
