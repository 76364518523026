import "styles/global.css";

import { FC } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/private-theming";

import Routes from "routes";
import { store } from "store";
import theme from "styles/muiTheme";

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
