import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { GlobalServices } from "services/global.services";
import { RequestError } from "interfaces/errors.interfaces";
import { CurrencyEntity } from "interfaces/globals.interface";
import { Currency, Locale } from "interfaces/shopping-cart.interfaces";

interface IConfigState {
  locale: Locale;
  currency: Currency;
  isLoading: boolean;
  isFetchched: boolean;
  searchHistory: string[];
  error: RequestError | null;
  cotization: CurrencyEntity;
}

const initialState: IConfigState = {
  error: null,
  isLoading: false,
  isFetchched: false,
  locale: Locale.es,
  searchHistory: [],
  currency: Currency.usd,
  cotization: {
    id: "",
    base: "UYU",
    BR: { buy: 0, sell: 0 },
    EUR: { buy: 0, sell: 0 },
    USD: { buy: 0, sell: 0 },
    ARG: { buy: 0, sell: 0 },
  },
};

/**
 * Get current UYU cotizationfor calculate products prices and fees
 */
export const getDailyCotization = createAsyncThunk(
  "global-config/getDailyCotization",
  async (_, thunkApi) => {
    try {
      return await GlobalServices.getCurrentCotization();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const GlobalConfigSlice = createSlice({
  name: "global-config",
  initialState,
  reducers: {
    getGlobalConfig(state) {
      const config = localStorage.getItem("global-config");

      if (config) {
        const lsConfig: IConfigState = JSON.parse(config);
        state.locale = lsConfig.locale;
        state.currency = lsConfig.currency;
        state.searchHistory = lsConfig.searchHistory;
      }
    },

    setCurrency(state, action: PayloadAction<Currency>) {
      state.currency = action.payload;
      localStorage.setItem("global-config", JSON.stringify(state));
    },

    setLocale(state, action: PayloadAction<Locale>) {
      state.locale = action.payload;
      localStorage.setItem("global-config", JSON.stringify(state));
    },

    setKeywordSearch(state, action: PayloadAction<string>) {
      const item = action.payload.trim() as string;

      if (!state.searchHistory.includes(item)) {
        if (state.searchHistory.length > 10) state.searchHistory.pop();

        state.searchHistory.unshift(item);
        localStorage.setItem("global-config", JSON.stringify(state));
      }
    },

    cleanSearchHistory(state) {
      state.searchHistory = [];
      localStorage.setItem("global-config", JSON.stringify(state));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDailyCotization.fulfilled, (state, action: any) => {
        state.error = null;
        state.isLoading = false;
        state.isFetchched = true;
        state.cotization = action.payload;
      })
      .addCase(getDailyCotization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDailyCotization.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export const {
  setLocale,
  setCurrency,
  getGlobalConfig,
  setKeywordSearch,
  cleanSearchHistory,
} = GlobalConfigSlice.actions;

export default GlobalConfigSlice.reducer;
