import { FC, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { MainImage } from "interfaces/products.interfaces";
import ImageMagnify from "components/molecules/image-magnify";
import notFoundImage from "assets/images/image_not_found.png";

interface ImageListProps {
  images: string[];
  current: string;
  onClick: (url: string) => void;
}

interface IProps {
  mainImage: MainImage;
  imageUrlList: string[];
}

const ProductImages: FC<IProps> = (props) => {
  const { imageUrlList, mainImage } = props;

  const [currentImg, setCurrentImg] = useState(notFoundImage);

  useEffect(() => {
    if (mainImage) setCurrentImg(mainImage.imageUrl);

    if (
      imageUrlList &&
      Array.isArray(imageUrlList) &&
      imageUrlList.length > 0
    ) {
      setCurrentImg(imageUrlList[0]);
    }
  }, [mainImage, imageUrlList]);

  return (
    <Box sx={{ display: "flex" }}>
      <ImageList
        current={currentImg}
        onClick={setCurrentImg}
        images={imageUrlList}
      />

      <ImageMagnify src={currentImg} />
    </Box>
  );
};

const ImageList: FC<ImageListProps> = (props) => {
  const s = useStyles();

  return (
    <Box mr={1}>
      {props.images &&
        props.images.slice(0, 7).map((url, index) => (
          <Box
            key={index}
            onMouseEnter={() => props.onClick(url)}
            className={`${s.stack} ${
              props.current === url ? s.selected : undefined
            }`}
          >
            <img src={url} alt={`pic-${index}`} className={s.thumbnail} />
          </Box>
        ))}
    </Box>
  );
};

export default ProductImages;

const useStyles = makeStyles((theme: Theme) => ({
  thumbnail: { width: 54, height: 54, objectFit: "contain" },

  stack: {
    margin: 6,
    width: 60,
    height: 60,
    padding: 3,
    borderRadius: 6,

    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "center",
    border: `1px solid lightgrey`,
  },

  selected: { border: `1px solid ${theme.palette.secondary.main}` },
}));
