import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme, useTheme } from "@mui/system";
import { Container, useMediaQuery } from "@mui/material";

import CustomLogo from "components/atoms/custom-logo";
import CurrencyButton from "components/molecules/currency-button";

const SimpleNavbar: FC = () => {
  const styles = useStyles();
  const { breakpoints } = useTheme();
  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));

  return (
    <Container className={styles.container}>
      <Box
        maxWidth="lg"
        sx={{
          width: "100%",
          display: "flex",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomLogo width={86} />

        <CurrencyButton isMobile={downToMiddleScreenSize} />
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: 88,
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1758FF",
    paddingY: theme.spacing(0.5),
  },
}));

export default SimpleNavbar;
