import { makeStyles } from "@mui/styles";
import { CSSProperties, FC, ReactChild } from "react";

import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";

interface IProps {
  value: string;
  options: IOption[];
  disabled?: boolean;
  sx?: CSSProperties;
  sxSelecet?: CSSProperties;
  width?: string | number;
  defaultValue: string;
  sxItem?: CSSProperties;
  handleChange: (e: SelectChangeEvent) => void;
}

export interface IOption {
  value: string;
  disabled?: boolean;
  onClick?: () => void;
  label: string | ReactChild;
}

/**
 *  Component based on MUI for create a custom select input with styles applied.
 * @param {String} value
 * @param {IOption[]} options
 * @param {Number} props (optional)
 * @param {(e: SelectChangeEvent) => void} handleChange
 * @example return (
  <CustomSelect value={14} options={[]} handleChange={() => console.log("Hello World")}/>
)
 */
const CustomSelect: FC<IProps> = (props) => {
  const {
    sx,
    value,
    width,
    sxItem,
    options,
    disabled,
    sxSelecet,
    defaultValue,
    handleChange,
  } = props;

  const s = useStyles();

  const getValue = () => {
    const found = options.find((option) => option.value === value);
    return !found ? defaultValue : value;
  };

  return (
    <FormControl
      variant="outlined"
      className={s.form}
      sx={{ mx: 1, width: width || 110, ...sx }}
    >
      <Select
        value={getValue()}
        disabled={disabled}
        className={s.select}
        onChange={handleChange}
        style={{ ...sxSelecet }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={option.onClick}
            disabled={option.disabled}
            selected={option.value === value}
            sx={{
              px: 3,
              py: 1.5,
              display: option.value === "welcome" ? "none" : "flex",
              ...sxItem,
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: any) => ({
  select: {
    p: 0,
    height: "6ch",
    color: theme.palette.background.default,
  },
  form: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset, &.Mui-focused fieldset": {
        border: `0.5px solid ${theme.palette.background.default}`,
      },
      "& fieldset": {
        border: `0.5px solid rgba(255, 255, 255, 0.4)`,
      },
    },
    "& .MuiSelect-icon": {
      color: theme.palette.background.default,
    },
  },
}));

export default CustomSelect;
