import { FC, Fragment } from "react";
import { useAppSelector } from "hooks";

import { Alert } from "@mui/material";
import { feesAlertsSelector } from "store/fees/fees.selectors";

const AlertAmountExceded: FC = () => {
  const {
    bigPriceDetected,
    excededQuantityLimit,
    excededLimitImportationAmount,
  } = useAppSelector(feesAlertsSelector);

  const messages = [];

  if (excededQuantityLimit)
    messages.push(
      <Alert
        severity="error"
        sx={{ width: { md: "50%", xs: "100%" }, mx: 0, my: 1 }}
      >
        Si compras más de 5 productos iguales por orden, tu pedido será
        procesado como compra de importación y no como franquicia.
      </Alert>
    );

  if (bigPriceDetected)
    messages.push(
      <Alert
        severity="warning"
        sx={{ width: { md: "50%", xs: "100%" }, mx: 0, my: 1 }}
      >
        Atención: el peso de alguno de tus paquetes podría ser muy elevado o
        erróneo. Ten en cuenta que esto se reflejará en los costos de envío.
      </Alert>
    );

  if (excededLimitImportationAmount)
    messages.push(
      <Alert
        severity="error"
        sx={{ width: { md: "50%", xs: "100%" }, mx: 0, my: 1 }}
      >
        Te informamos que tu compra excede el monto máximo permitido para
        consumir una franquicia, por lo que tendrás recargos adicionales. (Más
        información en el resumen del pedido).
      </Alert>
    );

  if (!messages.length) return null;

  return (
    <Fragment>
      {messages.map((message, i) => (
        <Fragment key={i}>{message}</Fragment>
      ))}
    </Fragment>
  );
};

export default AlertAmountExceded;
