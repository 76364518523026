import { Box, styled } from "@mui/system";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";

import {
  Search as SearchIcon,
  AccessTime as RecentIcon,
} from "@mui/icons-material/";

import {
  Theme,
  useTheme,
  TextField,
  Typography,
  Autocomplete,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";

import { KeywordSearch } from "interfaces/searchResults.interfaces";
import { fetchKeywordSearchByUser } from "store/keywords-search.slice";

interface IProps {
  value: string;
  options: KeywordSearch[];
  onPress: (e: any) => void;
  onClick: (e: any) => void;
  onChange: (e: any) => void;
  onSetValue: Dispatch<SetStateAction<string>>;
}

const CustomButton = (props: any) => (
  <ButtonUnstyled {...props} component={CustomButtonRoot} />
);

const SearchBar: FC<IProps> = (props) => {
  const { onChange, onClick, value, onPress, onSetValue, options } = props;

  const s = useStyles();
  const query = useQuery();
  const theme: Theme = useTheme();
  const dispatch = useAppDispatch();
  const upToMiddleScreenSize = useMediaQuery(theme.breakpoints.up("md"));

  const keyword = query.get("amz") as string;

  const { searchHistory } = useAppSelector((state) => state.globals);
  const { searchedByUser } = useAppSelector((state) => state.search);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchKeywordSearchByUser(""));
  }, [dispatch, isLoggedIn]);

  const allOptions = [
    ...searchedByUser.map((keyword) => ({
      name: keyword.name,
    })),
    ...searchHistory.map((keyword) => ({ name: keyword })),
    ...options.map((keyword) => ({
      name: keyword.name,
    })),
  ];

  const deleteDuplicates = (allOptions: { name: string }[]) => {
    const results: string[] = [];

    for (let i = 0; i < allOptions.length; i++) {
      const name = allOptions[i].name;
      if (!results.includes(name)) results.push(name);
    }

    return results;
  };

  const checkIfKeywordExistInLs = (keyword: string) =>
    searchedByUser.map((keyword) => keyword.name).includes(keyword) ||
    searchHistory.includes(keyword);

  return (
    <Autocomplete
      freeSolo
      id="autocomplete"
      disableClearable
      onChange={(e, valueE) => {
        onSetValue(valueE);
        onPress(e);
      }}
      autoSelect={false}
      clearIcon={<div />}
      autoHighlight={false}
      value={keyword ?? ""}
      options={deleteDuplicates(allOptions)}
      renderOption={(props, option) => (
        <Box
          {...props}
          onClick={(e: any) => {
            if (props.onClick) props.onClick(e);
            onSetValue(option);
          }}
          component="li"
          sx={{ color: "text.primary", "& > img": { flexShrink: 0 } }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {checkIfKeywordExistInLs(option) ? (
              <RecentIcon
                fontSize="small"
                sx={{ color: "text.primary", p: 0 }}
              />
            ) : (
              <SearchIcon
                fontSize="small"
                sx={{ color: "text.primary", p: 0 }}
              />
            )}

            <Typography sx={{ pl: 1 }}>{option}</Typography>
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          variant="outlined"
          onChange={onChange}
          className={s.input}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <CustomButton onClick={onClick} className={s.customBtn}>
                  <SearchIcon />

                  {upToMiddleScreenSize && (
                    <Typography className={s.btn}>Buscar</Typography>
                  )}
                </CustomButton>
              </InputAdornment>
            ),
          }}
          placeholder="Busca aquí por productos, marcas y más…"
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    border: "none",
    overflow: "hidden",
    backgroundColor: "#fff",
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("md")]: { width: "62ch" },
    [theme.breakpoints.down("md")]: { width: "90vw", margin: theme.spacing(1) },
  },
  btn: { fontWeight: 500, padding: `0 ${theme.spacing(1)}` },
  customBtn: { [theme.breakpoints.down("md")]: { padding: theme.spacing(1) } },
}));

const CustomButtonRoot = styled("span")`
  background-color: #ea7443;
  padding: 6px 12px;
  border-radius: 5px;
  color: #fff;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  display: flex;
  flex-direction: row;

  &:hover {
    border-color: rgba(0, 0, 0, 0);
    background-color: #da6433;
    color: #ededed;
  }
`;

export default SearchBar;
