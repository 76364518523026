import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import CustomLayout from "components/atoms/simple-layout";
import { useAppDispatch, useAppSelector } from "hooks";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { fetchTrackingByTrackingNumber } from "store/tracking.slice";
import TrackingItemsList from "./components/TrackingItemsList";

const Tracking: FC = () => {
  const dispatch = useAppDispatch();
  const [trackingNumber, setTrackNumber] = useState("");
  const [searched, setSearched] = useState(false);
  const tracking = useAppSelector((state) => state.tracking);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTrackNumber(e.target.value);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(fetchTrackingByTrackingNumber(trackingNumber));
    setSearched(true);
  };

  const displayResults = () => {
    if (searched && !tracking.trackingEvents.length) {
      return (
        <Typography textAlign="center" marginTop={20}>
          No encontramos tu pedido.
        </Typography>
      );
    }

    if (!searched) {
      return (
        <Typography textAlign="center" marginTop={20}>
          Rastrea tu pedido.
        </Typography>
      );
    }

    return <TrackingItemsList items={tracking.trackingEvents} />;
  };

  return (
    <CustomLayout mg={6}>
      <Grid container justifyContent="center">
        <Grid item>
          <Box
            component="form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => onSubmit(e)}
          >
            <div>
              <Input
                value={trackingNumber}
                onChange={(e) => onChange(e)}
                placeholder="Numero de tracking"
                required
              />
              <Button type="submit">Rastrear pedido</Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {tracking.isLoading ? (
            <Grid container justifyContent="center" marginTop={20}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            displayResults()
          )}
        </Grid>
      </Grid>
    </CustomLayout>
  );
};

export default Tracking;
