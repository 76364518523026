import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";
import { Container, Typography } from "@mui/material";
import { DashboardType } from "interfaces/dashboard.interfaces";

import Dashboard from "components/organisms/dashboard";
import UpdatePassword from "../account-settings/components/UpdatePassword";
import CardLayoutWithTittle from "components/molecules/card-layout-with-title";

const ProfileSecurityView: FC = () => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Dashboard current={DashboardType.security}>
      <Container maxWidth="md" sx={{ p: { xs: 0 } }}>
        <Typography fontSize={18} marginBottom={3}>
          Seguridad
        </Typography>

        <CardLayoutWithTittle title="Mi contraseña">
          <UpdatePassword />
        </CardLayoutWithTittle>
      </Container>
    </Dashboard>
  );
};

export default ProfileSecurityView;
