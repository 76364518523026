import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import Input from "components/atoms/input";
import CustomButton from "components/atoms/custom-button";
import SnackbarMessage from "components/atoms/custom-snackbar";

import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { updatePasswordWithCodeAction } from "store/credentials.slice";
import { passwordMessage, passwordRegex } from "utils/forms/validations";

interface IProps {
  code: string;
}

const ChangePasswordForm: FC<IProps> = (props) => {
  const { code } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [errornMsg, setErrorMsg] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState(false);
  const { error, isLoading } = useAppSelector((state) => state.credentials);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "La contraseña tiene que tener 8 o mas carácteres")
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage)
      .notOneOf(
        [Yup.ref("currentPassword")],
        "La contraseña no puede ser igual a la actual"
      ),
    confirmPassword: Yup.string()
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage)
      .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data: FieldValues) => {
    dispatch(
      updatePasswordWithCodeAction({
        code,
        newPassword: data.password,
      })
    ).then((response: any) => {
      if (response.error) {
        setErrorMsg(true);
        return;
      }
      setConfirmationMsg(true);

      reset();
      setTimeout(() => {
        navigate("/auth/signin", { replace: true });
      }, 3000);
    });
  };

  return (
    <>
      <Box padding={2}>
        <Typography fontSize={24} textAlign="center" marginBottom={3}>
          Cambiar contraseña
        </Typography>

        <Input
          type="password"
          name="password"
          control={control}
          error={errors.password}
          label="Nueva contraseña"
        />

        <Input
          type="password"
          control={control}
          name="confirmPassword"
          error={errors.confirmPassword}
          label="Confirmar contraseña"
        />

        <CustomButton
          variant="primary"
          loading={isLoading}
          disabled={isLoading}
          text="Cambiar contraseña"
          sx={{ width: "100%", mt: 2 }}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>

      {confirmationMsg && (
        <SnackbarMessage
          open={confirmationMsg}
          setOpen={setConfirmationMsg}
          type={SnackbarType.success}
          message={`Contraseña cambiada con éxito`}
          origin={{ vertical: "top", horizontal: "center" }}
        />
      )}

      {errornMsg && (
        <SnackbarMessage
          open={errornMsg}
          setOpen={setErrorMsg}
          type={SnackbarType.warning}
          origin={{ vertical: "top", horizontal: "center" }}
          message={
            error && error?.code === 400
              ? error.message
              : "Ha ocurrido un error inesperado"
          }
        />
      )}
    </>
  );
};

export default ChangePasswordForm;
