import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

interface ITrackingItem {
  date: string;
  description: string;
}

const TrackingItem: FC<ITrackingItem> = (props) => {
  const { date, description } = props;

  return (
    <Grid item sm={8}>
      <Box margin={1}>
        <Paper
          sx={{
            padding: 2,
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>Fecha: {date}</Typography>
          </Box>
          <Box>
            <Typography>Descripción: {description}</Typography>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};

export default TrackingItem;
