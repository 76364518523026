import { createSlice } from "@reduxjs/toolkit";
import {
  Currency,
  ICartItem,
  ILSShoppingCart,
} from "interfaces/shopping-cart.interfaces";
import { localStorageAPI } from "utils/localStorage";

const initialState: ILSShoppingCart = {
  total: 0,
  items: [],
  currency: Currency.usd,
};

const getTotal = (items: ICartItem[]) =>
  items.map((item) => item.price * item.quantity).reduce((a, b) => a + b, 0);

const LSShoppingCartSlice = createSlice({
  name: "ls-shopping-cart",
  initialState,
  reducers: {
    getLSCart(state) {
      const lsCart: any = localStorage.getItem("ls-shopping-cart");
      if (lsCart) {
        const newState = JSON.parse(lsCart);

        state.items = newState.items;
        state.total = newState.total;
        state.currency = newState.currency;
      }
    },

    updateLsCartItem(state, action) {
      const items = state.items.map((item) => {
        if (item.id !== action.payload.id) return item;
        item.quantity = action.payload.quantity;
        return item;
      });

      state.items = items;
      state.total = getTotal(state.items);
      localStorage.setItem("ls-shopping-cart", JSON.stringify(state));
    },

    addNewItemToLsCart(state, action) {
      state.items.push(action.payload);
      state.total = getTotal(state.items);
      localStorage.setItem("ls-shopping-cart", JSON.stringify(state));
    },

    removeItemToLsCart(state, action) {
      const cartItemId = action.payload;
      state.items = state.items.filter((item) => item.id !== cartItemId);
      state.total = getTotal(state.items);
      localStorage.setItem("ls-shopping-cart", JSON.stringify(state));
    },

    removeShoppingCartForMerge(state) {
      state.items = [];
      state.total = 0;

      localStorageAPI.removeItem("ls-shopping-cart");
    },
  },
});

export const {
  getLSCart,
  updateLsCartItem,
  removeItemToLsCart,
  addNewItemToLsCart,
  removeShoppingCartForMerge,
} = LSShoppingCartSlice.actions;

export default LSShoppingCartSlice.reducer;
