import { Alert, FormGroup, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/atoms/custom-button";
import Input from "components/atoms/input";
import { useAppDispatch, useAppSelector } from "hooks";
import { FC, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { genValidationCode } from "store/user.slice";

const ValidationCard: FC = () => {
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const { error } = useAppSelector((state) => state.userInformation);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FieldValues) => {
    dispatch(genValidationCode(data.email)).then((data: any) => {
      if (!data.error) setValidated(true);
    });
  };

  return (
    <Paper>
      <Box padding={5}>
        <FormGroup>
          <Typography
            fontSize={24}
            letterSpacing={1.5}
            textAlign="center"
            marginBottom={3}
          >
            Enviar código de verificación
          </Typography>

          {validated && (
            <Alert sx={{ m: 1 }} severity="success">
              Se envío el código al email correctamente.{" "}
            </Alert>
          )}

          {error && (
            <Alert sx={{ m: 1 }} severity="error">
              {error.message}
            </Alert>
          )}

          <Input
            name="email"
            control={control}
            error={errors.email}
            label="Correo Electrónico"
            rules={{
              required: "Campo obligatorio",
              pattern: {
                value: /^\S+@\S+$/,
                message: "Por favor ingrese un correo electrónico válido",
              },
            }}
          />

          <CustomButton
            onClick={handleSubmit(onSubmit)}
            text="Validar"
            variant="primary"
          />
        </FormGroup>
      </Box>
    </Paper>
  );
};

export default ValidationCard;
