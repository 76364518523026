import { FC } from "react";
import { setCurrency } from "store/globals.slice";
import { useAppDispatch, useAppSelector } from "hooks";
import { Currency } from "interfaces/shopping-cart.interfaces";

import CustomSelect from "components/atoms/custom-select";
import { SelectChangeEvent, Typography } from "@mui/material";

/**
 * Component that show a custom select with currency options (USD and UYU).
 * @example
 * return <CurrencyButton />
 */

interface IProps {
  isMobile?: boolean;
}

const CurrencyButton: FC<IProps> = (props) => {
  const { isMobile } = props;

  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.globals);

  const handleChange = (e: SelectChangeEvent) => {
    const val = e.target.value as Currency;
    dispatch(setCurrency(val));
  };

  const selectOption = [
    {
      value: Currency.usd,
      label: <Span text="USD" />,
    },
    {
      value: Currency.uyu,
      label: <Span text="UYU" />,
    },
  ];

  return (
    <CustomSelect
      value={currency}
      options={selectOption}
      handleChange={handleChange}
      defaultValue={Currency.usd}
      width={isMobile ? 110 : 120}
    />
  );
};

const Span = ({ text }: { text: string }) => (
  <>
    <Typography fontSize={{ md: 18, sx: 14 }} component="span" mr={1}>
      $
    </Typography>
    {text}
  </>
);

export default CurrencyButton;
