import { RootState } from "store";
import { setGenericError } from "utils/errors";
import { localStorageAPI } from "utils/localStorage";
import { removeShoppingCartForMerge } from "./lscart.slice";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILoginBody } from "interfaces/auth.interfaces";
import { RequestError } from "interfaces/errors.interfaces";

import {
  IUser,
  IHistory,
  SignupDto,
  IUserUpdate,
  ICreateHistory,
  IUpdatePassword,
} from "interfaces/user.interfaces";

import { AuthServices } from "services/auth.services";
import { userServices } from "services/user.services";
import { HistoryServices } from "services/history.services";
import { shoppingCartServices } from "services/shopping-cart.services";

interface IUserState {
  user?: IUser;
  isLoading: boolean;
  isLoggedIn: boolean;
  error: RequestError | null;
  isnNavigationFetched: boolean;
  navigationHistory: IHistory[];
}

const initialState: IUserState = {
  error: null,
  isLoading: false,
  isLoggedIn: false,
  navigationHistory: [],
  isnNavigationFetched: false,
};

/**
 * A function that is used to register a user with email, fullname and password
 * @param {SignupDto} signupDto
 */
export const signupUser = createAsyncThunk(
  "user/signupUser",
  async (signupDto: SignupDto, thunkApi) => {
    try {
      return await AuthServices.signupPuntoMallAPI(signupDto);
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that get all history records belongs to a user
 */
export const getAllHistoryRecords = createAsyncThunk(
  "user/getAllHistoryRecords",
  async (_, thunkApi) => {
    try {
      return await HistoryServices.getAllHistoryRecords();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that create a new history record belongs to a user
 * @param {string} productId
 */
export const createNewHistoryRecord = createAsyncThunk(
  "user/createNewHistoryRecord",
  async ({ productId, title }: ICreateHistory, thunkApi) => {
    try {
      await HistoryServices.createNewHistoryRecord(productId, title);
      return thunkApi.dispatch(getAllHistoryRecords());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that gets the current user info
 */
export const getProfile = createAsyncThunk("user/getProfile", async () => {
  return await userServices.getProfile();
});

/**
 * A function that login a user with email and password, then check if
 * shopping cart exists in local storage and merged
 */
export const login = createAsyncThunk(
  "user/login",
  async (body: ILoginBody, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      await AuthServices.login(body.email, body.password);

      if (state.lscart.items.length)
        return thunkApi.dispatch(mergeShoppingCartWithLS());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that login or register a user with facebook
 */
export const loginOrRegisterWithFacebook = createAsyncThunk(
  "user/loginOrRegisterWithFacebook",
  async (accessToken: string, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      await AuthServices.loginOrRegisterWithFacebook(accessToken);
      if (state.lscart.items.length) return dispatch(mergeShoppingCartWithLS());
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that login or register a user with google
 */
export const loginOrRegisterWithGoogle = createAsyncThunk(
  "user/loginOrRegisterWithGoogle",
  async (accessToken: string, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      await AuthServices.loginOrRegisterWithGoogle(accessToken);
      if (state.lscart.items.length) return dispatch(mergeShoppingCartWithLS());
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Validate email
 */
export const validateEmail = createAsyncThunk(
  "user/validateEmail",
  async (validationCode: string, { rejectWithValue }) => {
    try {
      return await AuthServices.validateEmail(validationCode);
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Gen code validation
 */
export const genValidationCode = createAsyncThunk(
  "user/genValidationCode",
  async (email: string, { rejectWithValue }) => {
    try {
      return await AuthServices.genValidationCode(email);
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that update the user info
 */
export const updateUser = createAsyncThunk(
  "user/update",
  async (userUpdate: IUserUpdate, { rejectWithValue, dispatch }) => {
    try {
      await userServices.updateUser(userUpdate);
      return dispatch(getProfile());
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that update the user password
 */
export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (updatePassword: IUpdatePassword, { rejectWithValue }) => {
    try {
      return await userServices.updatePassword(updatePassword);
    } catch (error) {
      return rejectWithValue(error as RequestError);
    }
  }
);

export const mergeShoppingCartWithLS = createAsyncThunk(
  "user/mergeShoppingCartWithLS",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;

      await shoppingCartServices.createOrMergeShoppingCart(
        state.lscart.items as any
      );

      thunkApi.dispatch(removeShoppingCartForMerge());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    cleanUserSliceErrors(state) {
      state.error = null;
    },

    logoutUser(state) {
      state.user = undefined;
      state.isLoggedIn = false;
      state.navigationHistory = [];
      localStorageAPI.removeItem("auth");
    },

    loginFromStorage(state) {
      const token = localStorageAPI.getItem("auth");
      const userInformation = localStorageAPI.parseJwtPayload(token) as IUser;

      state.error = null;
      state.isLoading = false;
      state.isLoggedIn = true;
      state.user = userInformation;
    },

    checkIfAuthTokenExists(state) {
      const token = localStorageAPI.getItem("auth");

      if (token) {
        const userInformation = localStorageAPI.parseJwtPayload(token) as IUser;
        state.isLoggedIn = true;
        state.user = userInformation;
      }
    },

    setFetchHistoryStatus: (state, action: PayloadAction<boolean>) => {
      state.isnNavigationFetched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(signupUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(signupUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(getAllHistoryRecords.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isnNavigationFetched = true;
        state.navigationHistory = action.payload as IHistory[];
      })
      .addCase(getAllHistoryRecords.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllHistoryRecords.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isnNavigationFetched = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(createNewHistoryRecord.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(createNewHistoryRecord.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(getProfile.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.error = null;
        state.isLoading = false;

        state.user = { ...action.payload };
      })
      .addCase(getProfile.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getProfile.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(updateUser.fulfilled, (state, action: any) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(updateUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(login.fulfilled, (state) => {
        const token = localStorageAPI.getItem("auth");
        const userInformation = localStorageAPI.parseJwtPayload(token) as IUser;

        state.error = null;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = userInformation;
      })
      .addCase(login.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(login.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(loginOrRegisterWithFacebook.fulfilled, (state, action) => {
        const token = localStorageAPI.getItem("auth");
        const userInformation = localStorageAPI.parseJwtPayload(token) as IUser;

        state.user = userInformation;
        state.error = null;
        state.isLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(loginOrRegisterWithFacebook.pending, (state, action) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(loginOrRegisterWithFacebook.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(loginOrRegisterWithGoogle.fulfilled, (state, action) => {
        const token = localStorageAPI.getItem("auth");
        const userInformation = localStorageAPI.parseJwtPayload(token) as IUser;

        state.user = userInformation;
        state.error = null;
        state.isLoading = false;
        state.isLoggedIn = true;
      })
      .addCase(loginOrRegisterWithGoogle.pending, (state, action) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(loginOrRegisterWithGoogle.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(validateEmail.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(validateEmail.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(validateEmail.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(genValidationCode.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(genValidationCode.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(genValidationCode.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(updatePassword.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(updatePassword.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(updatePassword.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export const {
  logoutUser,
  loginFromStorage,
  cleanUserSliceErrors,
  setFetchHistoryStatus,
  checkIfAuthTokenExists,
} = UserSlice.actions;

export default UserSlice.reducer;
