import { RequestError } from "interfaces/errors.interfaces";

export const parseAxiosErrorObject = (error?: {
  message?: string;
  statusCode?: number;
}) => ({
  message:
    error?.message || "Ha ocurrido un error por favor intentar nuevamente",
  code: error?.statusCode || 500,
});

export const setGenericError = (error: RequestError) => ({
  code: error?.code as number,
  message: error?.message as string,
});
