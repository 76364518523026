import { FC } from "react";
import { DashboardType } from "interfaces/dashboard.interfaces";

import Dashboard from "components/organisms/dashboard";

const ProfileRedundsVieew: FC = () => {
  return (
    <Dashboard current={DashboardType.refunds}>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi atque
      ea quis modi recusandae! Ex iusto aliquid, blanditiis, maxime itaque id
      minima adipisci sint repellat, dolor autem quibusdam omnis voluptate.
      Aliquam Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Repudiandae saepe necessitatibus assumenda deserunt earum minima numquam
      cupiditate similique, dolor rerum asperiores, dolorem placeat pariatur
      doloribus quia blanditiis corporis quos doloremque.
    </Dashboard>
  );
};

export default ProfileRedundsVieew;
