import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExternalTrackingEvent } from "interfaces/tracking.interfaces";
import { trackingServices } from "services/tracking.services";

interface ITrackingState {
  isLoading: boolean;
  error: null | string;
  trackingEvents: IExternalTrackingEvent[];
}

/**
 * A function that fetch the tracking info
 * @param {String} trackingNumber The tracking number
 */
export const fetchTrackingByTrackingNumber = createAsyncThunk(
  "tracking/fetchTrackingByTrackingNumber",
  async (trackingNumber: string) => {
    return await trackingServices.getTrackingInfo(trackingNumber);
  }
);

const initialState: ITrackingState = {
  error: null,
  isLoading: false,
  trackingEvents: [],
};

const TrackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTrackingByTrackingNumber.fulfilled,
        (state, action: PayloadAction<IExternalTrackingEvent[]>) => {
          state.isLoading = false;
          state.trackingEvents = action.payload;
        }
      )
      .addCase(fetchTrackingByTrackingNumber.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchTrackingByTrackingNumber.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error?.message || genericErrorMessage;
      });
  },
});

const genericErrorMessage = "a generic error occurred on the request";

export default TrackingSlice.reducer;
