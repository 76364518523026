import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { IStatistic } from "interfaces/statistics.interfaces";

export const StatisticServices = {
  getTrendingProducts: async (): Promise<IStatistic[]> => {
    try {
      const statistics: AxiosResponse<IStatistic[]> = await puntoMallAPI.get(
        "statistics/trending"
      );
      return statistics.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },

  getBestSellersProducts: async (): Promise<IStatistic[]> => {
    try {
      const orders: AxiosResponse<IStatistic[]> = await puntoMallAPI.get(
        "statistics/best_sellers"
      );
      return orders.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },
};
