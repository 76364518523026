import { FC, useEffect } from "react";
import { scrollTop } from "utils/commons";
import { Container, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";

import { getProfile } from "store/user.slice";
import { DashboardType } from "interfaces/dashboard.interfaces";

import UpdateName from "./components/UpdateName";
import ProfileItem from "./components/ProfileItem";
import UpdateGender from "./components/UpdateGender";
import Dashboard from "components/organisms/dashboard";
import UpdateBirthday from "./components/UpdateBirthday";
import UpdatePhoneNumber from "./components/UpdatePhoneNumber";
import ShippingAddressList from "./components/ShippingAddressList";
import CardLayoutWithTittle from "components/molecules/card-layout-with-title";

const AccountSettingsView: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <Dashboard current={DashboardType.account}>
      <Container maxWidth="md" sx={{ p: { xs: 0 } }}>
        <Typography fontSize={18} marginBottom={3}>
          Infomación de la cuenta
        </Typography>

        <CardLayoutWithTittle title="Información personal para Aduana">
          <UpdateName />
          <UpdateBirthday />

          <ProfileItem
            name="Cédula de identidad"
            hideActionButton
            value={user?.document ?? ""}
          />

          <UpdateGender />
        </CardLayoutWithTittle>

        <CardLayoutWithTittle title="Información de contacto">
          <ProfileItem
            name="Email"
            hideActionButton
            value={user?.email ?? ""}
          />
          <UpdatePhoneNumber />
        </CardLayoutWithTittle>

        <CardLayoutWithTittle title="Direcciones">
          <ShippingAddressList />
        </CardLayoutWithTittle>
      </Container>
    </Dashboard>
  );
};

export default AccountSettingsView;
