import { AxiosResponse } from "axios";
import {
  IUpdatePassword,
  IUpdatePasswordWithCode,
  IUser,
  IUserUpdate,
} from "interfaces/user.interfaces";
import { parseAxiosErrorObject } from "utils/errors";
import { puntoMallAPI } from "./config/axios.config";

export const userServices = {
  /**
   * A function that makes axios request to puntoMall API (/auth) and get a jwt token
   * @param {String} email
   * @param {String} password
   * @returns {IUser} The access token
   */
  getProfile: async (): Promise<IUser> => {
    const user: AxiosResponse<IUser> = await puntoMallAPI.get("users");

    return user.data;
  },

  /**
   * A function that makes axios request to puntoMall API (/auth) and update the user profile
   * @param {IUserUpdate} userUpdate The new user info
   * @returns {IUser} The access token
   */
  updateUser: async (userUpdate: IUserUpdate): Promise<IUser> => {
    const { data: user }: AxiosResponse<IUser> = await puntoMallAPI.patch(
      "users",
      userUpdate
    );

    return user;
  },

  updatePassword: async (updatePassword: IUpdatePassword): Promise<IUser> => {
    const { data: user }: AxiosResponse<IUser> = await puntoMallAPI.patch(
      "users/password",
      updatePassword
    );

    return user;
  },

  updatePasswordWithCode: async (
    updatePasswordWithCode: IUpdatePasswordWithCode
  ): Promise<IUser> => {
    try {
      const { data: user }: AxiosResponse<IUser> = await puntoMallAPI.patch(
        "users/forgot-password",
        updatePasswordWithCode
      );

      return user;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  forgotPassword: async (email: string): Promise<boolean> => {
    try {
      const response: AxiosResponse<boolean> = await puntoMallAPI.post(
        "users/forgot-password",
        { email }
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
