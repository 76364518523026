import { ICreateSnackbarMessage } from "./snackbars.interfaces";
import { hideSnackBarMessages, showSnackBarMessages } from "./snackbars.slice";

export const createSnackbarMessage =
  ({ message, snackbarType, origin }: ICreateSnackbarMessage) =>
  (dispatch: any) => {
    dispatch(showSnackBarMessages({ message, snackbarType, origin }));

    setTimeout(() => {
      dispatch(hideSnackBarMessages());
    }, 5000);
  };

export const actions = {};
