export const uruguayanCitiesList = [
  { ARTIGAS: "Artigas" },
  { BALTASAR_BRUM: "Baltasar Brum" },
  { BELLA_UNION: "Bella Union" },
  { LAS_PIEDRAS: "Las Piedras" },
  { TOMAS_GOMENSORO: "Tomas Gomensoro" },
  { AGUAS_CORRIENTES: "Aguas Corrientes" },
  { ATLANTIDA: "Atlantida" },
  { BARRA_DE_CARRASCO: "Barra de Carrasco" },
  { BARROS_BLANCOS: "Barros Blancos" },
  { CANELONES: "Canelones" },
  { CIUDAD_DE_LA_COSTA: "Ciudad de la Costa" },
  { COLONIA_NICOLICH: "Colonia Nicolich" },
  { EL_PINAR: "El Pinar" },
  { EMPALME_OLMOS: "Empalme Olmos" },
  { JOANICO: "Joanico" },
  { JOAQUIN_SUAREZ: "Joaquin Suarez" },
  { LA_FLORESTA: "La Floresta" },
  { LA_PAZ: "La Paz" },
  { LAS_TOSCAS: "Las Toscas" },
  { LOS_CERRILLOS: "Los Cerrillos" },
  { MARINDIA: "Marindia" },
  { MAROÑAS: "Maroñas" },
  { MIGUES: "Migues" },
  { MONTES: "Montes" },
  { NEPTUNIA: "Neptunia" },
  { PANDO: "Pando" },
  { PARQUE_DEL_PLATA: "Parque del Plata" },
  { PASO_DE_CARRASCO: "Paso de Carrasco" },
  { PROGRESO: "Progreso" },
  { SALINAS: "Salinas" },
  { SAN_ANTONIO: "San Antonio" },
  { SAN_BAUTISTA: "San Bautista" },
  { SAN_JACINTO: "San Jacinto" },
  { SAN_LUIS: "San Luis" },
  { SAN_RAMON: "San Ramon" },
  { SANTA_LUCIA: "Santa Lucia" },
  { SANTA_ROSA: "Santa Rosa" },
  { SAUCE: "Sauce" },
  { SOCA: "Soca" },
  { TALA: "Tala" },
  { TOLEDO: "Toledo" },
  { ACEGUA: "Acegua" },
  { ISIDORO_NOBLIA: "Isidoro Noblia" },
  { MELO: "Melo" },
  { RIO_BRANCO: "Rio Branco" },
  { TUPAMBAE: "Tupambae" },
  { AGRACIADA: "Agraciada" },
  { CARMELO: "Carmelo" },
  { COLONIA_VALDENSE: "Colonia Valdense" },
  { COLONIA_DEL_SACRAMENTO: "Colonia del Sacramento" },
  { FLORENCIO_SANCHEZ: "Florencio Sanchez" },
  { JUAN_L_LACAZE: "Juan L. Lacaze" },
  { MIGUELETE: "Miguelete" },
  { NUEVA_HELVECIA: "Nueva Helvecia" },
  { NUEVA_PALMIRA: "Nueva Palmira" },
  { OMBUES_DE_LAVALLE: "Ombues de Lavalle" },
  { ROSARIO: "Rosario" },
  { TARARIRAS: "Tarariras" },
  { BLANQUILLO: "Blanquillo" },
  { CARLOS_REYLES: "Carlos Reyles" },
  { DURAZNO: "Durazno" },
  { LA_PALOMA: "La Paloma" },
  { SANTA_BERNARDINA: "Santa Bernardina" },
  { SARANDI_DEL_YI: "Sarandi del Yi" },
  { VILLA_DEL_CARMEN: "Villa del Carmen" },
  { TRINIDAD: "Trinidad" },
  { CARDAL: "Cardal" },
  { CASUPA: "Casupa" },
  { CERRO_COLORADO: "Cerro Colorado" },
  { CHAMIZO: "Chamizo" },
  { FLORIDA: "Florida" },
  { INDEPENDENCIA: "Independencia" },
  { MENDOZA: "Mendoza" },
  { MENDOZA_CHICO: "Mendoza Chico" },
  { SARANDI_GRANDE: "Sarandi Grande" },
  { VEINTICINCO_DE_AGOSTO: "Veinticinco de Agosto" },
  { VEINTICINCO_DE_MAYO: "Veinticinco de Mayo" },
  { JOSE_BATLLE_Y_ORDONEZ: "Jose Batlle y Ordonez" },
  { JOSE_PEDRO_VARELA: "Jose Pedro Varela" },
  { LAVALLEJA: "Lavalleja" },
  { MARISCALA: "Mariscala" },
  { MINAS: "Minas" },
  { SOLIS_DE_MATAOJO: "Solis de Mataojo" },
  { ZAPICÁN: "Zapicán" },
  { AIGUA: "Aigua" },
  { MALDONADO: "Maldonado" },
  { PAN_DE_AZUCAR: "Pan de Azucar" },
  { PIRIAPOLIS: "Piriapolis" },
  { PUNTA_DEL_ESTE: "Punta del Este" },
  { SAN_CARLOS: "San Carlos" },
  { MONTEVIDEO: "Montevideo" },
  { PAJAS_BLANCAS: "Pajas Blancas" },
  { SANTIAGO_VAZQUEZ: "Santiago Vazquez" },
  { GUICHON: "Guichon" },
  { PAYSANDU: "Paysandu" },
  { PIEDRAS_COLORADAS: "Piedras Coloradas" },
  { PORVENIR: "Porvenir" },
  { QUEBRACHO: "Quebracho" },
  { SAN_FELIX: "San Felix" },
  { ALGORTA: "Algorta" },
  { FRAY_BENTOS: "Fray Bentos" },
  { NUEVO_BERLIN: "Nuevo Berlin" },
  { RIO_NEGRO: "Rio Negro" },
  { SAN_JAVIER: "San Javier" },
  { YOUNG: "Young" },
  { MASOLLER: "Masoller" },
  { MINAS_DE_CORRALES: "Minas de Corrales" },
  { RIVERA: "Rivera" },
  { TRANQUERAS: "Tranqueras" },
  { VICHADERO: "Vichadero" },
  { CABO_POLONIO: "Cabo Polonio" },
  { CASTILLOS: "Castillos" },
  { CEBOLLATI: "Cebollati" },
  { CHUY: "Chuy" },
  { DIECIOCHO_DE_JULIO: "Dieciocho de Julio" },
  { LA_PEDRERA: "La Pedrera" },
  { LA_TUNA: "La Tuna" },
  { LASCANO: "Lascano" },
  { ROCHA: "Rocha" },
  { VELAZQUEZ: "Velazquez" },
  { BELEN: "Belen" },
  { CONSTITUCION: "Constitucion" },
  { SALTO: "Salto" },
  { DELTA_DEL_TIGRE: "Delta del Tigre" },
  { ECILDA_PAULLIER: "Ecilda Paullier" },
  { LIBERTAD: "Libertad" },
  { PUNTAS_DE_VALDEZ: "Puntas de Valdez" },
  { RAFAEL_PERAZZA: "Rafael Perazza" },
  { RODRIGUEZ: "Rodriguez" },
  { SAN_JOSE_DE_MAYO: "San Jose de Mayo" },
  { CARDONA: "Cardona" },
  { DOLORES: "Dolores" },
  { JOSE_ENRIQUE_RODO: "Jose Enrique Rodo" },
  { MERCEDES: "Mercedes" },
  { PALMITAS: "Palmitas" },
  { SANTA_CATALINA: "Santa Catalina" },
  { SORIANO: "Soriano" },
  { CURTINA: "Curtina" },
  { PASO_DE_LOS_TOROS: "Paso de los Toros" },
  { SAN_GREGORIO_POLANCO: "San Gregorio Polanco" },
  { TACUAREMBO: "Tacuarembo" },
  { SANTA_CLARA_DE_OLIMAR: "Santa Clara de Olimar" },
  { TREINTA_Y_TRES: "Treinta y Tres" },
  { VERGARA: "Vergara" },
  { VILLA_SARA: "Villa Sara" },
];

export const apartmentList = [
  {
    MONTEVIDEO: "Montevideo",
  },
  { ARTIGAS: "Artigas" },
  { CANELONES: "Canelones" },
  { CERRO_LARGO: "Cerro Largo" },
  { COLONIA: "Colonia" },
  { DURAZNO: "Durazno" },
  { FLORES: "Flores" },
  { FLORIDA: "Florida" },
  { LAVALLEJA: "Lavalleja" },
  { MALDONADO: "Maldonado" },
  { PAYSANDU: "Paysandu" },
  { RIO_NEGRO: "Río Negro" },
  { RIVERA: "Rivera" },
  { ROCHA: "Rocha" },
  { SALTO: "Salto" },
  { SAN_JOSE: "San Jose" },
  { SORIANO: "Soriano" },
  { TACUAREMBO: "Tacuarembo" },
  { TREINTA_Y_TRES: "Treina y Tres" },
];

export const getApartmentLabel = (apartment: string) =>
  apartmentList
    .map((a) => ({ value: Object.keys(a)[0], label: Object.values(a)[0] }))
    .find((option) => option.value === apartment)?.label;
