import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import Slider from "react-slick";
import ProductCard from "components/molecules/product-card";
import ProductCardSkeleton from "components/atoms/product-card-skeleton";

import { useTheme } from "@mui/system";
import { Box, Typography, useMediaQuery } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  getSimilarProductState,
  getStateProductsLoaders,
} from "store/products/products.selectors";

import { genRandomId } from "utils/commons";
import { fetchSimilarProducts } from "store/products/products.actions";

interface IProps {
  searchQuery: string;
  hideArrows?: boolean;
}

const ArrowBackComponent = (props: any) => {
  if (props.type === "back")
    return (
      <ArrowBackIosNewIcon
        sx={{
          color: "text.secondary",
          "&:hover": { backgroundColor: "transparent", color: "text.primary" },
        }}
        fontSize="large"
        {...props}
      />
    );

  return (
    <ArrowForwardIosIcon
      sx={{
        color: "text.secondary",
        "&:hover": { backgroundColor: "transparent", color: "text.primary" },
      }}
      fontSize="large"
      {...props}
    />
  );
};

const SimilarProductsList: FC<IProps> = (props) => {
  const { searchQuery } = props;

  const disptach = useAppDispatch();
  const { breakpoints, spacing } = useTheme();
  const { list, query } = useAppSelector(getSimilarProductState);
  const { similarProductsLoader } = useAppSelector(getStateProductsLoaders);

  useEffect(() => {
    if (searchQuery !== query) disptach(fetchSimilarProducts(searchQuery));
  }, [disptach, query, searchQuery]);

  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));
  const downToLargeScreenSize = useMediaQuery(breakpoints.down("lg"));
  const downToXlargeScreenSize = useMediaQuery(breakpoints.down("xl"));

  const getcurrentScreenSize = () => {
    if (downToMiddleScreenSize) return 1;
    if (downToLargeScreenSize) return 2;
    if (downToXlargeScreenSize) return 3;

    return 4;
  };

  if (similarProductsLoader)
    return <ProductCardSkeleton title="También te puede interesar" />;

  const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToScroll: 1,
    className: "center",
    centerPadding: spacing(1),
    draggable: downToMiddleScreenSize,
    slidesToShow: getcurrentScreenSize(),
    swipeToSlide: downToMiddleScreenSize,

    nextArrow: <ArrowBackComponent type="front" />,
    prevArrow: <ArrowBackComponent type="back" />,
  };

  return (
    <>
      <Typography
        mt={2}
        mb={1}
        fontSize={18}
        variant="subtitle1"
        color="text.secondary"
      >
        También te puede interesar
      </Typography>

      <Box mx={2}>
        <Slider {...settings} arrows>
          {list.slice(1, 18).map((item) => (
            <ProductCard product={item} key={genRandomId()} />
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default SimilarProductsList;
