import { useNavigate } from "react-router-dom";
import { FC, Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import {
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

import {
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
} from "@mui/icons-material";

import { getAllCategories } from "store/categories.slice";
import { ICategory } from "interfaces/categories.interfaces";

interface IProps {
  toggleView: (val: boolean) => void;
}

interface ICategoryOption {
  category: ICategory;
  isSubCategory?: boolean;
  isSubSubCategory?: boolean;
  onClick: (sword: string) => void;
}

const MobileCategoriesList: FC<IProps> = (props) => {
  const { toggleView } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const { isFetchched, list } = useAppSelector((state) => state.categories);

  useEffect(() => {
    if (!isFetchched) dispatch(getAllCategories());
  }, [dispatch, isFetchched]);

  const handleClick = () => setOpen(!open);

  const navigateToSelectedCategory = (sword: string) => {
    toggleView(false);
    navigate(`/search?amz=${sword}&page=1`);
  };

  return (
    <List>
      <ListItemButton sx={{ paddingY: 0 }} onClick={handleClick}>
        <ListItemIcon>
          <FormatListBulleted />
        </ListItemIcon>

        <ListItemText
          primary="Categorías"
          sx={{ color: "text.secondary", ml: -2 }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {list.map((cat) => (
            <Fragment key={cat.id}>
              {cat.subCategories?.length ? (
                <CategoriyWithSubCategories
                  category={cat}
                  onClick={navigateToSelectedCategory}
                />
              ) : (
                <CategoriyWithoutSubCategories
                  category={cat}
                  onClick={navigateToSelectedCategory}
                />
              )}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

const CategoriyWithSubCategories: FC<ICategoryOption> = (props) => {
  const { category, onClick, isSubCategory } = props;

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={category.name}
          sx={{ color: "text.secondary", ml: isSubCategory ? 4 : 2 }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category.subCategories.map((subcategory) => (
            <Fragment key={subcategory.id}>
              {subcategory.subCategories?.length ? (
                <CategoriyWithSubCategories
                  isSubCategory
                  onClick={onClick}
                  category={subcategory}
                />
              ) : (
                <CategoriyWithoutSubCategories
                  isSubSubCategory
                  onClick={onClick}
                  category={subcategory}
                />
              )}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const CategoriyWithoutSubCategories: FC<ICategoryOption> = (props) => {
  const { category, onClick, isSubCategory, isSubSubCategory } = props;

  return (
    <ListItemButton
      sx={{ pl: 4 }}
      onClick={() => onClick(category.englishKeyword)}
    >
      <ListItemText
        primary={category.name}
        sx={{
          color: "text.secondary",
          ml: isSubCategory ? 2 : "auto",
          pl: isSubSubCategory ? 2 : "auto",
        }}
      />
    </ListItemButton>
  );
};

export default MobileCategoriesList;
