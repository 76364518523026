import { FC } from "react";
import { Grid, RadioGroup } from "@mui/material";

import PaymentMethodRadio from "components/atoms/payment-method-radio";
import { PaymentMethodTypes } from "store/checkout/checkout.interfaces";

import ocaImage from "assets/images/payment-methods/oca.png";
import hsbcImage from "assets/images/payment-methods/hsbc.png";
import itauImage from "assets/images/payment-methods/itau.png";
import bbvaImage from "assets/images/payment-methods/bbva.png";
import visaImage from "assets/images/payment-methods/visa.png";
import ebrouImage from "assets/images/payment-methods/ebrou.png";
// import abitabImage from "assets/images/payment-methods/abitab.png";
import santanderImage from "assets/images/payment-methods/santander.png";
import scotiaBankImage from "assets/images/payment-methods/scotiabank.png";
import banqueheritageImage from "assets/images/payment-methods/banqueheritage.png";

interface IProps {
  paymentMethod: PaymentMethodTypes;
  setPaymentMethod: (methodType: PaymentMethodTypes) => void;
}

const PaymentMethodsList: FC<IProps> = (props) => {
  const { paymentMethod, setPaymentMethod } = props;

  return (
    <RadioGroup
      value={paymentMethod}
      onChange={(e) => setPaymentMethod(e.target.value as PaymentMethodTypes)}
    >
      <Grid container spacing={2} columns={{ xs: 3, sm: 4, md: 6, lg: 9 }}>
        <PaymentMethodRadio
          image={scotiaBankImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_SCOTIABANK}
        />
        <PaymentMethodRadio
          image={visaImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_VISA}
        />
        <PaymentMethodRadio
          image={ocaImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_OCA}
        />
        <PaymentMethodRadio
          image={itauImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_ITAU}
        />
        <PaymentMethodRadio
          image={hsbcImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_HSBC}
        />
        <PaymentMethodRadio
          selected={paymentMethod}
          image={banqueheritageImage}
          value={PaymentMethodTypes.SISTARBANC_HERITAGE}
        />
        <PaymentMethodRadio
          image={ebrouImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_BROU}
        />

        <PaymentMethodRadio
          image={bbvaImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_BBVA}
        />
        <PaymentMethodRadio
          image={santanderImage}
          selected={paymentMethod}
          value={PaymentMethodTypes.SISTARBANC_SANTANDER}
        />
      </Grid>
    </RadioGroup>
  );
};

export default PaymentMethodsList;
