import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestError } from "interfaces/errors.interfaces";
import { BodyShippingAddress } from "interfaces/shipping-addresses.interfaces";
import { ShippingAddressesService } from "services/shipping-addresses.services";

/**
 * A function that is used to get the list of addresses associated with the user
 */
export const getShippingAddresses = createAsyncThunk(
  "shipping-addresses/getShippingAddresses",
  async (_, thunkApi) => {
    try {
      return await ShippingAddressesService.getShippingAddresses();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that create a new shipping address by userId
 * @param {ShippingAddress} body of new address
 */
export const createNewShippingAddress = createAsyncThunk(
  "cart/createNewShippingAddress",
  async (body: BodyShippingAddress, thunkApi) => {
    try {
      return await ShippingAddressesService.createShippingAddress(body);
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that remove existing shipping address by id
 * @param {string} id of the address
 */
export const removeShippingAddress = createAsyncThunk(
  "cart/removeShippingAddress",
  async (id: string, thunkApi) => {
    try {
      await ShippingAddressesService.removeShippingAddress(id);
      return thunkApi.dispatch(getShippingAddresses());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that update existing shipping address by id and
 * then fecth all addresses
 * @param {string} id of the address
 */
export const updateShippingAddress = createAsyncThunk(
  "cart/updateShippingAddress",
  async ({ id, body }: { id: string; body: BodyShippingAddress }, thunkApi) => {
    try {
      await ShippingAddressesService.updateShippingAddress(id, body);
      return thunkApi.dispatch(getShippingAddresses());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * A function that update the default shipping address by id. If user have a default address,
 * remove the default and only set current address
 * @param {string} id of the address
 */
export const updateDefaultShippingAddress = createAsyncThunk(
  "cart/updateShippingAddress",
  async (id: string, thunkApi) => {
    try {
      await ShippingAddressesService.updateDefaultShippingAddress(id);
      return thunkApi.dispatch(getShippingAddresses());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);
