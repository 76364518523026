import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IProductsState } from "./products.interfaces";
import { IProduct, ProductList } from "interfaces/products.interfaces";

import {
  fetchProducts,
  fetchSingleProduct,
  fetchSimilarProducts,
} from "./products.actions";

const initialState: IProductsState = {
  product: null,

  productIsNotValid: false,
  similarProducts: { list: [], query: "" },
  products: { list: [], quantity: 0, currentPage: 0 },

  errors: {
    productError: null,
    productsError: null,
    similarProductsError: null,
  },

  loaders: {
    productLoader: false,
    productsLoader: false,
    similarProductsLoader: false,
  },
  helpers: {
    fetchedProduct: false,
    fetchedAllProducts: false,
    disableForMissingVariantSelected: false,
  },
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setDisabledForMissingVariantSelected(
      state,
      action: PayloadAction<boolean>
    ) {
      state.helpers.disableForMissingVariantSelected = action.payload;
    },

    setSimilarProductQuery(state, action: PayloadAction<string>) {
      state.similarProducts.query = action.payload;
    },

    setIfProductIsNotValid(state, action: PayloadAction<boolean>) {
      state.productIsNotValid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchProducts.fulfilled,
        (
          state,
          action: PayloadAction<{
            products: ProductList[];
            currentPage: number;
          }>
        ) => {
          state.loaders.productsLoader = false;
          state.helpers.fetchedAllProducts = true;

          state.products.list = action.payload.products;
          state.products.currentPage = action.payload.currentPage;
          state.products.quantity = action.payload.products.length;
        }
      )
      .addCase(fetchProducts.pending, (state) => {
        state.errors.productsError = null;
        state.loaders.productsLoader = true;
        state.helpers.fetchedAllProducts = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loaders.productsLoader = false;
        state.errors.productsError =
          action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(
        fetchSingleProduct.fulfilled,
        (state, action: PayloadAction<IProduct>) => {
          state.product = action.payload;
          state.loaders.productLoader = false;
          state.helpers.fetchedProduct = true;

          if (action.payload.categories.includes("Kindle Store")) {
            state.productIsNotValid = true;
          }
        }
      )
      .addCase(fetchSingleProduct.pending, (state) => {
        state.errors.productError = null;
        state.loaders.productLoader = true;
        state.helpers.fetchedProduct = false;

        state.productIsNotValid = false;
      })
      .addCase(fetchSingleProduct.rejected, (state, action) => {
        state.loaders.productLoader = false;
        state.errors.productError =
          action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(
        fetchSimilarProducts.fulfilled,
        (
          state,
          action: PayloadAction<{ list: ProductList[]; query: string }>
        ) => {
          state.similarProducts = action.payload;
          state.loaders.similarProductsLoader = false;
        }
      )
      .addCase(fetchSimilarProducts.pending, (state) => {
        state.similarProducts.list = [];
        state.errors.similarProductsError = null;
        state.loaders.similarProductsLoader = true;
      })
      .addCase(fetchSimilarProducts.rejected, (state, action) => {
        state.loaders.similarProductsLoader = false;
        state.errors.similarProductsError =
          action.error?.message || genericErrorMessage;
      });
  },
});

const genericErrorMessage = "a generic error occurred on the request";

export const { setDisabledForMissingVariantSelected, setIfProductIsNotValid } =
  productsSlice.actions;

export default productsSlice.reducer;
