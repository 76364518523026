import { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";

import {
  IShippingAddress,
  BodyShippingAddress,
} from "interfaces/shipping-addresses.interfaces";

import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { getAddressStore } from "store/addresses/addresses.selectors";
import { updateShippingAddress } from "store/addresses/addresses.actions";

import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";
import SnackbarMessage from "components/atoms/custom-snackbar";
import ShippingAddressForm from "components/organisms/forms/ShippingAddressForm";

interface IProps {
  open: boolean;
  address: IShippingAddress;
  setOpen: (value: boolean) => void;
}

const UpdateShippingAddress: FC<IProps> = (props) => {
  const { open, setOpen, address } = props;

  const form = useForm({
    defaultValues: {
      address: address.address,
      zipCode: address.zipCode,
      country: address.country,
      phoneNumber: address.phoneNumber,

      doorNumber: address.doorNumber,
      neighborhood: address.neighborhood,
      observations: address.observations,
    },
  });

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(getAddressStore);
  const [apartmentError, setApartmentError] = useState(false);
  const [apartment, setApartment] = useState(address.apartment);

  const onSubmit = (addressBody: FieldValues) => {
    if (!apartment) setApartmentError(true);
    else
      dispatch(
        updateShippingAddress({
          id: address.id,
          body: { ...addressBody, apartment } as BodyShippingAddress,
        })
      ).then(() => setOpen(false));
  };

  return (
    <>
      <CustomModal handleClose={setOpen} open={open} size="big">
        <Typography sx={{ mb: 2 }} textAlign={{ xs: "center", sm: "left" }}>
          Actualizar dirección
        </Typography>

        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xs={12}>
            <ShippingAddressForm
              formProps={form}
              apartmentObject={{ setValue: setApartment, value: apartment }}
            />
          </Grid>

          <Grid item xs={12} md={3} marginTop={2}>
            <CustomButton
              text="Guardar"
              variant="primary"
              disabled={isLoading}
              sx={{ width: "100%" }}
              onClick={form.handleSubmit(onSubmit)}
            />
          </Grid>

          <Grid item xs={12} md={3} marginTop={{ md: 2 }}>
            <CustomButton
              text="Cancelar"
              disabled={isLoading}
              variant="secondary"
              sx={{ width: "100%" }}
              onClick={() => setOpen(false)}
            />
          </Grid>
        </Grid>
      </CustomModal>

      <SnackbarMessage
        open={apartmentError}
        type={SnackbarType.error}
        setOpen={setApartmentError}
        message="seleccione un departamento válido para continuar"
      />
    </>
  );
};

export default UpdateShippingAddress;
