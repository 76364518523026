import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import { FC } from "react";

interface ITrackingNodeContainer {
  label: string;
  date?: Date;
}

export const TrackingNodeContainer: FC<ITrackingNodeContainer> = ({
  label,
  date,
  children,
}) => (
  <Box width="100%">
    {children}
    <Typography
      marginTop="20px"
      marginBottom="14px"
      textAlign="center"
      color="#777577"
      fontSize={11}
    >
      {label}
    </Typography>
    <Typography
      textAlign="center"
      color="#777577"
      fontSize={12}
      fontWeight={500}
    >
      {date
        ? DateTime.fromJSDate(date).setLocale("es").toFormat("ccc. dd LLL.")
        : ""}
    </Typography>
  </Box>
);

export const FirstTrackingNode: FC<{ active?: boolean }> = ({
  active = false,
}) => {
  const color = active ? "#00B67A" : "#DFDFDF";

  return (
    <Box width="100%" minWidth="128px" display="flex">
      <Box
        width="50%"
        sx={{ borderBottom: "4px solid transparent", position: "relative" }}
      />
      <Box
        width="50%"
        sx={{ borderBottom: `4px solid ${color}`, position: "relative" }}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: "100%",
            backgroundColor: color,
            position: "absolute",
            bottom: -14,
            left: -12,
          }}
        />
      </Box>
    </Box>
  );
};

export const LastTrackingNode: FC<{ active?: boolean }> = ({
  active = false,
}) => {
  const color = active ? "#00B67A" : "#DFDFDF";

  return (
    <Box width="100%" minWidth="128px" display="flex">
      <Box
        width="50%"
        sx={{ borderBottom: `4px solid ${color}`, position: "relative" }}
      >
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: "100%",
            backgroundColor: color,
            position: "absolute",
            bottom: -14,
            right: -12,
          }}
        />
      </Box>
      <Box
        width="50%"
        sx={{ borderBottom: "4px solid transparent", position: "relative" }}
      />
    </Box>
  );
};

const TrackingNode: FC<{ active?: boolean }> = ({ active = false }) => {
  const color = active ? "#00B67A" : "#DFDFDF";

  return (
    <Box
      width="100%"
      minWidth="128px"
      sx={{ borderBottom: `4px solid ${color}`, position: "relative" }}
    >
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: "100%",
          backgroundColor: color,
          position: "absolute",
          bottom: -14,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      />
    </Box>
  );
};

export default TrackingNode;
