import { useLocation } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { headerWithoutSearchBar, footerWithoutSearchBar } from "utils/commons";

import { Box } from "@mui/system";
import { Container } from "@mui/material";

import Footer from "../footer";
import NavBar from "../navbar";

import SimpleNavbar from "../simple-navbar";
import SimpleFooter from "../simple-footer";

/**
 * Component based on MUI that displays custom layout integrated with a navbar and footer
 * and receives a children and render it
 * @param props
 * @example
 * return <AppLayout>{children}</AppLayout>
 */

const AppLayout: FC = (props) => {
  const location = useLocation();

  const [simpleHeader, setSimpleHeader] = useState(false);
  const [simpleFooter, setSimpleFooter] = useState(false);

  useEffect(() => {
    if (headerWithoutSearchBar.includes(location.pathname))
      setSimpleHeader(true);
    else setSimpleHeader(false);

    if (footerWithoutSearchBar.includes(location.pathname))
      setSimpleFooter(true);
    else setSimpleFooter(false);
  }, [location]);

  return (
    <Container disableGutters sx={{ p: 0, m: 0, minWidth: "100%" }}>
      <Box position="sticky" top={0} zIndex={100}>
        {simpleHeader ? <SimpleNavbar /> : <NavBar />}
      </Box>

      <Box minHeight="100vh">{props.children}</Box>

      {simpleFooter ? <SimpleFooter /> : <Footer />}
    </Container>
  );
};

export default AppLayout;
