import { FC } from "react";
import { useAppSelector } from "hooks";

import { CheckoutSteps } from "store/checkout/checkout.interfaces";
import HorizontalStepper from "components/molecules/horizontal-stepper";

const CheckoutStepper: FC = () => {
  const { currenStep } = useAppSelector((state) => state.checkout.helpers);

  const steps = [
    {
      label: "Información personal",
      value: CheckoutSteps.USER_INFORMATION,
    },
    { label: "Método de envío", value: CheckoutSteps.DELIVERY_METHOD },
    { label: "Datos de envío", value: CheckoutSteps.ADDRESS },
  ];

  return <HorizontalStepper currentValue={currenStep} steps={steps} />;
};

export default CheckoutStepper;
