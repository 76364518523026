import { FC, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Button, Container, CssBaseline, Theme } from "@mui/material";
import { DashboardType } from "interfaces/dashboard.interfaces";

import MainListItems from "./ListItems";
import { KeyboardArrowDown } from "@mui/icons-material";

interface IProps {
  current: DashboardType;
}

const Dashboard: FC<IProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Box display={{ xs: "block", md: "flex" }}>
      <CssBaseline />

      <Box display={{ xs: "block", md: "none" }}>
        <Button
          fullWidth={true}
          className={classes.button}
          onClick={() => setOpen(!open)}
        >
          Mi cuenta
          <KeyboardArrowDown />
        </Button>
      </Box>

      {open ? (
        <Box onMouseLeave={handleClose}>
          <MainListItems displayTitle={false} current={props.current} />
        </Box>
      ) : null}

      <Box display={{ xs: "none", md: "block" }}>
        <MainListItems current={props.current} />
      </Box>

      <main className={classes.content}>
        <Box
          width={{ xs: "100%", md: "80vw" }}
          minHeight={{ sm: "40vh", md: "70vh" }}
        >
          <Container maxWidth="xl" className={classes.container}>
            <div>{props.children}</div>
          </Container>
        </Box>
      </main>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    overflow: "auto",
    background: theme.palette.background.default,
  },

  button: {
    display: "flex",
    fontSize: "18px",
    padding: "14px 16px",
    textTransform: "unset",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
  },

  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: { padding: theme.spacing(2) },
  },
}));

export default Dashboard;
