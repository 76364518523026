import { FrancheseType } from "interfaces/shopping-cart.interfaces";

import {
  CheckoutState,
  CheckoutSteps,
  UserInformation,
  DeliveryMethods,
  SortStepPosition,
  ShippingAddress,
  PaymentMethodTypes,
} from "./checkout.interfaces";

interface ISetCheckoutStep {
  currentStep: CheckoutSteps;
  sortStepPosition: SortStepPosition;
}

interface ISetShippingInformation {
  address: ShippingAddress;
  addToUserAddresses: boolean;
  deliveryMethod: DeliveryMethods;
}

const checkoutActions = {
  setCheckoutStep(
    state: CheckoutState,
    action: { type: string; payload: ISetCheckoutStep }
  ) {
    state.helpers.currenStep = action.payload.currentStep;
    state.helpers.sortStepPosition = action.payload.sortStepPosition;
  },

  setShippingInformation(
    state: CheckoutState,
    action: { type: string; payload: ISetShippingInformation }
  ) {
    const { addToUserAddresses, deliveryMethod, address } = action.payload;

    state.addressInformation = {
      address,
      deliveryMethod,
      addToUserAddresses,
    };
  },

  setUserInformation(
    state: CheckoutState,
    action: { type: string; payload: UserInformation }
  ) {
    state.userInformation = action.payload;
  },

  toggleStepAddresView(
    state: CheckoutState,
    action: { type: string; payload: boolean }
  ) {
    state.helpers.hideStepAddres = action.payload;
  },

  setPaymentMethodType(
    state: CheckoutState,
    action: { type: string; payload: PaymentMethodTypes }
  ) {
    state.paymentMethods = action.payload;
  },

  toogleConfirmPurchaseView(
    state: CheckoutState,
    action: { type: string; payload: boolean }
  ) {
    state.showPurchaseConfirmation = action.payload;
  },

  resetCheckoutState(state: CheckoutState) {
    state.paymentMethods = null;
    state.userInformation = null;
    state.showPurchaseConfirmation = false;

    state.helpers = {
      hideStepAddres: false,
      currenStep: CheckoutSteps.USER_INFORMATION,
      sortStepPosition: SortStepPosition.USER_INFORMATION,
    };

    state.addressInformation = {
      address: null,
      deliveryMethod: null,
      addToUserAddresses: false,
    };
  },

  toggleUseFranchiseInPurchase(
    state: CheckoutState,
    action: { type: string; payload: FrancheseType }
  ) {
    state.useFranchise = action.payload;
  },
};

export default checkoutActions;
