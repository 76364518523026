import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router-dom";

import {
  Logout,
  ReceiptLong,
  AccountCircle,
  AssignmentInd,
} from "@mui/icons-material";

import { Typography } from "@mui/material";
import { logoutUser } from "store/user.slice";

import AuthOption, { AuthMode } from "./AuthOptions";
import { IOption } from "components/atoms/custom-select";
import { cleanSearchHistory } from "store/globals.slice";

interface IUseAuthOption {
  userInfo: any;
}

const useAuthOptions = (props: IUseAuthOption) => {
  const { userInfo } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isLoggedInOptions: IOption[] = [
    {
      value: AuthMode.WELCOME,
      onClick: () => navigate("/"),
      label: (
        <AuthOption
          icon={<AccountCircle />}
          label={
            <>
              <Typography fontSize={12}>Hola</Typography>
              {userInfo.user && userInfo.user.firstName
                ? userInfo.user?.firstName.substring(0, 13)
                : ""}
            </>
          }
        />
      ),
    },
    {
      value: AuthMode.PROFILE,
      onClick: () => navigate("/profile/account-settings"),
      label: <AuthOption label="Mi cuenta" icon={<AssignmentInd />} />,
    },
    {
      value: AuthMode.MY_ORDERS,
      onClick: () => navigate("/profile/orders"),
      label: <AuthOption label="Mis órdenes" icon={<ReceiptLong />} />,
    },
    // {
    //   value: AuthMode.REFUND_PRODUCTS,
    //   onClick: () => navigate("/profile/refunds"),
    //   label: (
    //     <AuthOption
    //       label={
    //         <>
    //           Devolución <br />
    //           de productos
    //         </>
    //       }
    //       icon={<Autorenew />}
    //     />
    //   ),
    // },
    {
      value: AuthMode.LOGOUT,
      onClick: () => {
        dispatch(logoutUser());
        dispatch(cleanSearchHistory());
        navigate("/", { replace: true });
        window.location.reload();
      },
      label: <AuthOption label="Cerrar Sesión" icon={<Logout />} />,
    },
  ];

  const isNotLoggedInOptions: IOption[] = [
    {
      value: AuthMode.WELCOME,
      onClick: () => navigate("/"),
      label: (
        <AuthOption
          icon={<AccountCircle />}
          label={
            <Typography variant="body2" fontSize={16}>
              Ingresar
            </Typography>
          }
        />
      ),
    },
    {
      value: AuthMode.SIGNIN,
      onClick: () => navigate("/auth/signin"),
      label: <AuthOption label="Iniciar Sesión" icon={<AccountCircle />} />,
    },
    {
      value: AuthMode.SIGNUP,
      onClick: () => navigate("/auth/signup"),
      label: <AuthOption label="Registrarse" icon={<AccountCircle />} />,
    },
  ];

  return { isLoggedInOptions, isNotLoggedInOptions };
};

export default useAuthOptions;
