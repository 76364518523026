import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import {
  ExpandLess,
  ExpandMore,
  Person as PersonIcon,
} from "@mui/icons-material";

import { Box } from "@mui/system";
import { logoutUser } from "store/user.slice";
import { useAppDispatch } from "hooks";

interface IProps {
  isLoggedIn: boolean;
  toggleView: (val: boolean) => void;
}

const AccountList: FC<IProps> = (props) => {
  const { isLoggedIn, toggleView } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  const IsloggedInOptions = () => {
    return (
      <Box>
        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            toggleView(false);
            navigate("/profile/account-settings");
          }}
        >
          <ListItemText
            primary="Información de la cuenta"
            sx={{ color: "text.secondary" }}
          />
        </ListItemButton>

        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            navigate("/profile/security");
            toggleView(false);
          }}
        >
          <ListItemText primary="Seguridad" sx={{ color: "text.secondary" }} />
        </ListItemButton>

        <ListItemButton
          sx={{ pl: 4 }}
          onClick={() => {
            toggleView(false);
            dispatch(logoutUser());
            navigate("/", { replace: true });
          }}
        >
          <ListItemText
            primary="Cerrar sesión"
            sx={{ color: "text.secondary" }}
          />
        </ListItemButton>
      </Box>
    );
  };

  const IsNotLoggedInOptions = () => {
    return (
      <Box>
        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/auth/signin")}>
          <ListItemText
            primary="Iniciar Sesión"
            sx={{ color: "text.secondary" }}
          />
        </ListItemButton>

        <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/auth/signup")}>
          <ListItemText
            primary="Registrarse"
            sx={{ color: "text.secondary" }}
          />
        </ListItemButton>
      </Box>
    );
  };

  return (
    <List>
      <ListItemButton sx={{ paddingY: 0 }} onClick={handleClick}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>

        <ListItemText
          sx={{ color: "text.secondary", ml: -2 }}
          primary={isLoggedIn ? "Mi cuenta" : "Ingresar"}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {isLoggedIn ? <IsloggedInOptions /> : <IsNotLoggedInOptions />}
        </List>
      </Collapse>
    </List>
  );
};

export default AccountList;
