import { SnackbarOrigin } from "@mui/material";

export interface ISnackbarState {
  show: boolean;
  message: string;
  origin?: SnackbarOrigin;
  snackbarType: SnackbarType;
}

export enum SnackbarType {
  info = "info",
  error = "error",
  warning = "warning",
  success = "success",
}

export interface snakcbarAction {
  type: string;
  payload: ICreateSnackbarMessage;
}

export interface ICreateSnackbarMessage {
  message: string;
  origin?: SnackbarOrigin;
  snackbarType: SnackbarType;
}
