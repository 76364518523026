import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useQuery } from "hooks";
import { IProductVariationValue } from "interfaces/products.interfaces";
import { FC, MouseEvent } from "react";

interface ISingleVariationCard {
  variation: IProductVariationValue;
  variationName: string;
  onClick: (e: MouseEvent<HTMLElement>) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  disabled: { opacity: 0.3, cursor: "not-allowed" },
  imgCard: { width: 48, height: 48, objectFit: "contain" },
  selectedCard: { border: `1px solid ${theme.palette.secondary.main}` },
}));

const variationCardCss = {
  px: 1,
  py: 0.5,
  margin: 0.5,
  borderRadius: 2,
  cursor: "pointer",
  border: "1px solid lightgray",
};

/**
 *  Component for showing a simple card text that takes two props:
 * @param {IProductVariationValue} variation
 * @param {Function} props event function that is executed when clicking on the component
 */

const TextVariationCard: FC<ISingleVariationCard> = (props) => {
  const s = useStyles();
  const query = useQuery();
  const selected = props.variation.selected;

  const getSelected = () => {
    if (selected) return true;
    const variationValue = query.get(props.variationName);
    const asin = query.get("asin");

    if (
      (variationValue && variationValue === props.variation.value) ||
      asin === props.variation.asin
    )
      return true;

    if (asin === props.variation.asin || asin === props.variation.dpUrl)
      return true;
    return false;
  };

  return (
    <Box
      sx={{ ...variationCardCss }}
      onClick={!selected ? props.onClick : undefined}
      className={`${getSelected() ? s.selectedCard : ""} ${
        !props.variation.available ? s.disabled : ""
      }`}
    >
      <Typography variant="button" fontSize={12}>
        {props.variation.value}
      </Typography>
    </Box>
  );
};

export default TextVariationCard;
