import { FC, useEffect, useRef } from "react";
import { IPaymentConfig } from "interfaces/payment.interfaces";

interface ISistarbancForm {
  paymentConfig: IPaymentConfig;
}

const SistarbancForm: FC<ISistarbancForm> = ({ paymentConfig }) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (Object.keys(paymentConfig).length && paymentConfig.bank) {
      ref.current.submit();
    }
  }, [paymentConfig, ref]);

  return (
    <form
      ref={ref}
      action={
        process.env.REACT_APP_SISTARBANC_PAY_NOW ??
        "https://spftest.sistarbanc.com.uy/spfe/servlet/PagoEmpresa"
      }
      method="POST"
    >
      <input type="hidden" name="idBanco" value={paymentConfig.bank} />
      <input
        type="hidden"
        name="idTransaccion"
        value={paymentConfig.transactionId}
      />
      <input type="hidden" name="idOrganismo" value="SKYPOSTAL" />
      <input type="hidden" name="tipoServicio" value="VTOL" />
      <input type="hidden" name="idCuenta" value="0" />
      <input type="hidden" name="idFactura" value={paymentConfig.orderId} />
      <input
        type="hidden"
        name="importe"
        value={paymentConfig.value.toFixed(2).replace(/,|\./g, "")}
      />
      <input
        type="hidden"
        name="importeGravado"
        value={paymentConfig.taxAmount.toFixed(2).replace(/,|\./g, "")}
      />
      <input type="hidden" name="consumidorFinal" value="1" />
      <input type="hidden" name="moneda" value={paymentConfig.currency} />
      <input
        type="hidden"
        name="urlVuelta"
        value={
          process.env.REACT_APP_SISTARBANC_REDIRECT_URL ??
          "https://api-test.mall.puntomio.uy"
        }
      />
    </form>
  );
};

export default SistarbancForm;
