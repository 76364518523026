import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import ProfileWalletView from "pages/profile/wallet";
import ProfileOrdersView from "pages/profile/orders";
import ProfileRedundsVieew from "pages/profile/refunds";
import ProfileSecurityView from "pages/profile/security";
import RequiredAuthRoutes from "./utils/RequiredAuthRoutes";
import AccountSettingsView from "pages/profile/account-settings";

const ProfileRoutes: FC = () => (
  <Routes>
    <Route
      path="account-settings"
      element={
        <RequiredAuthRoutes>
          <AccountSettingsView />
        </RequiredAuthRoutes>
      }
    />
    <Route
      path="security"
      element={
        <RequiredAuthRoutes>
          <ProfileSecurityView />
        </RequiredAuthRoutes>
      }
    />
    <Route
      path="orders"
      element={
        <RequiredAuthRoutes>
          <ProfileOrdersView />
        </RequiredAuthRoutes>
      }
    />
    <Route
      path="refunds"
      element={
        <RequiredAuthRoutes>
          <ProfileRedundsVieew />
        </RequiredAuthRoutes>
      }
    />
    <Route
      path="wallet"
      element={
        <RequiredAuthRoutes>
          <ProfileWalletView />
        </RequiredAuthRoutes>
      }
    />
  </Routes>
);

export default ProfileRoutes;
