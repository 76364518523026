import { FC } from "react";
import { ProductList } from "interfaces/products.interfaces";

import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { StarBorder } from "@mui/icons-material";
import { Paper, Typography, Theme, Rating } from "@mui/material";
import { useAppSelector } from "hooks";
import { Currency } from "interfaces/shopping-cart.interfaces";
import { getPriceInUSD, getPriceInUYU } from "utils/commons";
import { Link } from "react-router-dom";

interface IProps {
  product: ProductList;
}

const ProductCard: FC<IProps> = (props) => {
  const { product } = props;

  const s = useStyles();
  const { currency, cotization } = useAppSelector((state) => state.globals);

  const price =
    currency === Currency.usd
      ? getPriceInUSD(product.price)
      : getPriceInUYU(product.price, cotization);

  const showRating = () => {
    if (!product.productRating) return null;
    if (typeof product.productRating === "number") return product.productRating;
    return Number(product.productRating.split(" ")[0]);
  };

  const description =
    product.productDescription?.length > 85
      ? `${product.productDescription.slice(0, 85)}...`
      : product.productDescription;

  const url = `/products?asin=${product.asin}`;

  return (
    <Paper
      component={Link}
      sx={{ textDecoration: "none" }}
      to={url}
      elevation={3}
      className={s.paper}
    >
      <Box
        component="img"
        alt={description}
        className={s.img}
        src={product.imgUrl}
      />

      <Typography variant="body1" color="text.primary" my={1}>
        {description}
      </Typography>

      <Box width="100%">
        {product.countReview ? (
          <Box className={s.rating}>
            <Rating
              readOnly
              size="small"
              value={showRating()}
              precision={0.5}
              name="product-feedback"
              emptyIcon={
                <StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Typography ml={0.5} color="#007171" variant="body2">
              {product.countReview}
            </Typography>
          </Box>
        ) : null}

        <Box sx={{ py: 0.5, display: "flex", flexDirection: "row" }}>
          {product.price ? (
            <>
              <Typography variant="h5" sx={{ p: 0, fontWeight: 600 }}>
                {currency === Currency.usd ? "U$S " : "$ "}
                {price[0]}
              </Typography>

              <Typography
                sx={{ fontWeight: 600, fontSize: "1em", verticalAlign: ".7em" }}
              >
                {price[1] ?? ""}
              </Typography>
            </>
          ) : (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.2em",
                verticalAlign: ".7em",
              }}
            >
              Consultar Precio
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: 270,
    height: 435,
    display: "flex",
    borderRadius: 6,
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    margin: `${theme.spacing(2)} auto`,
    [theme.breakpoints.down("md")]: { width: "90%" },
  },

  rating: { width: "100%", display: "flex", alignItems: "center" },
  img: { width: 240, height: 224, cursor: "pointer", objectFit: "contain" },
}));

export default ProductCard;
