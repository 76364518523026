import { AccessTimeOutlined } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/system";
import BackdropLoader from "components/atoms/backdrop-loader";
import CustomButton from "components/atoms/custom-button";
import CustomLayout from "components/atoms/simple-layout";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";
import { OrderStatusEnum } from "interfaces/orders.interfaces";
import OrderCard from "pages/profile/orders/components/OrderCard";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSingleOrder } from "store/orders.slice";
import { scrollTop } from "utils/commons";

const PendingPurchasePage: FC = () => {
  const s = useStyles();

  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userId = query.get("uid") as string;
  const orderId = query.get("oid") as string;

  const { user } = useAppSelector((state) => state.userInformation);
  const { singleOrder, isLoading } = useAppSelector((state) => state.orders);

  useEffect(() => {
    if (!userId || !orderId) navigate("/");

    dispatch(getSingleOrder(orderId)).then((res) => {
      const order: any = res.payload;

      console.log("%cindex.tsx line:33 res", "color: #26bfa5;", res);

      switch (order.status) {
        case OrderStatusEnum.ZINC_PROCCESSING:
          return navigate(`/successful-purchase?uid=${userId}&oid=${orderId}`);

        case OrderStatusEnum.FAILED:
          return navigate(`/failed-purchase?uid=${userId}&oid=${orderId}`);

        default:
          break;
      }
    });
  }, [dispatch, navigate, orderId, userId]);

  const goToHomePage = () => {
    scrollTop();
    navigate("/", { replace: true });
    window.location.reload();
  };

  if (isLoading) return <BackdropLoader />;

  return (
    <CustomLayout pd={2}>
      <Box className={s.imgContainer}>
        <Box display="flex" justifyContent="center">
          <Box
            width="96px"
            height="96px"
            display="flex"
            borderRadius="25px"
            justifyContent="center"
            sx={{ backgroundColor: "#FF4D4D" }}
          >
            <AccessTimeOutlined
              sx={{ fontSize: "48px", color: "white", alignSelf: "center" }}
            />
          </Box>
        </Box>

        <Typography my={2} align="center" color="text.primary" fontSize={30}>
          {user?.firstName} tu pago está siendo procesado
        </Typography>

        <Typography
          mx="auto"
          align="center"
          color="text.secondary"
          width={{ xs: "100%", md: "70%" }}
        >
          Recibirás tus productos de 10 a 15 días hábiles a partir de hoy, te
          recomendamos estar atento a tu correo electrónico por cualquier
          eventualidad en el envío.
        </Typography>

        <Divider sx={{ my: 3 }} />

        {singleOrder && (
          <Box p={{ sx: 1, md: 2 }} m={{ sx: 0, md: 2 }}>
            <OrderCard order={singleOrder} />
          </Box>
        )}

        <CustomButton
          variant="primary"
          text="Seguir comprando"
          onClick={goToHomePage}
          sx={{ width: { xs: "100%", md: 254 }, margin: "0 auto" }}
        />
      </Box>
    </CustomLayout>
  );
};

export default PendingPurchasePage;

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 96,
    margin: "0 auto",
    objectFit: "contain",
  },
  imgContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(6),
  },
}));
