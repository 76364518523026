import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Currency,
  IShoppingCart,
  ICartException,
  ICartAvailabilityResponse,
} from "interfaces/shopping-cart.interfaces";

import {
  addItemToCart,
  updateCartItem,
  removeItemToCart,
  fetchCartByUserId,
  checkCartavailability,
} from "./cart.actions";

interface IShoppingCartState {
  error: null | string;
  isActiveCart: boolean;
  shoppingCart: IShoppingCart;

  showAvailabilityErrorInCart: boolean;

  loaders: {
    isLoading: boolean;
    exceptionLoader: boolean;
  };

  extraLoaders: {
    isUpdating: boolean;
    isDeletingItem: boolean;
    isAddingNewItem: boolean;
  };

  exceptions: ICartException[];
}

const initialState: IShoppingCartState = {
  error: null,
  isActiveCart: false,
  showAvailabilityErrorInCart: false,
  loaders: { isLoading: false, exceptionLoader: false },

  exceptions: [],

  extraLoaders: {
    isUpdating: false,
    isDeletingItem: false,
    isAddingNewItem: false,
  },

  shoppingCart: {
    id: "",
    total: 0,
    user: null,
    items: [],
    currency: Currency.usd,
  },
};

const ShoppingCartSlice = createSlice({
  name: "shopping-cart",
  initialState,
  reducers: {
    deleteCartExceptions: (state) => {
      state.exceptions = [];
      state.showAvailabilityErrorInCart = false;
    },

    setShowAvailabilityErrorsInCart: (state) => {
      state.showAvailabilityErrorInCart = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCartByUserId.fulfilled,
        (state, action: PayloadAction<IShoppingCart>) => {
          state.loaders.isLoading = false;
          state.isActiveCart = true;
          state.shoppingCart = action.payload;
        }
      )
      .addCase(fetchCartByUserId.pending, (state) => {
        state.error = null;
        state.loaders.isLoading = true;
      })
      .addCase(fetchCartByUserId.rejected, (state, action) => {
        state.loaders.isLoading = false;
        state.isActiveCart = false;
        state.error = action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(updateCartItem.fulfilled, (state) => {
        state.extraLoaders.isUpdating = false;
      })
      .addCase(updateCartItem.pending, (state) => {
        state.error = null;
        state.extraLoaders.isUpdating = true;
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.extraLoaders.isUpdating = false;
        state.error = action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(addItemToCart.fulfilled, (state, action: any) => {
        state.extraLoaders.isAddingNewItem = false;
      })
      .addCase(addItemToCart.pending, (state) => {
        state.error = null;
        state.extraLoaders.isAddingNewItem = true;
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.extraLoaders.isAddingNewItem = false;
        state.error = action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(removeItemToCart.fulfilled, (state) => {
        state.extraLoaders.isDeletingItem = false;
      })
      .addCase(removeItemToCart.pending, (state) => {
        state.error = null;
        state.extraLoaders.isDeletingItem = true;
      })
      .addCase(removeItemToCart.rejected, (state, action) => {
        state.extraLoaders.isDeletingItem = false;
        state.error = action.error?.message || genericErrorMessage;
      });

    builder
      .addCase(
        checkCartavailability.fulfilled,
        (state, action: PayloadAction<ICartAvailabilityResponse>) => {
          const { cart, exceptions } = action.payload;

          state.shoppingCart = cart;
          state.exceptions = exceptions;
          state.loaders.exceptionLoader = false;

          state.shoppingCart.total = cart.total;
          state.shoppingCart.items = cart.cartItems as any;
        }
      )
      .addCase(checkCartavailability.pending, (state) => {
        state.error = null;
        state.loaders.exceptionLoader = true;
      })
      .addCase(checkCartavailability.rejected, (state, action) => {
        state.isActiveCart = false;
        state.loaders.exceptionLoader = false;
        state.error = action.error?.message || genericErrorMessage;
      });
  },
});

const genericErrorMessage = "a generic error occurred on the request";

export const { deleteCartExceptions, setShowAvailabilityErrorsInCart } =
  ShoppingCartSlice.actions;

export default ShoppingCartSlice.reducer;
