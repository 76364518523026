import { createAsyncThunk } from "@reduxjs/toolkit";

import { RequestError } from "interfaces/errors.interfaces";
import { OfferServices } from "services/offers.services";

/**
 * Get a list with all user favorites ordered by date (DESC)
 * and then fetch updated list
 */
export const getOffersList = createAsyncThunk(
  "offers/getOffers",
  async (_, thunkApi) => {
    try {
      return await OfferServices.getAllOffers();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);
