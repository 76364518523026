import { FC } from "react";
import { IFavorites } from "interfaces/favorites.interfaces";

import FavoriteCard from "components/molecules/favorite-card";

interface IProps {
  favorites: IFavorites[];
}

const FavoritesList: FC<IProps> = (props) => {
  const { favorites } = props;

  console.log(
    "%cFavoritesList.tsx line:13 favorites",
    "color: #26bfa5;",
    favorites
  );

  return (
    <>
      {favorites.map(({ product, id, productId }) => (
        <FavoriteCard
          key={id}
          favoriteId={id}
          product={product}
          productId={productId}
        />
      ))}
    </>
  );
};

export default FavoritesList;
