import { createSlice } from "@reduxjs/toolkit";

import {
  SnackbarType,
  ISnackbarState,
  snakcbarAction,
} from "./snackbars.interfaces";

const initialState: ISnackbarState = {
  show: false,
  message: "",
  snackbarType: SnackbarType.success,
  origin: { horizontal: "left", vertical: "bottom" },
};

const snackbarMessagesSlice = createSlice({
  name: "snackbar-messages",
  initialState,
  reducers: {
    showSnackBarMessages(state: ISnackbarState, action: snakcbarAction) {
      const { origin, message, snackbarType } = action.payload;

      state.show = true;
      state.message = message;
      state.snackbarType = snackbarType;

      if (origin) state.origin = origin;
    },

    hideSnackBarMessages(state: ISnackbarState) {
      state.show = false;
      state.message = "";
      state.origin = initialState.origin;
    },
  },
});

export const { showSnackBarMessages, hideSnackBarMessages } =
  snackbarMessagesSlice.actions;

export default snackbarMessagesSlice.reducer;
