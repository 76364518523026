import { useNavigate } from "react-router";
import { FC, Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector, useQuery } from "hooks";

import { useTheme } from "@mui/styles";
import { Grid, Paper, Theme, useMediaQuery } from "@mui/material";

import { scrollTop } from "utils/commons";
import { IProduct } from "interfaces/products.interfaces";
import { createNewHistoryRecord } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { fetchSingleProduct } from "store/products/products.actions";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";
import { setDisabledForMissingVariantSelected } from "store/products/products.slice";

import {
  getStateSingleProduct,
  getStateProductsErrors,
  getStateProductsHelpers,
  getStateProductsLoaders,
} from "store/products/products.selectors";

import MainMobileView from "./mobile/MainView";
import ProductImages from "./web/ProductImages";
import ProductDetails from "./web/ProductDetails";
import ProductFeatures from "./web/ProductFeatures";
import ProductBuySection from "./web/ProductBuySection";
import ProductVariations from "./web/ProductVariations";
import ShareSingleProduct from "./web/ShareSingleProduct";
import ProductInformation from "./web/ProductInformation";
import ProductDescription from "./web/ProductDescription";

import ShareButton from "./commons/ShareButton";
import CustomLayout from "components/atoms/simple-layout";
import BackdropLoader from "components/atoms/backdrop-loader";
import SnackbarMessage from "components/atoms/custom-snackbar";
import SimilarProductsList from "./commons/SimilarProductsList";

/**
 * This component dispatch the action to fetch a single-product by ASIN inside
 * useEffect and then render another components using that information.
 */

const SingleProduct: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const queryParams = useQuery();
  const pAsin = queryParams.get("asin");

  const single = useAppSelector(getStateSingleProduct);
  const { productError } = useAppSelector(getStateProductsErrors);
  const { productLoader } = useAppSelector(getStateProductsLoaders);
  const { fetchedProduct } = useAppSelector(getStateProductsHelpers);
  const { isLoggedIn } = useAppSelector((store) => store.userInformation);

  const theme: Theme = useTheme();
  const upToMiddleScreenSize = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!pAsin) navigate("/");

    scrollTop();

    dispatch(fetchSingleProduct(pAsin as string)).then((data: any) => {
      const product: IProduct = data.payload;

      if (product?.variations && product.variations?.length >= 2) {
        const results = product.variations
          .map((variation) =>
            variation.values.map((value) => value.asin || value.dpUrl)
          )
          .flat();

        const coincidences = results.filter((raw) => raw === pAsin).length;
        if (!coincidences) dispatch(setDisabledForMissingVariantSelected(true));
      }

      if (isLoggedIn && data?.payload?.asin)
        dispatch(
          createNewHistoryRecord({
            productId: pAsin as string,
            title: product.productTitle,
          })
        );
    });
  }, [dispatch, isLoggedIn, navigate, pAsin]);

  useEffect(() => {
    if (productError) {
      dispatch(
        createSnackbarMessage({
          snackbarType: SnackbarType.info,
          message:
            "No hemos podido encontrar tu producto, intenta con alguno diferente!",
        })
      );

      navigate(-1);
    }
  }, [dispatch, navigate, productError]);

  if (fetchedProduct && !upToMiddleScreenSize) return <MainMobileView />;

  return (
    <Fragment>
      {productLoader && <BackdropLoader />}

      {single && fetchedProduct && (
        <CustomLayout>
          <ShareButton productId={single.asin} title={single.productTitle} />

          <Paper sx={{ padding: 3 }}>
            <Grid
              container
              spacing={{ xs: 1, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item md={5} lg={7}>
                <ProductImages
                  mainImage={single.mainImage}
                  imageUrlList={single.imageUrlList}
                />

                <ShareSingleProduct
                  productId={single.asin}
                  title={single.productTitle}
                />
              </Grid>

              <Grid item md={4} lg={3}>
                <ProductInformation />
                <ProductVariations variations={single.variations} />
                <ProductFeatures />
              </Grid>

              <Grid item md={3} lg={2}>
                <ProductBuySection isLoggedIn={isLoggedIn} />
              </Grid>

              <Grid item md={12} lg={12} ml={1.5}>
                <ProductDetails
                  features={single.features}
                  productDetails={single.productDetails}
                />
              </Grid>
            </Grid>

            {single.productDescription && (
              <Grid item ml={1.5}>
                <ProductDescription description={single.productDescription} />
              </Grid>
            )}

            <Grid item md={12} ml={1.5}>
              <SimilarProductsList searchQuery={single.productTitle} />
            </Grid>
          </Paper>
        </CustomLayout>
      )}

      <SnackbarMessage
        open={!!productError}
        type={SnackbarType.error}
        message="No hemos podido encontrar el producto que buscabas, intentalo nuevamente"
      />
    </Fragment>
  );
};

export default SingleProduct;
