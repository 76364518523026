import { combineReducers } from "redux";

import {
  Action,
  ThunkAction,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import userReducer from "./user.slice";
import feesSlice from "./fees/fees.slice";
import ordersReducer from "./orders.slice";
import lscartReducer from "./lscart.slice";
import cartReducer from "./cart/cart.slice";
import paymentReducer from "./payment.slice";
import trackingReducer from "./tracking.slice";
import offersSlice from "./offers/offers.slice";
import globalConfigReducer from "./globals.slice";
import categoryReducer from "./categories.slice";
import statisticsReducer from "./statistics.slice";
import credentialsReducer from "./credentials.slice";
import checkoutReducer from "./checkout/checkout.slice";
import productsReducer from "./products/products.slice";
import keywordSearchReducer from "./keywords-search.slice";
import favoritesReducer from "./favorites/favorites.slice";
import snackbarMessageReducer from "./snackbars/snackbars.slice";
import shippingAddressesReducer from "./addresses/addresses.slice";

const rootReducer = combineReducers({
  fees: feesSlice,
  cart: cartReducer,
  offers: offersSlice,
  orders: ordersReducer,
  lscart: lscartReducer,
  payment: paymentReducer,
  products: productsReducer,
  tracking: trackingReducer,
  checkout: checkoutReducer,
  categories: categoryReducer,
  favorites: favoritesReducer,
  globals: globalConfigReducer,
  search: keywordSearchReducer,
  userInformation: userReducer,
  statistics: statisticsReducer,
  credentials: credentialsReducer,
  snackbars: snackbarMessageReducer,
  addresses: shippingAddressesReducer,
});

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
