import { ApartmentType } from "interfaces/shipping-addresses.interfaces";
import { DeliveryMethodsFees } from "interfaces/shopping-cart.interfaces";
import { ShippingAddress } from "store/checkout/checkout.interfaces";

export const getDeliveryMethod = (
  address: ShippingAddress | null
): DeliveryMethodsFees => {
  return address?.apartment.toLowerCase() ===
    ApartmentType.MONTEVIDEO.toLowerCase()
    ? DeliveryMethodsFees.MONTEVIDEO
    : DeliveryMethodsFees.OTHER_DEPARTMENTS;
};
