import { FC } from "react";
import { useAppDispatch } from "hooks";
import { ReactFacebookLoginInfo } from "react-facebook-login";

import GoogleLogin from "react-google-login";
import SocialMediaButton from "components/atoms/social-media-button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import {
  loginOrRegisterWithGoogle,
  loginOrRegisterWithFacebook,
} from "store/user.slice";

export const GoogleLoginComponent: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <GoogleLogin
      clientId={
        process.env.REACT_APP_GOOGLE_CLIENT_ID ??
        "324359881714-lv3avbh34ht6dtn40u4v13kig9j8mkd5.apps.googleusercontent.com"
      }
      cookiePolicy={"single_host_origin"}
      onSuccess={async (e: any) => {
        await dispatch(loginOrRegisterWithGoogle(e.accessToken));
      }}
      render={({ onClick, disabled }) => (
        <SocialMediaButton
          variant="google"
          onClick={onClick}
          disabled={disabled}
          sx={{ width: "100%" }}
          text="Regístrate con Google"
        />
      )}
    />
  );
};

export const FacebookLoginComponent: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? "364100512183050"}
      fields="name, email"
      render={({ onClick, isDisabled, isProcessing, isSdkLoaded }: any) => (
        <SocialMediaButton
          variant="facebook"
          text="Regístrate con Facebook"
          onClick={onClick}
          disabled={!(isDisabled || isProcessing || isSdkLoaded)}
          sx={{ width: "100%" }}
        />
      )}
      callback={async (e: ReactFacebookLoginInfo) => {
        await dispatch(loginOrRegisterWithFacebook(e.accessToken));
      }}
    />
  );
};
