import { FC, ReactChild } from "react";
import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";

import questonImg from "assets/icons/question.svg";

import { useAppSelector } from "hooks";
import { getWeight } from "utils/commons";
import { getStateSingleProduct } from "store/products/products.selectors";

const ProductFeatures: FC = () => {
  const { soldBy, fulfilledBy, asin, price, salePrice, productDetails } =
    useAppSelector(getStateSingleProduct);

  return (
    <Box mt={1} pr={1}>
      <TextDescription
        title="Disponibilidad"
        description={price || salePrice ? "En stock" : "Fuera de stock"}
      />

      {fulfilledBy && (
        <TextDescription title="Marca" description={fulfilledBy} />
      )}

      <TextDescription
        title="Peso de empaque"
        description={getWeight(productDetails) || "N/A"}
      />

      <TextDescription
        title="Producto con devolución"
        description={
          <img
            alt="vector"
            src={questonImg}
            style={{ opacity: 1, marginLeft: 6 }}
          />
        }
      />

      {soldBy && <TextDescription title="Producto de" description={soldBy} />}

      <TextDescription title="SKU" description={asin} />
    </Box>
  );
};

interface ITextDescription {
  title: string;
  description: string | ReactChild;
}

const TextDescription: FC<ITextDescription> = (props) => {
  const { title, description } = props;
  const { palette } = useTheme();

  return (
    <Typography
      fontSize={14}
      color={palette.text.secondary}
      sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
    >
      {title}:{" "}
      <Typography
        fontSize={14}
        component="span"
        color={palette.text.primary}
        sx={{ display: "flex", alignItems: "center", pl: 0.5 }}
      >
        {description}
      </Typography>
    </Typography>
  );
};

export default ProductFeatures;
