import { FC } from "react";
import { Box } from "@mui/system";
import { StarBorder } from "@mui/icons-material";
import { Rating, Typography } from "@mui/material";

interface IProps {
  text?: string;
  value: number;
  countReview: number;
}

/**
 * Component for showing custom rating start component based in MUI that takes three props
 *
 * @param {Number} value that indicates the stars (1 -5)
 * @param {Number} countReview number of the total reviews
 * @param {String} text (optional) custom informatión about rating
 *
 * @example
 * return (
 *   <RatingStars value={4.5} countReview={1345}/>
 * )
 */

const RatingStars: FC<IProps> = (props) => (
  <Box ml={0} sx={{ display: "flex", alignItems: "center" }}>
    <Rating
      readOnly
      size="small"
      precision={0.5}
      value={props.value}
      name="product-feedback"
      emptyIcon={<StarBorder style={{ opacity: 0.55 }} fontSize="inherit" />}
    />

    <Typography ml={1} variant="subtitle2" fontSize={13} color="#007171">
      {props.countReview} opiniones
    </Typography>

    {props.text && (
      <Typography ml={0.5} variant="subtitle2" fontSize={14}>
        ({props.text})
      </Typography>
    )}
  </Box>
);

export default RatingStars;
