import { styles } from "./styles";
import { relevant } from "./static.json";
import { FC, useEffect, useState } from "react";
import { genRandomId, scrollTop } from "utils/commons";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import mainImage from "assets/images/relevant-info.svg";
import CustomLayout from "components/atoms/simple-layout";
import secondImage from "assets/images/relevant-info-person.svg";

interface ICurrentInfo {
  title: string;
  description: { type: IInfoType; text: string }[];
}

enum IInfoType {
  p = "p",
  h5 = "h5",
}

const RelevantInformationPage: FC = () => {
  const s = styles();

  useEffect(() => {
    scrollTop();
  }, []);

  const [currentInfo, setCurrentInfo] = useState<ICurrentInfo>({
    title: relevant[0].title,
    description: relevant[0].description as any,
  });

  const onClick = (i: number) =>
    setCurrentInfo({
      title: relevant[i].title,
      description: relevant[i].description as any,
    });

  return (
    <CustomLayout maxWidth="xl">
      <Box
        component="img"
        src={mainImage}
        alt="relevant-info"
        className={s.img}
      />

      <Box
        component="img"
        src={secondImage}
        alt="relevant-info-persom"
        className={s.secondImage}
      />

      <Typography className={s.title} variant="h4">
        Información{" "}
        <Typography component="span" variant="h4" className={s.span}>
          relevante
        </Typography>
      </Typography>

      <Grid container spacing={{ xs: 1, md: 3 }} className={s.cardContainer}>
        <Grid item xs={12} md={4}>
          {relevant.map((item, i) => (
            <Typography
              key={genRandomId()}
              color="text.secondary"
              onClick={() => onClick(i)}
              className={`${s.optionTittle} ${
                currentInfo.title === item.title && s.titleSelected
              }`}
            >
              {item.title}
            </Typography>
          ))}
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h5" my={2}>
            {currentInfo.title}
          </Typography>

          {currentInfo.description.map((info) => (
            <Typography
              my={0.5}
              fontSize={13}
              key={genRandomId()}
              color="text.secondary"
              component={info.type as any}
              className={`${info.type === IInfoType.h5 && s.title2} ${
                info.type === IInfoType.p && s.paragraph
              }`}
            >
              {info.text}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </CustomLayout>
  );
};

export default RelevantInformationPage;
