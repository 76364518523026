import { FC } from "react";
import { useAppSelector } from "hooks";
import { Divider, Typography } from "@mui/material";

import RatingStars from "components/molecules/rating-stars";
import { getStateSingleProduct } from "store/products/products.selectors";

const ProductInformation: FC = () => {
  const single = useAppSelector(getStateSingleProduct);

  const showRating = () => {
    if (typeof single.productRating === "number") return single.productRating;
    return Number(single.productRating?.split(" ")[0]);
  };

  return (
    <>
      <Typography variant="h6" component="h1" mb={1}>
        {single.productTitle || single.productDescription || ""}
      </Typography>

      <Typography variant="caption" component="small" mt={0}>
        {single.manufacturer}
      </Typography>

      <RatingStars value={showRating() ?? 0} countReview={single.countReview} />

      <Divider sx={{ mt: 1, mb: 1 }} />
    </>
  );
};

export default ProductInformation;
