import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { RequestError } from "interfaces/errors.interfaces";
import { ICategory } from "interfaces/categories.interfaces";
import { CategoryServices } from "services/categories.services";

interface IFavoriteState {
  list: ICategory[];
  isLoading: boolean;
  isFetchched: boolean;
  error: RequestError | null;
}

const initialState: IFavoriteState = {
  list: [],
  error: null,
  isLoading: false,
  isFetchched: false,
};

export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async (_, thunkApi) => {
    try {
      return await CategoryServices.getAllCategories();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.isFetchched = true;
        state.list = action.payload as ICategory[];
      })
      .addCase(getAllCategories.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllCategories.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export default categoriesSlice.reducer;
