import { FC } from "react";
import { Box } from "@mui/system";
import { Paper, Typography } from "@mui/material";

interface IProps {
  title: string;
}

const CardLayoutWithTittle: FC<IProps> = (props) => {
  const { title, children } = props;

  return (
    <Box sx={{ mb: 3 }}>
      <Typography color="text.secondary" fontSize={14} sx={{ my: 1 }}>
        {title}
      </Typography>

      <Paper sx={{ borderRadius: "6px" }}>{children}</Paper>
    </Box>
  );
};

export default CardLayoutWithTittle;
