import { RootState } from "store";

export const getCheckout = (state: RootState) => state.checkout;
export const getCheckoutHelpers = (state: RootState) => state.checkout.helpers;

export const getCheckoutAddressInfo = (state: RootState) =>
  state.checkout.addressInformation;

export const getCheckoutUserInfo = (state: RootState) =>
  state.checkout.userInformation;

export const getIfShowConfirmationView = (state: RootState) =>
  state.checkout.showPurchaseConfirmation;
