import { SelectChangeEvent } from "@mui/material";
import CustomSelect from "components/atoms/custom-select";
import { useAppSelector } from "hooks";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthMode } from "./AuthOptions";
import useAuthOptions from "./useAuthOptions";

/**
 * Component that show a custom select with auth options (signin and signup)
 * @example
 * return <AuthButton />
 */

const AuthButton: FC = () => {
  const userInfo = useAppSelector((state) => state.userInformation);
  const { pathname } = useLocation();

  const { isLoggedInOptions, isNotLoggedInOptions } = useAuthOptions({
    userInfo,
  });

  const [value, setValue] = useState<AuthMode>(AuthMode.WELCOME);

  useEffect(() => {
    if (pathname.includes("/profile")) {
      setValue(AuthMode.MY_ACCOUNT);
    }
  }, [pathname]);

  const handleChange = (e: SelectChangeEvent) => {
    const val = e.target.value as AuthMode;
    setValue(val);
  };

  const selectOption = () =>
    userInfo.isLoggedIn ? isLoggedInOptions : isNotLoggedInOptions;

  return (
    <CustomSelect
      width={180}
      value={value}
      options={selectOption()}
      handleChange={handleChange}
      defaultValue={userInfo.isLoggedIn ? AuthMode.WELCOME : AuthMode.SIGNIN}
    />
  );
};

export default AuthButton;
