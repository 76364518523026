import { setGenericError } from "utils/errors";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestError } from "interfaces/errors.interfaces";
import { FavoriteException, IFavorites } from "interfaces/favorites.interfaces";

import {
  removeFavorite,
  getUserFavorites,
  createNewFavorite,
} from "./favorites.actions";

interface IFavoriteState {
  isFetched: boolean;
  isLoading: boolean;
  list: IFavorites[];
  error: RequestError | null;
  exceptions: FavoriteException[];
}

const initialState: IFavoriteState = {
  list: [],
  error: null,
  exceptions: [],
  isFetched: false,
  isLoading: false,
};

const favoritesSlice = createSlice({
  name: "user-favorites",
  initialState,
  reducers: {
    cleanFavoritesExceptions: (state) => {
      state.exceptions = [];
    },

    setFetchFavoriteStatus: (state, action: PayloadAction<boolean>) => {
      state.isFetched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserFavorites.fulfilled, (state, action) => {
        state.error = null;
        state.isFetched = true;
        state.isLoading = false;

        state.list = action.payload.favorites as IFavorites[];
        state.exceptions = action.payload.exceptions as FavoriteException[];
      })
      .addCase(getUserFavorites.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getUserFavorites.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isFetched = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(removeFavorite.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(removeFavorite.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(removeFavorite.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isFetched = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(createNewFavorite.fulfilled, (state) => {
        state.error = null;
        state.isLoading = false;
      })
      .addCase(createNewFavorite.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(createNewFavorite.rejected, (state, action: any) => {
        state.isLoading = false;
        state.isFetched = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export const { cleanFavoritesExceptions, setFetchFavoriteStatus } =
  favoritesSlice.actions;

export default favoritesSlice.reducer;
