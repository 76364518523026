import { FC } from "react";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

interface IOrderInfoProps {
  chip?: any;
  label: string;
  value?: string;
  align?: "center" | "left" | "right" | "top" | "bottom" | any;
  textAlign?: any;
}

const OrderInfo: FC<IOrderInfoProps> = ({
  label,
  value,
  chip,
  align = "center",
}) => (
  <Grid item xs={6} md={3}>
    <Box textAlign={align as any} px={{ md: 4, xs: 2 }}>
      <Typography color="text.disabled">{label}</Typography>
      {chip ? (
        <Box marginTop={1}>{chip}</Box>
      ) : (
        <Typography>{value}</Typography>
      )}
    </Box>
  </Grid>
);

export default OrderInfo;
