import { createAsyncThunk } from "@reduxjs/toolkit";
import { shoppingCartServices } from "services/shopping-cart.services";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

import { RequestError } from "interfaces/errors.interfaces";
import { FeesDto } from "interfaces/shopping-cart.interfaces";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
/**
 * A function that get fees that correspond to a cart
 */
export const getFees = createAsyncThunk(
  "cart/getFees",
  async (body: FeesDto, thunkApi) => {
    try {
      return await shoppingCartServices.getCurrentFees(body);
    } catch (error) {
      thunkApi.dispatch(
        createSnackbarMessage({
          snackbarType: SnackbarType.error,
          message: "Error al obtener los fees de tu carrito",
          origin: { horizontal: "center", vertical: "top" },
        })
      );
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);
