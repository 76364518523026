import { MouseEvent } from "react";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowDropDown, Menu } from "@mui/icons-material";

interface IMenuButton {
  ref: any;
  open: boolean;
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const CategoryMenuButton = (props: IMenuButton) => {
  const { open, handleClick, ref } = props;
  const s = useStyles();

  return (
    <Button
      ref={ref}
      variant="outlined"
      className={s.button}
      onClick={handleClick}
      aria-expanded={open ? "true" : undefined}
      aria-controls={open ? "basic-menu" : undefined}
    >
      <Menu sx={{ mr: 1 }} />
      Todas las categorias
      <ArrowDropDown />
    </Button>
  );
};

export default CategoryMenuButton;

const useStyles = makeStyles({
  button: {
    fontSize: 13,
    color: "white",
    textTransform: "unset",
    background: "#082330A3",
    border: "1px solid #0823304D",
    "&:hover": {
      background: "#082330A3",
    },
  },
});
