import { createAsyncThunk } from "@reduxjs/toolkit";

import { RequestError } from "interfaces/errors.interfaces";
import { FavoritesService } from "services/favorites.services";
import { CreateFavoriteDto } from "interfaces/favorites.interfaces";

/**
 * Get a list with all user favorites ordered by date (DESC)
 * and then fetch updated list
 */
export const getUserFavorites = createAsyncThunk(
  "user-favorites/getUserFavorites",
  async (_, thunkApi) => {
    try {
      return await FavoritesService.getFavoritesByUserId();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Remove a creted favorite for the user favorites list
 * and then fetch updated list
 */
export const removeFavorite = createAsyncThunk(
  "user-favorites/removeFavorite",
  async (favoriteId: string, thunkApi) => {
    try {
      await FavoritesService.removeFavorite(favoriteId);
      return thunkApi.dispatch(getUserFavorites());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Create a new favorite record with productId and userId
 * and then fetch updated list
 */
export const createNewFavorite = createAsyncThunk(
  "user-favorites/createNewFavorite",
  async (body: CreateFavoriteDto, thunkApi) => {
    try {
      await FavoritesService.createNewFavorite(body);
      return thunkApi.dispatch(getUserFavorites());
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);
