import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ICategory, ISubCategoryState } from "interfaces/categories.interfaces";

import { makeStyles } from "@mui/styles";
import { MenuItem, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material/";

interface ICategoryItem {
  name: string;
  englishKeyword: string;
  handleClose: () => void;
  subCategories: ICategory[];
  onClickSubcategories: (val: ISubCategoryState) => void;
}

const CategoryItem: FC<ICategoryItem> = (props) => {
  const {
    name,
    handleClose,
    subCategories,
    englishKeyword,
    onClickSubcategories,
  } = props;

  const s = useStyles();
  const navigate = useNavigate();

  const onClick = () => {
    handleClose();
    navigate(`/search?amz=${englishKeyword}&page=1`);
  };

  const onMouseOver = () =>
    onClickSubcategories({
      categoryName: name,
      list: subCategories || [],
      show: !!subCategories?.length && true,
    });

  return (
    <MenuItem
      sx={{ my: 0.5 }}
      onClick={onClick}
      className={s.root}
      onMouseOver={onMouseOver}
    >
      <Typography fontSize="14px">{name}</Typography>
      {subCategories && subCategories?.length ? (
        <ArrowForwardIos sx={{ color: "#777577", fontSize: 12, ml: 2 }} />
      ) : null}
    </MenuItem>
  );
};

export default CategoryItem;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": { background: "rgb(81 130 255 / 18%)" },
  },
}));
