import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { RequestError } from "interfaces/errors.interfaces";
import { IStatistic } from "interfaces/statistics.interfaces";
import { StatisticServices } from "services/statistics.services";

interface IStatisticsSlice {
  isLoading: boolean;
  mostViewed: IStatistic[];
  bestSellers: IStatistic[];
  error: RequestError | null;
}

const initialState: IStatisticsSlice = {
  error: null,
  mostViewed: [],
  bestSellers: [],
  isLoading: false,
};

/**
 * Get an array with all orders by userId (with JWT) and set in orders state
 */
export const getTrendingProducts = createAsyncThunk(
  "statistics/getTrendingProducts",
  async (_, thunkApi) => {
    try {
      return await StatisticServices.getTrendingProducts();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Get single order by user id and then set in singleOrder state
 */
export const getBestSellersProducts = createAsyncThunk(
  "statistics/getBestSellersProducts",
  async (_, thunkApi) => {
    try {
      return await StatisticServices.getBestSellersProducts();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrendingProducts.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.mostViewed = action.payload as IStatistic[];
      })
      .addCase(getBestSellersProducts.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.bestSellers = action.payload as IStatistic[];
      })

      .addCase(
        getTrendingProducts.pending || getBestSellersProducts.pending,
        (state) => {
          state.error = null;
          state.isLoading = true;
        }
      )
      .addCase(
        getTrendingProducts.rejected || getBestSellersProducts.rejected,
        (state, action: any) => {
          state.isLoading = false;
          state.error = setGenericError(action.payload);
        }
      );
  },
});

export default statisticsSlice.reducer;
