import { FC } from "react";
import { DashboardType } from "interfaces/dashboard.interfaces";

import Dashboard from "components/organisms/dashboard";

const ProfileWalletView: FC = () => {
  return (
    <Dashboard current={DashboardType.wallet}>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi atque
      ea quis modi recusandae! Ex iusto aliquid, blanditiis, maxime itaque id
      minima adipisci sint repellat, dolor autem quibusdam omnis voluptate.
    </Dashboard>
  );
};

export default ProfileWalletView;
