import { createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { RequestError } from "interfaces/errors.interfaces";
import { IShippingAddress } from "interfaces/shipping-addresses.interfaces";

import {
  getShippingAddresses,
  removeShippingAddress,
  createNewShippingAddress,
} from "./addresses.actions";

interface IAddressesState {
  isLoading: boolean;
  isFetchched: boolean;
  list: IShippingAddress[];
  error: RequestError | null;
  defaultAddress?: IShippingAddress;
}

const initialState: IAddressesState = {
  list: [],
  error: null,
  isLoading: false,
  isFetchched: false,
};

const shippingAddressesSlice = createSlice({
  name: "shipping-addresses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShippingAddresses.fulfilled, (state, action) => {
        const addresses = action.payload as IShippingAddress[];
        state.error = null;
        state.list = addresses;
        state.isLoading = false;
        state.isFetchched = true;

        addresses.forEach((address) => {
          if (address.defaultAddress) state.defaultAddress = address;
        });
      })
      .addCase(getShippingAddresses.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getShippingAddresses.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(createNewShippingAddress.fulfilled, (state, action) => {
        const address = action.payload as IShippingAddress;

        state.error = null;
        state.isLoading = false;
        state.list.push(address);
      })
      .addCase(createNewShippingAddress.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(createNewShippingAddress.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });

    builder
      .addCase(removeShippingAddress.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(removeShippingAddress.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export default shippingAddressesSlice.reducer;
