import { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { scrollTop } from "utils/commons";
import { updateUser } from "store/user.slice";
import { configPayment } from "store/payment.slice";
import { getCartState } from "store/cart/cart.selectors";
import { cleanOrderError, createNewOrder } from "store/orders.slice";

import { getCheckout } from "store/checkout/checkout.selectors";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";
import { createNewShippingAddress } from "store/addresses/addresses.actions";

import {
  DeliveryMethods,
  PaymentMethodTypes,
} from "store/checkout/checkout.interfaces";

import {
  setPaymentMethodType,
  toogleConfirmPurchaseView,
} from "store/checkout/checkout.slice";

import {
  FrancheseType,
  DeliveryMethodsFees,
} from "interfaces/shopping-cart.interfaces";

import CustomModal from "components/atoms/custom-modal";
import CustomButton from "components/atoms/custom-button";
import SistarbancForm from "components/atoms/sistarbanc-form";
import PaymentMethodsList from "components/molecules/payment-methods-list";
import { getDeliveryMethod } from "utils/getDeliveryMethod";
import { BodyShippingAddress } from "interfaces/shipping-addresses.interfaces";

const PaymentMethods: FC = () => {
  const dispatch = useAppDispatch();

  const { userInformation, addressInformation, paymentMethods, useFranchise } =
    useAppSelector(getCheckout);

  const { shoppingCart } = useAppSelector(getCartState);
  const { config } = useAppSelector((state) => state.payment);
  const { currency } = useAppSelector((state) => state.globals);
  const { user } = useAppSelector((state) => state.userInformation);

  const { error } = useAppSelector((state) => state.orders);

  const [loading, setLoading] = useState<boolean>(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodTypes>(
    PaymentMethodTypes.SISTARBANC_SCOTIABANK
  );

  const handleCloseModal = useCallback(
    () => dispatch(toogleConfirmPurchaseView(false)),
    [dispatch]
  );

  useEffect(() => {
    scrollTop();
  });

  useEffect(() => {
    if (error) {
      dispatch(
        createSnackbarMessage({
          origin: { horizontal: "center", vertical: "top" },
          message: `Ha ocurrido un error inesperado creando tu orden, por favor intentalo nuevamente. ${error.message}`,
          snackbarType: SnackbarType.error,
        })
      );

      setLoading(false);
      handleCloseModal();
      dispatch(cleanOrderError());
    }
  }, [dispatch, error, handleCloseModal]);

  const onClick = async () => {
    dispatch(setPaymentMethodType(paymentMethod as PaymentMethodTypes));

    if (!userInformation || !paymentMethod || !addressInformation.address)
      return;

    try {
      setLoading(true);

      /** enviamos todos los requests necesarios para crear la orden de compra */
      await dispatch(updateUser(userInformation));

      if (addressInformation.addToUserAddresses)
        await dispatch(
          createNewShippingAddress(
            addressInformation.address as BodyShippingAddress
          )
        );

      const method =
        addressInformation.deliveryMethod === DeliveryMethods.PICKUP
          ? DeliveryMethodsFees.PICKUP
          : getDeliveryMethod(addressInformation.address);

      dispatch(
        createNewOrder({
          userId: user?.id,
          currency: currency,
          deliveryMethod: method,
          cartId: shoppingCart.id,
          amount: shoppingCart.total,
          address: addressInformation.address,
          customerEmail: user?.email as string,

          useFranchese:
            shoppingCart.total < 200
              ? (useFranchise as FrancheseType)
              : FrancheseType.NOT_APPLICABLE,
        })
      ).then(async (response: any) => {
        try {
          await dispatch(
            configPayment({
              paymentMethod: paymentMethod,
              orderId: response?.payload?.payload.id as string,
            })
          );
        } catch (err) {
          setLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Box px={{ md: 2 }} py={1}>
      <CustomModal
        open
        size="big"
        showCloseButton
        customSize={980}
        handleClose={handleCloseModal}
      >
        <Typography mb={3} fontSize={18}>
          Método de pago
        </Typography>

        <PaymentMethodsList
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />

        <CustomButton
          variant="primary"
          onClick={onClick}
          loading={loading}
          text="Continuar"
          sx={{ width: { sx: "100%", md: 240 }, mt: 3 }}
        />
      </CustomModal>

      {paymentMethods?.includes("sistarbanc") && config ? (
        <SistarbancForm paymentConfig={config} />
      ) : null}
    </Box>
  );
};

export default PaymentMethods;
