import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllHistoryRecords } from "store/user.slice";
import { parseProductInformation, scrollTop } from "utils/commons";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

import CustomLayout from "components/atoms/simple-layout";
import ProductCard from "components/molecules/product-card";
import BackdropLoader from "components/atoms/backdrop-loader";

const NavigationView: FC = () => {
  const dispatch = useAppDispatch();

  const { navigationHistory, isLoggedIn, isLoading } = useAppSelector(
    (state) => state.userInformation
  );

  useEffect(() => {
    if (!navigationHistory.length && isLoggedIn)
      dispatch(getAllHistoryRecords());
  }, [dispatch, isLoggedIn, navigationHistory.length]);

  useEffect(() => {
    scrollTop();
  }, []);

  if (isLoading) return <BackdropLoader />;

  return (
    <CustomLayout maxWidth="xl">
      <Box my={{ md: 8, xs: 0 }} />

      <Typography variant="h6" color="text.secondary" mb={1}>
        Tu historial
      </Typography>

      {!navigationHistory.length && (
        <Box
          height="40vh"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="body1" align="center">
            Aún no viste publicaciones.
          </Typography>

          <Typography align="center" fontSize={12}>
            ¡Empezá a descubrir todo lo que tenemos para vos!
          </Typography>
        </Box>
      )}

      <Grid container>
        {navigationHistory.map((raw) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={raw.id}>
            <ProductCard
              product={parseProductInformation(raw.product, raw.productId)}
            />
          </Grid>
        ))}
      </Grid>
    </CustomLayout>
  );
};

export default NavigationView;
