import { useAppDispatch, useAppSelector } from "hooks";
import { FC, useState, MouseEvent, useEffect } from "react";

import { getAllCategories } from "store/categories.slice";
import { ISubCategoryState } from "interfaces/categories.interfaces";

import { Box } from "@mui/system";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CategoryItem from "./CategoryItem";
import SubcategoryItem from "./SubcategoryItem";
import CategoryMenuButton from "./CategoryMenuButton";
import useDetectClickOut from "hooks/useDetectClickOut";

const DropdownCategories: FC = () => {
  const s = useStyles();
  const dispatch = useAppDispatch();
  const { nodeRef, setShow, show, triggerRef } = useDetectClickOut(false);

  const { isFetchched, list } = useAppSelector((state) => state.categories);

  const [subcategories, setSubcategories] = useState<ISubCategoryState>({
    list: [],
    show: false,
    categoryName: "",
  });

  useEffect(() => {
    if (!isFetchched) dispatch(getAllCategories());
  }, [dispatch, isFetchched]);

  const handleClose = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();

    setShow(false);
    setSubcategories({ list: [], show: false, categoryName: "" });
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setShow(!show);

  const onClickSubcategories = (val: ISubCategoryState) =>
    setSubcategories({ ...val });

  return (
    <>
      <CategoryMenuButton
        open={show}
        ref={triggerRef}
        handleClick={handleClick}
      />

      {show && (
        <Box
          ref={nodeRef}
          onMouseLeave={handleClose}
          className={`${s.root} ${!subcategories.show ? s.simpleRoot : null}`}
        >
          <Box
            className={`${s.categories} ${
              !subcategories.show ? s.fullWidth : null
            }`}
          >
            {list &&
              list.map((category) => (
                <CategoryItem
                  {...category}
                  key={category.id}
                  handleClose={handleClose}
                  onClickSubcategories={onClickSubcategories}
                />
              ))}
          </Box>

          <SubcategoryItem category={subcategories} handleClose={handleClose} />
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    top: 45,
    left: 0,
    zIndex: 10,
    width: "90%",
    display: "flex",
    borderRadius: 6,
    overflow: "hiiden",
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 24px 48px rgba(6, 25, 33, 0.08)",
  },

  simpleRoot: { width: "auto" },
  fullWidth: { width: "100%" },

  categories: {
    background: "#fafafa",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    padding: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
}));

export default DropdownCategories;
