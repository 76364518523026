import { FC } from "react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { getCartState } from "store/cart/cart.selectors";

import CustomAlert from "components/atoms/custom-alert";
import CustomButton from "components/atoms/custom-button";

const EmptyCart: FC = () => {
  const navigate = useNavigate();
  const goHome = () => navigate("/");

  const { showAvailabilityErrorInCart, exceptions } =
    useAppSelector(getCartState);

  return (
    <Box
      sx={{
        display: "flex",
        mt: { md: 6, xs: 2 },
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4" my={3} align="center">
        ¡Hay un carrito que llenar!
      </Typography>

      <Typography variant="body1" align="center">
        Actualmente no tenés productos en tu carrito.
      </Typography>

      <Typography align="center" fontSize={12}>
        Buscá entre millones de productos que nosotros los llevamos a tu puerta
        con hasta 18 cuotas sin interés
      </Typography>

      <CustomButton
        onClick={goHome}
        variant="secondary"
        text="Buscar Productos"
        sx={{ width: { sx: "100%", md: 240 }, mt: 3, mx: "auto" }}
      />

      <Box
        sx={{
          mx: 2,
          mt: 4,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {showAvailabilityErrorInCart && exceptions && exceptions.length
          ? exceptions.map((exception) => (
              <CustomAlert
                severity="warning"
                title="Importante"
                message={exception.message}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

export default EmptyCart;
