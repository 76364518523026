import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme: Theme) => ({
  img: {
    left: 0,
    zIndex: -1,
    height: 300,
    width: "100%",
    objectFit: "cover",
    position: "absolute",
    [theme.breakpoints.down("md")]: { display: "none" },
  },

  secondImage: {
    right: "10%",
    objectFit: "cover",
    position: "absolute",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: { display: "none" },
  },

  span: {
    fontWeight: "bold",
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationColor: theme.palette.secondary.main,
  },

  title: {
    color: "#061B5E",
    paddingBottom: 0,
    padding: theme.spacing(12),
    [theme.breakpoints.down("md")]: { padding: theme.spacing(3) },
  },

  cardContainer: {
    marginTop: 180,
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: { marginTop: theme.spacing(1) },
  },

  optionTittle: {
    cursor: "pointer",
    padding: theme.spacing(0.5),
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },

  titleSelected: {
    fontWeight: "bold",
    textDecoration: "underline",
    textDecorationStyle: "solid",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },

  title2: {
    fontWeight: "bold",
    margin: `${theme.spacing(2)} 0`,
  },

  paragraph: { margin: `${theme.spacing(2)} 0` },

  order: {
    width: "50%",
    minWidth: 576,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: { width: "100%", minWidth: 0 },
  },

  feesPaper: {
    width: "50%",
    padding: theme.spacing(3),
    margin: `${theme.spacing(12)} auto 0 auto`,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      width: "100%",
      padding: theme.spacing(2),
    },
  },
}));
