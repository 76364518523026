export const localStorageAPI = {
  getItem: (name: string, withPayload?: boolean) => {
    const found: string | null = localStorage.getItem(name);
    return found ? (withPayload ? JSON.parse(found) : found) : null;
  },

  parseJwtPayload: (token: string) => {
    if (!token) return "";

    const base64Payload = token.split(".")[1];
    const payload = Buffer.from(base64Payload, "base64");
    return JSON.parse(payload.toString());
  },

  removeItem: (name: string): void => {
    return localStorage.removeItem(name);
  },
};
