import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";

import { Box } from "@mui/system";
import theme from "styles/muiTheme";
import { Add, Remove } from "@mui/icons-material";
import { Collapse, Divider, Grid, Paper, Typography } from "@mui/material";

import OrderItem from "./OrderItem";
import OrderInfo from "components/atoms/order-info";
import OrderStatus from "components/atoms/order-status";

import TrackingNode, {
  LastTrackingNode,
  FirstTrackingNode,
  TrackingNodeContainer,
} from "components/atoms/tracking-node";

import { getOrderId } from "utils/commons";
import { IOrder, OrderStatusEnum } from "interfaces/orders.interfaces";

interface IOrderCardProps {
  order: IOrder;
}

const OrderCard: FC<IOrderCardProps> = ({ order }) => {
  const [showMore, setShowMore] = useState(false);
  const [mappedOrderEvents, setMappedOrderEvents] = useState<any>([]);

  const getDate = (order: IOrder) => {
    const date = DateTime.fromISO(order.dateAdded.toString());
    return date.toFormat("dd/LL/yyyy");
  };
  useEffect(() => {
    const key = "code";
    const mappedArray = [
      ...new Map(order.events.map((evt) => [evt[key], evt])).values(),
    ];
    setMappedOrderEvents(mappedArray);
  }, [order]);

  return (
    <Paper sx={{ pt: { md: 4, xs: 2 }, mb: 2, pb: 2.75 }}>
      <Grid container>
        <OrderInfo
          label="Fecha:"
          value={getDate(order)}
          align={{ xs: "left", md: "center" }}
        />

        <OrderInfo
          label="Nº de orden:"
          value={getOrderId(order).toString()}
          align={{ xs: "right", md: "center" }}
        />

        <OrderInfo
          label="Estatus de orden:"
          chip={<OrderStatus order={order} />}
          align={{ xs: "left", md: "center" }}
        />

        <OrderInfo
          label="Importe Total:"
          value={`$${order.amount.toString()}`}
          align={{ xs: "right", md: "center" }}
        />
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container paddingX={3} columnSpacing={3} rowSpacing={2}>
        {order.cart.items?.map((item) => {
          let status;

          if (order.amazonTrackigs.length) {
            order.amazonTrackigs.forEach((track) =>
              track.products.forEach((prod) => {
                if (prod.asin === item.id) status = track.status;
              })
            );
          }

          return <OrderItem status={status} item={item} key={item.id} />;
        })}
      </Grid>

      <Collapse in={showMore}>
        <Divider sx={{ marginY: 2 }} />

        <Box overflow="auto">
          <Box display="flex" marginTop="10px">
            <TrackingNodeContainer
              label="Pendiente por pago"
              date={new Date(order.dateAdded.toString())}
            >
              <FirstTrackingNode active />
            </TrackingNodeContainer>

            {mappedOrderEvents.map(
              (
                event: {
                  visible: any;
                  description: string;
                  date: { toString: () => string | number | Date };
                  code: any;
                },
                index: number
              ) => {
                if (!event.visible) return null;

                if (
                  OrderStatusEnum.COMPLETED === order.status &&
                  index + 1 === mappedOrderEvents.length
                ) {
                  return (
                    <TrackingNodeContainer
                      label={event.description}
                      date={new Date(event.date.toString())}
                      key={event.description + event.date + event.code}
                    >
                      <LastTrackingNode active />
                    </TrackingNodeContainer>
                  );
                }

                return (
                  <TrackingNodeContainer
                    label={event.description}
                    date={new Date(event.date.toString())}
                    key={event.description + event.date + event.code}
                  >
                    <TrackingNode active />
                  </TrackingNodeContainer>
                );
              }
            )}

            {OrderStatusEnum.COMPLETED !== order.status ? (
              <TrackingNodeContainer label="Entregada">
                <LastTrackingNode />
              </TrackingNodeContainer>
            ) : null}
          </Box>
        </Box>
      </Collapse>

      <Divider sx={{ marginTop: 2, marginBottom: 2.75 }} />

      <Box
        display="flex"
        justifyContent="center"
        sx={{ cursor: "pointer" }}
        onClick={() => setShowMore(!showMore)}
      >
        {!showMore ? (
          <Add sx={{ color: theme.palette.secondary.main }} />
        ) : (
          <Remove sx={{ color: theme.palette.secondary.main }} />
        )}

        <Typography
          marginLeft={1.6}
          fontSize="14px"
          fontWeight={500}
          color={theme.palette.secondary.main}
        >
          {!showMore ? "Ver más información" : "Ver menos información"}
        </Typography>
      </Box>
    </Paper>
  );
};

export default OrderCard;
