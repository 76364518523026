import { FC } from "react";
import { Box } from "@mui/system";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  WhatsApp as WhatsAppIcon,
  Instagram as InstagramIcon,
  FacebookOutlined as FacebookIcon,
} from "@mui/icons-material/";

interface IProps {
  sx?: any;
}

/**
 * Component that displays a list with social media icons
 * @param {IProps} props
 * @example
 * return <SocialMediaIcons sx={...style}/>
 */

const SocialMediaIcons: FC<IProps> = (props) => {
  const s = useStyles();

  const goToSocialMedia = (socialMedia: string) => {
    switch (socialMedia) {
      case "facebook":
        window.open(
          "https://www.facebook.com/profile.php?id=100080672015505/",
          "_blank"
        );
        break;

      case "instagram":
        window.open("https://www.instagram.com/puntomall_uy/", "_blank");
        break;

      case "whatsapp":
        window.open("https://wa.me/59897814037", "_blank");
        break;

      default:
        break;
    }
  };

  return (
    <Box className={`${props.sx} ${s.main}`}>
      <InstagramIcon
        className={s.icon}
        sx={{ color: "text.secondary" }}
        onClick={() => goToSocialMedia("instagram")}
      />

      <FacebookIcon
        className={s.icon}
        sx={{ color: "text.secondary" }}
        onClick={() => goToSocialMedia("facebook")}
      />

      <WhatsAppIcon
        className={s.icon}
        sx={{ color: "text.secondary" }}
        onClick={() => goToSocialMedia("whatsapp")}
      />
    </Box>
  );
};

export default SocialMediaIcons;

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  icon: {
    cursor: "pointer",
    margin: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    [theme.breakpoints.down("md")]: { fontSize: 36 },
  },
}));
