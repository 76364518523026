import { FC } from "react";
import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";

interface IProps {
  open: boolean;
  message: string;
  type: SnackbarType;
  setOpen?: (val: boolean) => void;
  origin?: SnackbarOrigin | undefined;
}

const SnackbarMessage: FC<IProps> = (props) => {
  const { open, setOpen, message, type, origin } = props;

  return (
    <Snackbar
      open={open}
      anchorOrigin={origin}
      autoHideDuration={3000}
      onClose={() => setOpen && setOpen(false)}
    >
      <Alert
        sx={{ width: "100%" }}
        severity={type || SnackbarType.success}
        onClose={() => setOpen && setOpen(false)}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
