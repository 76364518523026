import { FC, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";

import ProductCard from "components/molecules/product-card";

import { getTrendingProducts } from "store/statistics.slice";
import { IStatistic } from "interfaces/statistics.interfaces";
import { genRandomId, parseProductInformation } from "utils/commons";

const ViewMoreProducts: FC = () => {
  const dispatch = useAppDispatch();

  const { bestSellers, isLoading } = useAppSelector(
    (state) => state.statistics
  );

  useEffect(() => {
    if (!bestSellers.length) dispatch(getTrendingProducts());
  }, [dispatch, bestSellers.length]);

  const parseStatisticsToProductList = (statistic: IStatistic[]) =>
    statistic
      .map((a) => parseProductInformation(a.product, a.productId))
      .slice(0, 10);

  if (isLoading) return null;

  return (
    <Box my={3}>
      <Typography variant="h5" color="text.secondary" mx={1}>
        También te puede interesar
      </Typography>

      <Grid container maxWidth="xl">
        {parseStatisticsToProductList(bestSellers).map((product) => (
          <Grid item sm={6} md={4} lg={2.4} xs={12} key={genRandomId()}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ViewMoreProducts;
