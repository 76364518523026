import { FC } from "react";
import { Grid, useTheme, Container } from "@mui/material";

import HeaderText from "./HeaderText";
import DropdownCategories from "../dropdown-categories";

/**
 * A simple header component with some options and links.
 * @example
 * return <Header />
 */

const Header: FC = () => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        paddingY: 1,
        display: "flex",
        minWidth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.primary.light,
      }}
    >
      <Grid
        container
        maxWidth="lg"
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <DropdownCategories />

        <Grid container item xs={6}>
          <Grid item xs={3} />

          <HeaderText
            end
            xs={3}
            label="¿Cómo funciona?"
            to="information/how-works"
          />
          <HeaderText
            end
            xs={3}
            to="information/fees"
            label="Costos y Tarifas"
          />

          <HeaderText
            xs={3}
            end
            to="information/relevant"
            label="Información relevante"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
