import { Container, Typography } from "@mui/material";
import Dashboard from "components/organisms/dashboard";
import { useAppDispatch, useAppSelector } from "hooks";
import { DashboardType } from "interfaces/dashboard.interfaces";
import { IOrder, OrderStatusEnum } from "interfaces/orders.interfaces";
import { FC, useEffect, useState } from "react";
import { getAllOrders } from "store/orders.slice";
import { scrollTop } from "utils/commons";
import OrderCard from "./components/OrderCard";

const ProfileOrdersView: FC = () => {
  const dispatch = useAppDispatch();
  const { orders } = useAppSelector((state) => state.orders);
  const [filteredOrders, setFilteredOrders] = useState<IOrder[]>([]);
  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    const newOrders: IOrder[] = orders.filter(
      (order) => order.status !== OrderStatusEnum.PROCESSING
    );
    setFilteredOrders(newOrders);
  }, [orders]);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <Dashboard current={DashboardType.orders}>
      <Container maxWidth="lg" sx={{ p: { xs: 0 } }}>
        <Typography fontSize={18} fontWeight={500}>
          Mis órdenes
        </Typography>

        <Typography mb={2} color="#777577" fontSize={14} fontWeight={500}>
          {filteredOrders.length}{" "}
          {filteredOrders.length === 1 ? "orden" : "órdenes"}
        </Typography>

        {filteredOrders.map((order) => (
          <OrderCard order={order} key={order.id} />
        ))}
      </Container>
    </Dashboard>
  );
};

export default ProfileOrdersView;
