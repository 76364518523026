import { FC } from "react";
import { FieldValues, useForm } from "react-hook-form";

import Input from "components/atoms/input";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useAppDispatch } from "hooks";
import { getSingleOrder } from "store/orders.slice";

interface IOrderModal {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
}

const OrderModal: FC<IOrderModal> = (props) => {
  const { showModal, setShowModal } = props;

  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClose = () => setShowModal(false);

  const onSubmit = (data: FieldValues) => {
    dispatch(getSingleOrder(data.orderId));
    handleClose();
  };

  return (
    <div>
      <Dialog open={showModal}>
        <DialogTitle>SkyPostal Panel</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Ingrese el ID de la orden que desea actualizar con skypostal
          </DialogContentText>

          <Input
            name="orderId"
            control={control}
            error={errors.orderId}
            label=""
            rules={{
              required: "Campo obligatorio",
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderModal;
