import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";

import { parseAxiosErrorObject } from "utils/errors";

import {
  CreateFavoriteDto,
  GetFavoritesResponse,
} from "interfaces/favorites.interfaces";

export const FavoritesService = {
  getFavoritesByUserId: async (): Promise<GetFavoritesResponse> => {
    const favorites: AxiosResponse<GetFavoritesResponse> =
      await puntoMallAPI.get("/favorites");

    return favorites.data;
  },

  /**
   * Create a new favorite record using productId and user token (userId)
   * @param {BodyShippingAddress} body
   * @returns created shipping address
   */
  createNewFavorite: async (body: CreateFavoriteDto): Promise<void> => {
    try {
      const response: AxiosResponse<void> = await puntoMallAPI.post(
        "/favorites",
        body
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },

  /**
   * Delete an existing user favorite record by id and user token (userId)
   * @param {string} id
   */
  removeFavorite: async (id: string): Promise<void> => {
    try {
      const response: AxiosResponse<void> = await puntoMallAPI.delete(
        `/favorites/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response.data);
    }
  },
};
