import { FC, useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks";

import { Grid } from "@mui/material";
import { getProfile, updateUser } from "store/user.slice";

import ProfileItem from "./ProfileItem";
import Input from "components/atoms/input";

const UpdateName: FC = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const { user, isLoading } = useAppSelector((state) => state.userInformation);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (data: FieldValues) => {
      dispatch(
        updateUser({
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          secondLastName: data.secondLastName,
        })
      );

      setShowModal(false);
    },
    [dispatch]
  );

  const handleCloseFunction = () => reset();

  return (
    <ProfileItem
      size="big"
      loading={isLoading}
      name="Nombres y apellidos"
      title="Modificar nombres y apellidos"
      handleCloseFunction={handleCloseFunction}
      value={
        user
          ? `${user.firstName} ${user.middleName ?? ""} ${user.lastName}${
              user.secondLastName ? " " + user.secondLastName : ""
            }`
          : ""
      }
      open={showModal}
      setOpen={setShowModal}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} md={6}>
        <Input
          name="firstName"
          defaultValue={user?.firstName}
          control={control}
          error={errors.firstName}
          label="Primer Nombre (Idéntico a tu CI)"
          rules={{ required: "Campo obligatorio" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="middleName"
          defaultValue={user?.middleName}
          control={control}
          error={errors.middleName}
          label="Segundo Nombre (Idéntico a tu CI)"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="lastName"
          defaultValue={user?.lastName}
          control={control}
          error={errors.lastName}
          label="Primer Apellido (Idéntico a tu CI)"
          rules={{ required: "Campo obligatorio" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          name="secondLastName"
          defaultValue={user?.secondLastName}
          control={control}
          error={errors.secondLastName}
          label="Segundo Apellido (Idéntico a tu CI)"
        />
      </Grid>
    </ProfileItem>
  );
};

export default UpdateName;
