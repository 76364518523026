import { FC, useEffect } from "react";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

import { parseProductInformation, shuffleArray } from "utils/commons";

import StyledProductList from "components/molecules/syled-product-list";
import CustomMobileProductList from "components/molecules/custom-mobile-product-list";

import { useAppDispatch, useAppSelector } from "hooks";
import { getOffersList } from "store/offers/offers.actions";
import { getOfferState } from "store/offers/offers.selectors";

const OffersList: FC = () => {
  const dispatch = useAppDispatch();
  const { breakpoints } = useTheme();

  const downToMiddleScreenSize = useMediaQuery(breakpoints.down("md"));
  const { isFetched, isLoading, offers } = useAppSelector(getOfferState);

  useEffect(() => {
    if (!offers.length || !isFetched) dispatch(getOffersList());
  }, [dispatch, isFetched, offers.length]);

  if (isLoading) return null;

  const products = shuffleArray(
    offers.map((offer) =>
      parseProductInformation(offer.product, offer.productId)
    )
  ).slice(0, 5);

  if (downToMiddleScreenSize)
    return (
      <CustomMobileProductList
        title="Ofertas"
        products={products}
        urlTo={{ title: "Ver todas →", url: "offers" }}
      />
    );

  return (
    <StyledProductList
      title="Ofertas"
      products={products}
      urlTo={{ title: "Ver todas →", url: "offers" }}
    />
  );
};

export default OffersList;
