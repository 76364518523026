import { useAppSelector } from "hooks";
import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface IProps {
  children: ReactElement<any, any> | null;
}

/**
 * Component that check if user is autentitied. If the result is true,
 * then reder the component, if not navigate to signin
 * @param props
 * @returns
 */

const RequiredAuthRoutes: FC<IProps> = (props) => {
  const location = useLocation();

  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  return isLoggedIn ? (
    props.children
  ) : (
    <Navigate to="/auth/signin" replace state={location.pathname} />
  );
};

export default RequiredAuthRoutes;
