import { FC } from "react";
import { Box, Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Grid, Radio, Typography } from "@mui/material";

import { getApartmentLabel } from "utils/forms/aparments.options";
import { IShippingAddress } from "interfaces/shipping-addresses.interfaces";

interface IProps {
  selected: string;
  addresses: IShippingAddress[];
  setSelected: (id: string) => void;
}

const AddressesCardList: FC<IProps> = (props) => {
  const s = useStyles();
  const { addresses, selected, setSelected } = props;

  return (
    <Grid container spacing={2}>
      {addresses.map((a) => (
        <Grid item xs={12} lg={6} key={a.id}>
          <Box
            onClick={() => setSelected(a.id)}
            className={`${s.card} ${
              selected === a.id ? s.selectedButton : null
            }`}
          >
            <Box>
              <Typography>
                {a.address}. {a.doorNumber}
              </Typography>
              <Typography>Teléfono: {a.phoneNumber} </Typography>
              <Typography>
                {a.neighborhood} ({a.zipCode}){". "}
                {getApartmentLabel(a.apartment)}, Uruguay
              </Typography>
            </Box>

            <Radio
              checked={selected === a.id}
              classes={{ root: s.radio, checked: s.checked }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default AddressesCardList;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    borderRadius: 8,
    cursor: "pointer",
    alignItems: "center",
    position: "relative",
    background: "#FFFFFF",
    border: " 2px solid #EEEEEE",
    textTransform: "capitalize",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },

  selectedButton: {
    borderColor: "#FB8200",
    background: "rgba(251, 130, 0, 0.05)",
  },

  radio: { "&$checked": { color: "#FB8200" } },

  checked: {},
}));
