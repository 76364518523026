import { FC, useState } from "react";
import { useAppDispatch } from "hooks";

import {
  List,
  Collapse,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import { ExpandLess, ExpandMore, Paid as PaidIcon } from "@mui/icons-material";

import { setCurrency } from "store/globals.slice";
import { Currency } from "interfaces/shopping-cart.interfaces";

interface IProps {
  toggleView: (val: boolean) => void;
}

const MobileCurrencyOptions: FC<IProps> = (props) => {
  const { toggleView } = props;
  const dispatch = useAppDispatch();

  const toggleCurrencyValue = (currency: Currency) => {
    dispatch(setCurrency(currency));
    toggleView(false);
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  return (
    <List>
      <ListItemButton sx={{ paddingY: 0 }} onClick={handleClick}>
        <ListItemIcon>
          <PaidIcon />
        </ListItemIcon>

        <ListItemText
          primary="Moneda"
          sx={{ color: "text.secondary", ml: -2 }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => toggleCurrencyValue(Currency.usd)}
          >
            <ListItemText primary="$ USD" sx={{ color: "text.secondary" }} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => toggleCurrencyValue(Currency.uyu)}
          >
            <ListItemText primary="$ UYU" sx={{ color: "text.secondary" }} />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};

export default MobileCurrencyOptions;
