import { ArrowForwardIosRounded } from "@mui/icons-material";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import CustomButton from "components/atoms/custom-button";
import CustomModal from "components/atoms/custom-modal";
import { FC } from "react";
import theme from "styles/muiTheme";

interface IProfileItemProps {
  name: string;
  value: string;
  title?: string;
  open?: boolean;
  loading?: boolean;
  lastOne?: boolean;
  handleSubmit?: any;
  actionLabel?: string;
  size?: "small" | "big";
  setOpen?: Function;
  hideActionButton?: boolean;
  handleCloseFunction?: () => void;
}

const ProfileItem: FC<IProfileItemProps> = (props) => {
  const {
    name,
    value,
    title,
    setOpen,
    children,
    actionLabel,
    open = false,
    handleSubmit,
    size = "small",
    lastOne = false,
    loading = false,
    handleCloseFunction,
    hideActionButton = false,
  } = props;

  const handleOpen = () => (setOpen ? setOpen(true) : () => false);

  const handleClose = () => {
    if (handleCloseFunction) handleCloseFunction();
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setOpen ? setOpen(false) : () => false;
  };

  const getActionButton = () => {
    if (actionLabel)
      return (
        <Typography
          fontSize={14}
          fontWeight={500}
          color={theme.palette.secondary.main}
        >
          {actionLabel}
        </Typography>
      );

    return (
      <IconButton sx={{ padding: 0 }}>
        <ArrowForwardIosRounded color="warning" fontSize="medium" />
      </IconButton>
    );
  };

  const renderModal = () => {
    if (!handleSubmit) return null;

    return (
      <CustomModal open={open} handleClose={handleClose} size={size}>
        <Typography
          textAlign={{ xs: "center", sm: "left" }}
          sx={{ marginBottom: 3 }}
        >
          {title}
        </Typography>
        <Grid container columnSpacing={3}>
          {children}
          <Grid item xs={12} sm={3} marginTop={1}>
            <CustomButton
              text="Guardar"
              variant="primary"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={3} marginTop={{ sm: 1 }}>
            <CustomButton
              text="Cancelar"
              disabled={loading}
              variant="secondary"
              sx={{ width: "100%" }}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      </CustomModal>
    );
  };

  return (
    <>
      <Box
        display={{ xs: actionLabel ? "block" : "flex", md: "none" }}
        sx={{ cursor: "pointer" }}
        alignItems="center"
        onClick={handleOpen}
        justifyContent="space-between"
      >
        <Box paddingX={3} paddingY={3}>
          <Typography fontSize="14px" fontWeight={500} color="#777577">
            {name}
          </Typography>
          <Typography fontSize="14px" fontWeight={500}>
            {value}
          </Typography>
        </Box>

        {!hideActionButton && (
          <>
            {!actionLabel ? (
              <IconButton sx={{ padding: 0, marginRight: 3 }}>
                <ArrowForwardIosRounded color="warning" fontSize="medium" />
              </IconButton>
            ) : null}

            {actionLabel ? (
              <>
                <Divider />

                <Box
                  paddingX={3}
                  paddingY={3}
                  display="flex"
                  justifyContent="space-between"
                >
                  {getActionButton()}

                  <IconButton sx={{ padding: 0 }}>
                    <ArrowForwardIosRounded color="warning" fontSize="medium" />
                  </IconButton>
                </Box>
              </>
            ) : null}
          </>
        )}
      </Box>

      <Grid
        container
        paddingX={3}
        paddingY={2.5}
        alignItems="center"
        onClick={handleOpen}
        sx={{ cursor: "pointer" }}
        display={{ xs: "none", md: "flex" }}
      >
        <Grid item xs={4}>
          <Typography fontSize="14px" fontWeight={500} color="#777577">
            {name}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography fontSize="14px" fontWeight={500}>
            {value}
          </Typography>
        </Grid>

        {!hideActionButton && (
          <Grid item xs={3} display="flex" justifyContent="end">
            {getActionButton()}
          </Grid>
        )}
      </Grid>

      {renderModal()}

      {!lastOne && <Divider />}
    </>
  );
};

export default ProfileItem;
