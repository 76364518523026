import { AxiosResponse } from "axios";
import { puntoMallAPI } from "./config/axios.config";
import { parseAxiosErrorObject } from "utils/errors";
import { CreateOrderDto, IOrder } from "interfaces/orders.interfaces";

export const OrdersServices = {
  /**
   * Get a list with all ordersby userId (with JWT)
   * @returns array with orders
   */
  getAllOrders: async (): Promise<IOrder[]> => {
    try {
      const orders: AxiosResponse<IOrder[]> = await puntoMallAPI.get("orders");

      return orders.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },

  /**
   * Get a single order by id and userId (JWT) if exists
   * @param {string} orderId
   * @returns single order
   */
  getSingleOrder: async (orderId: string): Promise<IOrder> => {
    try {
      const orders: AxiosResponse<IOrder> = await puntoMallAPI.get(
        `orders/${orderId}`
      );
      return orders.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },

  /**
   * Create a single order with the specific body and user token (JWT)
   * @param {CreateOrderDto} body
   * @returns created order
   */
  createNewOrder: async (body: CreateOrderDto): Promise<IOrder> => {
    try {
      const orders: AxiosResponse<IOrder> = await puntoMallAPI.post(
        "orders",
        body
      );
      return orders.data;
    } catch (error: any) {
      throw parseAxiosErrorObject(error.response?.data as any);
    }
  },
};
