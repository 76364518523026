import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const font = "'Inter', sans-serif";

let theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: font,
      },
    },
  },

  palette: {
    primary: { main: "#1758FF", light: "#5182FF" },
    secondary: { main: "#EA7443", light: "#FAFAFA" },
    background: { default: "#F7F7FA" },
    text: { primary: "#221F1F", secondary: "#221f1fa6", disabled: "#777577" },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
