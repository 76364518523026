import { FC } from "react";
import { inputValidations } from "utils/forms/validations";
import { FieldValues, useController, UseFormReturn } from "react-hook-form";

import { Box } from "@mui/system";
import { Grid, MenuItem, Select, Typography } from "@mui/material";

import Input from "components/atoms/input";
import DatePicker from "components/atoms/date-picker";
import theme from "styles/muiTheme";
import { genreOptions } from "pages/profile/account-settings/components/UpdateGender";
import { DateTime } from "luxon";

interface IGenderSelectProps {
  name: string;
  control: any;
  error?: { message: string };
}

export const GenderSelect: FC<IGenderSelectProps> = (props) => {
  const {
    field: { onChange, name, value },
  } = useController({
    name: props.name,
    control: props.control,
    rules: { required: "Campo obligatorio" },
  });

  return (
    <>
      <Typography
        variant="body2"
        sx={{ mb: 0.25 }}
        lineHeight="24px"
        color="text.secondary"
      >
        Género *
      </Typography>

      <Select
        name={name}
        value={value}
        onChange={onChange}
        error={!!props.error?.message}
        sx={{
          width: "100%",
          height: "40px",
          backgroundColor: theme.palette.secondary.light,
        }}
      >
        {genreOptions.map((o) => (
          <MenuItem
            key={o.value}
            value={o.value}
            sx={{ px: 3, py: 1.5 }}
            selected={o.value === value}
          >
            {o.label}
          </MenuItem>
        ))}
      </Select>

      {!!props.error?.message && (
        <Typography
          color="#d32f2f"
          fontWeight={400}
          paddingTop={0.5}
          marginLeft={1.75}
          lineHeight={1.66}
          fontSize="0.75rem"
          letterSpacing="0.03333em"
          sx={{ backgroundColor: "#f9f9f9" }}
        >
          {props.error?.message}
        </Typography>
      )}
    </>
  );
};

interface IProps {
  formProps: UseFormReturn<FieldValues, object>;
}

/**
 * Component for displaying a controled form with UI and react-hook-form and get
 * user personal information
 * @param {IProps} props
 */

const PersonalInformationform: FC<IProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = props.formProps;

  return (
    <Box>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ textTransform: "uppercase", my: 2 }}
      >
        Información personal para Aduana
      </Typography>

      <Grid container spacing={{ xs: 1, md: 1 }}>
        <Grid item xs={12} md={6}>
          <Input
            name="firstName"
            control={control}
            error={errors.firstName}
            label="Primer Nombre (Idéntico a tu CI) *"
            rules={{
              required: "Campo obligatorio",
              pattern: inputValidations.onlyText(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="middleName"
            control={control}
            error={errors.middleName}
            label="Segundo Nombre (Idéntico a tu CI)"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="lastName"
            control={control}
            error={errors.lastName}
            label="Primer Apellido (Idéntico a tu CI) *"
            rules={{
              required: "Campo obligatorio",
              pattern: inputValidations.onlyText(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="secondLastName"
            control={control}
            error={errors.secondLastName}
            label="Segundo Apellido (Idéntico a tu CI)"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            name="document"
            control={control}
            error={errors.document}
            label="Cédula de identidad *"
            rules={{
              required: "Campo obligatorio",
              pattern: inputValidations.onlyNumbers(),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            control={control}
            name="birthday"
            error={errors.birthday}
            label="Fecha de nacimiento *"
            maxDate={DateTime.now().minus({ years: 18 })}
            rules={{
              required: "Campo obligatorio",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <GenderSelect control={control} name="gender" error={errors.gender} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInformationform;
