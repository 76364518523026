import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setGenericError } from "utils/errors";
import { fetchCartByUserId } from "./cart/cart.actions";
import { OrdersServices } from "services/orders.services";
import { RequestError } from "interfaces/errors.interfaces";
import { CreateOrderDto, IOrder } from "interfaces/orders.interfaces";

interface IOrdersStatae {
  orders: IOrder[];
  isLoading: boolean;
  singleOrder?: IOrder;
  error: RequestError | null;
}

const initialState: IOrdersStatae = {
  orders: [],
  error: null,
  isLoading: false,
};

/**
 * Get an array with all orders by userId (with JWT) and set in orders state
 */
export const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
  async (_, thunkApi) => {
    try {
      return await OrdersServices.getAllOrders();
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Get single order by user id and then set in singleOrder state
 */
export const getSingleOrder = createAsyncThunk(
  "orders/getSingleOrder",
  async (orderId: string, thunkApi) => {
    try {
      return await OrdersServices.getSingleOrder(orderId);
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

/**
 * Create a new order with userId, address and shoppingCart and then getAllOrders
 * (including created before) and finally clean shopping cart
 */
export const createNewOrder = createAsyncThunk(
  "orders/createNewOrder",
  async (body: CreateOrderDto, thunkApi) => {
    try {
      const order = await OrdersServices.createNewOrder(body);
      thunkApi.dispatch(getAllOrders()).then(() => {
        thunkApi.dispatch(fetchCartByUserId());
      });

      return thunkApi.dispatch(getSingleOrder(order.id));
    } catch (error) {
      return thunkApi.rejectWithValue(error as RequestError);
    }
  }
);

const orderSlice = createSlice({
  name: "user-favorites",
  initialState,
  reducers: {
    cleanOrderError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.orders = action.payload as IOrder[];
      })
      .addCase(getAllOrders.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as RequestError;
      });

    builder
      .addCase(getSingleOrder.fulfilled, (state, action) => {
        state.error = null;
        state.isLoading = false;
        state.singleOrder = action.payload as IOrder;
      })
      .addCase(getSingleOrder.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getSingleOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as RequestError;
      });

    builder
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(createNewOrder.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(createNewOrder.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = setGenericError(action.payload);
      });
  },
});

export const { cleanOrderError } = orderSlice.actions;

export default orderSlice.reducer;
