import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { FieldValues, useForm } from "react-hook-form";

import * as Yup from "yup";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { updatePassword } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";
import { passwordMessage, passwordRegex } from "utils/forms/validations";

import ProfileItem from "./ProfileItem";
import Input from "components/atoms/input";

const UpdatePassword: FC = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const { isLoading } = useAppSelector((state) => state.userInformation);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Campo obligatorio")
      .min(8, "La contraseña debe tener 8 o más carácteres"),
    password: Yup.string()
      .min(8, "La contraseña debe tener 8 o más carácteres")
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage)
      .notOneOf(
        [Yup.ref("currentPassword")],
        "La contraseña no puede ser igual a la actual"
      ),
    confirmPassword: Yup.string()
      .required("Campo obligatorio")
      .matches(passwordRegex, passwordMessage)
      .oneOf([Yup.ref("password")], "Las contraseñas no son iguales"),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (!showModal) reset();
  }, [reset, showModal]);

  const onSubmit = async (data: FieldValues) => {
    dispatch(
      updatePassword({
        newPassword: data.password,
        oldPassword: data.currentPassword,
      })
    ).then((res: any) => {
      if (res.error)
        return dispatch(
          createSnackbarMessage({
            snackbarType: SnackbarType.error,
            message:
              "Ha ocurrido un error, por favor verifique su contraseña e intente nuevamente",
          })
        );

      dispatch(
        createSnackbarMessage({
          snackbarType: SnackbarType.success,
          message: "Contraseña actualizada correctamente",
        })
      );
      setShowModal(false);
    });
  };

  return (
    <ProfileItem
      size="small"
      name="Clave"
      open={showModal}
      value="********"
      loading={isLoading}
      setOpen={setShowModal}
      title="Cambiar contraseña"
      actionLabel="Cambiar contraseña"
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Input
          name="currentPassword"
          control={control}
          error={errors.currentPassword}
          label="Contraseña actual"
          type="password"
        />
        <Input
          name="password"
          control={control}
          error={errors.password}
          label="Contraseña nueva"
          type="password"
        />
        <Input
          name="confirmPassword"
          control={control}
          error={errors.confirmPassword}
          label="Confirmar contraseña nueva"
          type="password"
        />
      </Grid>
    </ProfileItem>
  );
};

export default UpdatePassword;
