import { FC } from "react";
import ReactImageMagnify from "react-image-magnify";

import { Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";

interface IProps {
  src: string;
}

/**
 * Component for showing magnify image viewer that takes only one prop named src:
 * @param {String} src
 *
 * @example
 * return (
 *   <ImageMagnify src="custom-image.png" />
 * )
 */

const ImageMagnify: FC<IProps> = (props) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const downToLgSize = useMediaQuery(breakpoints.down("xl"));

  return (
    <ReactImageMagnify
      imageClassName={s.mainImg}
      enlargedImageClassName={s.mainImg}
      enlargedImageContainerStyle={{ zIndex: 2, background: "#ffff" }}
      enlargedImageContainerDimensions={{ width: "200%", height: "100%" }}
      {...{
        smallImage: {
          width: downToLgSize ? 300 : 480,
          height: downToLgSize ? 300 : 480,
          src: props.src,
          alt: "main-image",
          isFluidWidth: false,
        },
        shouldUsePositiveSpaceLens: true,
        largeImage: { src: props.src, width: 1200, height: 1200 },
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  mainImg: {
    objectFit: "contain",
    padding: theme.spacing(1),
    border: "1px solid #D9DDE1",
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
      padding: theme.spacing(0.5),
    },
  },
}));

export default ImageMagnify;
