import { FC, ReactElement } from "react";
import { Box, Typography } from "@mui/material";

interface IAuthOptionProps {
  icon: ReactElement;
  label: string | ReactElement;
}

export enum AuthMode {
  SIGNIN = "signin",
  SIGNUP = "signup",
  LOGOUT = "logout",
  PROFILE = "profile",
  WELCOME = "welcome",
  MY_ORDERS = "myOrders",
  MY_ACCOUNT = "myAccount",
  REFUND_PRODUCTS = "refundProducts",
}

const AuthOption: FC<IAuthOptionProps> = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {props.icon}

      <Box ml={1.5}>
        <Typography variant="body2" component="div" fontSize={14}>
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthOption;
