import { useEffect, FC, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import AppRoutes from "./AppRoutes";
import SnackbarMessage from "components/atoms/custom-snackbar";

import { checkIfAuthTokenExists } from "store/user.slice";
import { snackbarSelector } from "store/snackbars/snackbars.selectors";
import { getDailyCotization, getGlobalConfig } from "store/globals.slice";

const Routes: FC = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const snackbar = useAppSelector(snackbarSelector);
  const globals = useAppSelector((state) => state.globals);

  useEffect(() => {
    dispatch(getGlobalConfig());
    dispatch(checkIfAuthTokenExists());
  }, [dispatch, globals.isFetchched]);

  useEffect(() => {
    dispatch(getDailyCotization()).then(() => setLoading(false));
  }, [dispatch]);

  return (
    <BrowserRouter>
      {!loading && !globals.isLoading && <AppRoutes />}

      <SnackbarMessage
        open={snackbar.show}
        origin={snackbar.origin}
        message={snackbar.message}
        type={snackbar.snackbarType}
      />
    </BrowserRouter>
  );
};

export default Routes;
