import { FC } from "react";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";

import { Badge, useTheme } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material/";
import { ICartItem } from "interfaces/shopping-cart.interfaces";
import { getCartProducts } from "store/cart/cart.selectors";

/**
 * Component for showing shopping cart icon with badge indication total of products into cart
 * @example
 * return <Badge />
 */

const CartIcon: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const cartProducts = useAppSelector(getCartProducts);
  const { items } = useAppSelector((state) => state.lscart);
  const { isLoggedIn } = useAppSelector((state) => state.userInformation);

  const goShoppingCart = () => navigate("/shopping-cart");

  const getItemsCount = () => {
    let cartItems: ICartItem[];

    if (!isLoggedIn) cartItems = items;
    else cartItems = cartProducts;

    return cartItems.reduce(
      (prev, current) => prev + (Number(current.quantity) || 1),
      0
    );
  };

  return (
    <Badge
      color="warning"
      onClick={goShoppingCart}
      sx={{ ml: 2, cursor: "pointer" }}
      badgeContent={getItemsCount()}
    >
      <ShoppingCart
        fontSize="large"
        sx={{ color: theme.palette.background.default }}
      />
    </Badge>
  );
};

export default CartIcon;
