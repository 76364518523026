import axios, { AxiosInstance } from "axios";
import { sleep } from "utils/sleep";

const baseURI =
  process.env.REACT_APP_API_URL ?? "https://api-test.mall.puntomio.uy";
const authToken = localStorage.getItem("auth");

export const puntoMallAPI: AxiosInstance = axios.create({
  baseURL: baseURI,
  withCredentials: false,
  headers: { Authorization: authToken ? `Bearer ${authToken}` : "" },
});

puntoMallAPI.interceptors.request.use((config) => {
  let authToken = localStorage.getItem("auth");
  config.headers = { Authorization: authToken ? `Bearer ${authToken}` : "" };

  return config;
});

puntoMallAPI.interceptors.response.use(async (response) => {
  // add artificial delay for dev env
  if (process.env.NODE_ENV === "development") await sleep();
  return response;
});
