import { FC } from "react";
import { useAppDispatch } from "hooks";
import { useNavigate } from "react-router";
import { FieldValues, useForm } from "react-hook-form";

import { Box, Typography } from "@mui/material";

import Input from "components/atoms/input";
import CustomButton from "components/atoms/custom-button";

import { validateEmail } from "store/user.slice";
import { SnackbarType } from "store/snackbars/snackbars.interfaces";
import { createSnackbarMessage } from "store/snackbars/snackbars.actions";

const EmailValidation: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FieldValues) => {
    dispatch(validateEmail(data.validationCode)).then((data: any) => {
      if (!data.error) {
        dispatch(
          createSnackbarMessage({
            message: "Verificación exitosa",
            snackbarType: SnackbarType.success,
            origin: { horizontal: "center", vertical: "top" },
          })
        );
        navigate("/auth/signin");
      } else
        dispatch(
          createSnackbarMessage({
            snackbarType: SnackbarType.error,
            message:
              "Ha ocurrido un error inesperado, verifique el código de validación e intente nuevamente!",
            origin: { horizontal: "center", vertical: "top" },
          })
        );
    });
  };

  return (
    <Box px={{ md: 4, xs: 0 }}>
      <Typography
        mb={4}
        fontSize={24}
        fontWeight={500}
        textAlign="center"
        letterSpacing={1.5}
      >
        Validar Correo Electrónico
      </Typography>

      <Input
        label="Código"
        control={control}
        name="validationCode"
        error={errors.validationCode}
        rules={{ required: "Campo obligatorio" }}
      />

      <CustomButton
        text="Validar"
        variant="primary"
        sx={{ width: "100%" }}
        onClick={handleSubmit(onSubmit)}
      />
    </Box>
  );
};

export default EmailValidation;
