import { FC, useState } from "react";
import { ProductDetail } from "interfaces/products.interfaces";

import { Box, useTheme } from "@mui/system";
import { Typography } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

interface IDetailProps {
  detail: ProductDetail;
}

interface IProps {
  features: string[];
  productDetails: ProductDetail[];
}

const ProductDetailsMobile: FC<IProps> = (props) => {
  const { features, productDetails } = props;

  const { palette } = useTheme();
  const [viewMore, setViewMore] = useState<boolean>(false);

  return (
    <Box mt={1}>
      <Typography
        my={1}
        fontSize={18}
        variant="subtitle1"
        color={palette.secondary.main}
      >
        Características Principales
      </Typography>

      {productDetails &&
        productDetails.length &&
        productDetails.map((detail, i) => <Detail detail={detail} key={i} />)}

      <Typography
        component="a"
        variant="subtitle2"
        sx={{ display: "flex", my: 1 }}
        color={palette.secondary.main}
        onClick={() => setViewMore(!viewMore)}
      >
        {viewMore ? "Ver menos" : "Ver más"}
        <ArrowDropDownIcon />
      </Typography>

      {viewMore && (
        <Box component="ul">
          {features.map((text, index) => (
            <Typography my={0.5} key={index} component="li" fontSize={14}>
              {text}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

const Detail: FC<IDetailProps> = (props) => {
  return (
    <Box my={1}>
      <Typography fontSize={14} color="text.secondary">
        {props.detail.name}:
      </Typography>

      <Typography fontSize={14}>{props.detail.value}</Typography>
    </Box>
  );
};

export default ProductDetailsMobile;
