import { styles } from "./styles";
import { fees } from "./static.json";
import { FC, useEffect } from "react";
import { genRandomId, scrollTop } from "utils/commons";

import { Box } from "@mui/system";
import { Paper, Typography } from "@mui/material";
import CustomLayout from "components/atoms/simple-layout";

import secondImage from "assets/images/fees-img.svg";
import mainImage from "assets/images/relevant-info.svg";
import orderExampleImage from "assets/images/order-example.svg";

enum IInfoType {
  p = "p",
  h5 = "h5",
}

const FeesInformationPage: FC = () => {
  const s = styles();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <CustomLayout maxWidth="xl">
      <Box
        component="img"
        src={mainImage}
        alt="relevant-info"
        className={s.img}
      />

      <Box
        component="img"
        src={secondImage}
        alt="relevant-info-persom"
        className={s.secondImage}
      />

      <Typography className={s.title} variant="h4">
        Costos y{" "}
        <Typography component="span" variant="h4" className={s.span}>
          tarifas
        </Typography>
      </Typography>

      <Paper className={s.feesPaper}>
        <Typography variant="subtitle1" align="center">
          El costo de comprar un producto en USA por Punto Mall está compuesto
          por la sumatoria de los siguiente cargos: (1) empaque y flete + (2)
          fee de Punto Mío + (3) envío a domicilio + (4) otros tarifas e
          impuestos.
        </Typography>
      </Paper>

      <Box p={2}>
        {fees.map((item) => (
          <Typography
            my={0.5}
            fontSize={13}
            key={genRandomId()}
            color="text.secondary"
            component={item.type as any}
            className={`${item.type === IInfoType.h5 && s.title2} ${
              item.type === IInfoType.p && s.paragraph
            }`}
          >
            {item.text}
          </Typography>
        ))}
      </Box>

      <Box
        component="img"
        className={s.order}
        src={orderExampleImage}
        alt="order-example"
      />
    </CustomLayout>
  );
};

export default FeesInformationPage;
